import React from 'react';
import PropTypes from 'prop-types';

import { Empty, Result, Skeleton } from 'antd';

import { REQUISICAO_FALHOU } from 'Const/webConst';

const Feedback = ({ children, loading, error, empty, emptyMessage }) => (
  <Skeleton loading={loading} active>
    {error && (
      <Result
        status="500"
        title="500"
        subTitle={REQUISICAO_FALHOU}
        className="w-100"
      />
    )}
    {!error && empty && <Empty description={emptyMessage} className="w-100" />}
    {!error && !empty && children}
  </Skeleton>
);

Feedback.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  emptyMessage: PropTypes.string,
};

Feedback.initialValues = {
  emptyMessage: 'Não há dados',
};

export default Feedback;
