import React, { useCallback, useEffect, useState } from "react";
import * as S from "../../styles/styled";
import { RightOutlined } from "@ant-design/icons";
import TableAluno from "./components/TableAluno";
import { useSelector } from "react-redux";

const AlunoPage = ({ dadosSelecionados, bim, tipop, tipom, perfil }) => {
  const [dadosAluno, setDadosAluno] = useState();
  // const [status, setStatus] = useState();

  const { dataAll } = useSelector((state) => state.commons);

  const orderData = useCallback(
    (registros) => {
      const resultado = [];

      registros.forEach((registro) => {
        const {
          filial,
          codFilial,
          bimestre,
          tipoAvaliacao,
          nivelEnsino,
          serie,
          codHabilitacao,
          tipoTurma,
          ra,
          nome,
          nota,
          mediaSemNTD,
          mediaNTD,
          disciplina,
          turma,
          turno,
          codTurma,
        } = registro;

        // Verifica se o tipoAvaliacao é 'VP' e cria uma nova avaliação 'MEDIA'
        const tipoAvaliacaoAtual = tipop;

        let filialExistente = resultado.find(
          (item) => item.filial === filial && item.codFilial === codFilial
        );
        if (!filialExistente) {
          filialExistente = {
            filial,
            codFilial,
            bimestres: [],
          };
          resultado.push(filialExistente);
        }

        let bimestreExistente = filialExistente.bimestres.find(
          (item) => item.bimestre === bimestre
        );
        if (!bimestreExistente) {
          bimestreExistente = {
            bimestre,
            avaliacoes: [],
          };
          filialExistente.bimestres.push(bimestreExistente);
        }

        let avaliacaoExistente = bimestreExistente.avaliacoes.find(
          (item) => item.tipoAvaliacao === tipoAvaliacaoAtual
        );
        if (!avaliacaoExistente) {
          avaliacaoExistente = {
            tipoAvaliacao: tipoAvaliacaoAtual,
            niveisEnsino: [],
          };
          bimestreExistente.avaliacoes.push(avaliacaoExistente);
        }

        let nivelEnsinoExistente = avaliacaoExistente.niveisEnsino.find(
          (item) => item.nivelEnsino === nivelEnsino
        );
        if (!nivelEnsinoExistente) {
          nivelEnsinoExistente = {
            nivelEnsino,
            series: [],
          };
          avaliacaoExistente.niveisEnsino.push(nivelEnsinoExistente);
        }

        let serieExistente = nivelEnsinoExistente.series.find(
          (item) => item.serie === serie && item.codFilial === codFilial
        );
        if (!serieExistente) {
          serieExistente = {
            serie,
            codFilial,
            codHabilitacao,
            quantidade: 0,
            alunos: [],
          };
          nivelEnsinoExistente.series.push(serieExistente);
        }

        // Verifica o valor de tipom para atualizar a propriedade 'nota'
        let mediaAtual;
        if (tipop === "MEDIA") {
          mediaAtual = tipom === "S" ? mediaSemNTD : mediaNTD;
        } else {
          mediaAtual = nota;
        }
        if (mediaAtual < 70 && mediaAtual >= 0) {
          if (tipoAvaliacao === tipop || tipop === "MEDIA") {
            serieExistente.quantidade += 1;
            var existe = serieExistente.alunos.filter(
              (item) =>
                item.ra === ra &&
                item.nome === nome &&
                item.nota === mediaAtual &&
                item.disciplina === disciplina &&
                item.codTurma === codTurma
            );
            if (existe.length == 0) {
              serieExistente.alunos.push({
                ra,
                nome,
                nota: mediaAtual, // Atualiza a propriedade 'nota' com a média correspondente
                disciplina,
                turma,
                codTurma,
                tipoTurma,
                tipoAvaliacao,
                tipoVerificacao: tipop,
                turno,
              });
            }
          }
        }
      });
      setDadosAluno(resultado);
    },
    [tipom, tipop]
  );

  // const handleClick = (e) => {
  //   if (e.name.includes("abaixo")) {
  //     setAlunoPage("Table")
  //     setStatus(e.payload.menu[0]);
  //   }
  // }

  useEffect(() => {
    // setChangePage("Graficos");
    orderData(dataAll.return);
  }, [dataAll.return, orderData]);

  const renderSerie = (serie) => {
    switch (serie) {
      case "1o ANO EF1":
        return "1º ANO EF";
      case "2o ANO EF1":
        return "2º ANO EF";
      case "3o ANO EF1":
        return "3º ANO EF";
      case "4o ANO EF1":
        return "4º ANO EF";
      case "5o ANO EF1":
        return "5º ANO EF";
      case "6o ANO EF2":
        return "6º ANO EF";
      case "7o ANO EF2":
        return "7º ANO EF";
      case "8o ANO EF2":
        return "8º ANO EF";
      case "9o ANO EF2":
        return "9º ANO EF";
      case "1o ANO E.M":
        return "1º ANO EM";
      case "2o ANO E.M":
        return "2º ANO EM";
      case "3o ANO E.M":
        return "3º ANO EM";
      default:
        return serie.toString();
    }
  };
  return (
    dadosAluno && (
      <>
        <S.TitleWrapper>
          <S.containerPaginaSelecionada>
            <S.h1>{dadosSelecionados.menu[0].filialNomeCurto}</S.h1>
            <S.Span>
              <RightOutlined
                style={{
                  fontSize: 12,
                  stroke: "#A9A9A9",
                  strokeWidth: "35px",
                  color: "#A9A9A9",
                  margin: "0px 5px",
                }}
              />
            </S.Span>
            <S.Span style={{ color: "#A9A9A9", fontWeight: 500 }}>
              {renderSerie(dadosSelecionados.menu[0].title)}
            </S.Span>
            <S.Span>
              <RightOutlined
                style={{
                  fontSize: 12,
                  stroke: "#A9A9A9",
                  strokeWidth: "35px",
                  color: "#A9A9A9",
                  margin: "0px 5px",
                }}
              />
            </S.Span>
            <S.Span style={{ color: "#A9A9A9", fontWeight: 500 }}>
              POR ALUNO
            </S.Span>
          </S.containerPaginaSelecionada>

          <S.h2>
            {`${bim}º BIMESTRE`}
            {tipop ? (
              <>
                <span>
                  <RightOutlined
                    style={{
                      fontSize: 13,
                      stroke: "#009240",
                      strokeWidth: "80px",
                      margin: "0px 5px",
                    }}
                  />
                </span>
                <span>{tipop}</span>
              </>
            ) : (
              ""
            )}
            {tipom ? (
              <>
                <span>
                  <RightOutlined
                    style={{
                      fontSize: 13,
                      stroke: "#009240",
                      strokeWidth: "80px",
                      margin: "0px 5px",
                    }}
                  />
                </span>
                <span>{tipom === "S" ? "SEM NTD" : "COM NTD"}</span>
              </>
            ) : (
              ""
            )}
          </S.h2>
        </S.TitleWrapper>
        <TableAluno
          dadosAluno={dadosAluno}
          dadosSelecionados={dadosSelecionados}
          data={dataAll.return}
          bim={bim}
          tipop={tipop}
          tipom={tipom}
          perfil={perfil}
        />
      </>
    )
  );
};

export default AlunoPage;
