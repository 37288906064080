import React, { useEffect, useState } from "react";
import * as S from "../../styles/styled";
import PieChartCompDisciplina from "./components/PieChartCompDisciplina";
import { RightOutlined, BankOutlined } from "@ant-design/icons";
import { ReactComponent as FBIcon } from "../../../../components/Icons/fb.svg";
import TableDisciplina from "./components/TableDisciplina";
import { Tooltip } from "antd";
import { useCallback } from "react";
import { useSelector } from "react-redux";

const DisciplinaPage = ({
  COLORS,
  setChangePage,
  dadosSelecionados,
  bim,
  tipop,
  tipom,
  quantidadeTotal,
  perfil,
}) => {
  const [disciplinaPage, setDisciplinaPage] = useState("Main");
  const [dadosDisciplina, setDadosDisciplina] = useState();
  const [status, setStatus] = useState();
  const [mediaDisc, setMediaDisc] = useState();
  const [mediaDiscFilial, setMediaDiscFilial] = useState();

  const { dataAll } = useSelector((state) => state.commons);

  const orderData = useCallback(
    (registros) => {
      const resultado = [];

      registros.forEach((registro) => {
        const {
          filial,
          codFilial,
          bimestre,
          tipoAvaliacao,
          nivelEnsino,
          serie,
          codHabilitacao,
          ra,
          nome,
          nota,
          disciplina,
          turma,
          turno,
          tipoTurma,
          codTurma,
          mediaNTD,
          mediaSemNTD,
        } = registro;

        // Verifica se o tipoAvaliacao é 'VP' e cria uma nova avaliação 'MEDIA'
        const tipoAvaliacaoAtual = tipop;

        let filialExistente = resultado.find(
          (item) => item.filial === filial && item.codFilial === codFilial
        );
        if (!filialExistente) {
          filialExistente = {
            filial,
            codFilial,
            bimestres: [],
          };
          resultado.push(filialExistente);
        }

        let bimestreExistente = filialExistente.bimestres.find(
          (item) => item.bimestre === bimestre
        );
        if (!bimestreExistente) {
          bimestreExistente = {
            bimestre,
            avaliacoes: [],
          };
          filialExistente.bimestres.push(bimestreExistente);
        }

        let avaliacaoExistente = bimestreExistente.avaliacoes.find(
          (item) => item.tipoAvaliacao === tipoAvaliacaoAtual
        );
        if (!avaliacaoExistente) {
          avaliacaoExistente = {
            tipoAvaliacao: tipoAvaliacaoAtual,
            niveisEnsino: [],
          };
          bimestreExistente.avaliacoes.push(avaliacaoExistente);
        }

        let nivelEnsinoExistente = avaliacaoExistente.niveisEnsino.find(
          (item) => item.nivelEnsino === nivelEnsino
        );
        if (!nivelEnsinoExistente) {
          nivelEnsinoExistente = {
            nivelEnsino,
            series: [],
          };
          avaliacaoExistente.niveisEnsino.push(nivelEnsinoExistente);
        }

        let serieExistente = nivelEnsinoExistente.series.find(
          (item) => item.serie === serie && item.codFilial === codFilial
        );
        if (!serieExistente) {
          serieExistente = {
            serie,
            codFilial,
            codHabilitacao,
            disciplinas: [],
          };
          nivelEnsinoExistente.series.push(serieExistente);
        }

        let disciplinaExistente = serieExistente.disciplinas.find(
          (item) => item.disciplina === `${disciplina} - ${tipoTurma}`
        );
        if (!disciplinaExistente) {
          disciplinaExistente = {
            disciplina: `${disciplina} - ${tipoTurma}`,
            disc: disciplina,
            tipoTurma,
            quantidade: 0,
            alunos: [],
          };
          serieExistente.disciplinas.push(disciplinaExistente);
        }

        // Verifica o valor de tipom para atualizar a propriedade 'nota'
        let mediaAtual;
        if (tipop === "MEDIA") {
          mediaAtual = tipom === "S" ? mediaSemNTD : mediaNTD;
        } else {
          mediaAtual = nota;
        }
        if (mediaAtual < 70 && mediaAtual >= 0) {
          if (tipoAvaliacao === tipop || tipop === "MEDIA") {
            var existe = disciplinaExistente.alunos.filter(
              (item) =>
                item.ra === ra &&
                item.nome === nome &&
                item.nota === mediaAtual &&
                item.disciplina === disciplina &&
                item.codTurma === codTurma
            );
            if (existe.length == 0) {
              disciplinaExistente.quantidade += 1;
              disciplinaExistente.alunos.push({
                ra,
                nome,
                nota: mediaAtual,
                disciplina: `${disciplina}`,
                turno,
                tipoTurma,
                turma,
                codTurma,
              });
            }
          }
        }
      });

      setDadosDisciplina(resultado);
    },
    [tipom, tipop, dataAll]
  );

  const handleClick = (e) => {
    if (e.name.includes("abaixo")) {
      setDisciplinaPage("Table");
      setStatus(e.payload.menu[0]);
    }
  };

  useEffect(() => {
    // setChangePage("Graficos");
    orderData(dataAll.return);
  }, [dataAll, orderData]);

  const formatNota = (nota) => {
    var inteiro = Math.floor(nota);
    return (inteiro / 10).toFixed(1);
  };

  const renderSerie = (serie) => {
    switch (serie) {
      case "1o ANO EF1":
        return "1º ANO EF";
      case "2o ANO EF1":
        return "2º ANO EF";
      case "3o ANO EF1":
        return "3º ANO EF";
      case "4o ANO EF1":
        return "4º ANO EF";
      case "5o ANO EF1":
        return "5º ANO EF";
      case "6o ANO EF2":
        return "6º ANO EF";
      case "7o ANO EF2":
        return "7º ANO EF";
      case "8o ANO EF2":
        return "8º ANO EF";
      case "9o ANO EF2":
        return "9º ANO EF";
      case "1o ANO E.M":
        return "1º ANO EM";
      case "2o ANO E.M":
        return "2º ANO EM";
      case "3o ANO E.M":
        return "3º ANO EM";
      default:
        return serie.toString();
    }
  };
  switch (disciplinaPage) {
    case "Main":
      return (
        dadosDisciplina && (
          <>
            <S.TitleWrapperContainer>
              <S.TitleWrapper>
                <S.containerPaginaSelecionada>
                  <S.h1>{dadosSelecionados.menu[0].filialNomeCurto}</S.h1>
                  <S.Span>
                    <RightOutlined
                      style={{
                        fontSize: 12,
                        stroke: "#A9A9A9",
                        strokeWidth: "35px",
                        color: "#A9A9A9",
                        margin: "0px 5px",
                      }}
                    />
                  </S.Span>
                  <S.Span style={{ color: "#A9A9A9", fontWeight: 500 }}>
                    {renderSerie(dadosSelecionados.menu[0].title)}
                  </S.Span>
                  <S.Span>
                    <RightOutlined
                      style={{
                        fontSize: 12,
                        stroke: "#A9A9A9",
                        strokeWidth: "35px",
                        color: "#A9A9A9",
                        margin: "0px 5px",
                      }}
                    />
                  </S.Span>
                  <S.Span style={{ color: "#A9A9A9", fontWeight: 500 }}>
                    DISCIPLINA
                  </S.Span>
                </S.containerPaginaSelecionada>

                <S.h2>
                  {`${bim}º BIMESTRE`}
                  {tipop ? (
                    <>
                      <span>
                        <RightOutlined
                          style={{
                            fontSize: 13,
                            stroke: "#009240",
                            strokeWidth: "80px",
                            margin: "0px 5px",
                          }}
                        />
                      </span>
                      <span>{tipop}</span>
                    </>
                  ) : (
                    ""
                  )}
                  {tipom ? (
                    <>
                      <span>
                        <RightOutlined
                          style={{
                            fontSize: 13,
                            stroke: "#009240",
                            strokeWidth: "80px",
                            margin: "0px 5px",
                          }}
                        />
                      </span>
                      <span>{tipom === "S" ? "SEM NTD" : "COM NTD"}</span>
                    </>
                  ) : (
                    ""
                  )}
                </S.h2>
              </S.TitleWrapper>
            </S.TitleWrapperContainer>

            <S.PieChartContainer>
              <S.PieChartWrapperContainer>
                <S.PieChartWrapper>
                  {dadosDisciplina
                    .filter(
                      (x) => x.codFilial === dadosSelecionados.menu[0].codFilial
                    )[0]
                    .bimestres.filter(
                      (y) => y.bimestre === dadosSelecionados.menu[0].bimestre
                    )[0]
                    .avaliacoes.filter(
                      (p) =>
                        p.tipoAvaliacao === dadosSelecionados.menu[0].tipoProva
                    )[0]
                    .niveisEnsino.filter(
                      (i) =>
                        i.nivelEnsino === dadosSelecionados.menu[0].nivelEnsino
                    )[0]
                    .series.filter(
                      (l) =>
                        l.codHabilitacao ===
                        dadosSelecionados.menu[0].codHabilitacao
                    )
                    .map((item, index) => (
                      <PieChartCompDisciplina
                        key={`pieChartComp-disciplina_${index}`}
                        data={item}
                        handleClick={(e) => handleClick(e)}
                        COLORS={COLORS}
                        bim={bim}
                        tipop={tipop}
                        tipom={tipom}
                        filial={item.codFilial}
                        quantidadeTotal={quantidadeTotal}
                        dadosSelecionados={dadosSelecionados.payload.menu[0]}
                      />
                    ))}
                </S.PieChartWrapper>
              </S.PieChartWrapperContainer>
            </S.PieChartContainer>
          </>
        )
      );
    case "Table":
      return (
        <>
          <S.TitleWrapperContainer>
            <Tooltip title="Voltar para gráficos de Disciplina">
              <S.DoubleArrowIcon onClick={() => setDisciplinaPage("Main")} />
            </Tooltip>

            <S.TitleWrapper>
              <S.containerSuperior>
                <S.containerPaginaSelecionada>
                  <S.h1>{dadosSelecionados.menu[0].filialNomeCurto}</S.h1>
                  <S.Span>
                    <RightOutlined
                      style={{
                        fontSize: 12,
                        stroke: "#A9A9A9",
                        strokeWidth: "35px",
                        color: "#A9A9A9",
                        margin: "0px 5px",
                      }}
                    />
                  </S.Span>
                  <S.Span style={{ color: "#A9A9A9", fontWeight: 500 }}>
                    {renderSerie(dadosSelecionados.menu[0].title)}
                  </S.Span>
                  <S.Span>
                    <RightOutlined
                      style={{
                        fontSize: 12,
                        stroke: "#A9A9A9",
                        strokeWidth: "35px",
                        color: "#A9A9A9",
                        margin: "0px 5px",
                      }}
                    />
                  </S.Span>
                  <S.Span
                    style={{
                      color: "#A9A9A9",
                      fontWeight: 500,
                      whiteSpace: "break-spaces",
                    }}
                  >
                    {status?.title}
                  </S.Span>
                </S.containerPaginaSelecionada>

                <S.containerMedia>
                  {mediaDisc?.media !== undefined ? (
                    <Tooltip
                      placement="bottom"
                      title="Média da disciplina na série na instituição"
                    >
                      <S.containerMediaDisc>
                        <FBIcon width="20px" fill="#009240" />
                        <S.containerMediaDisciplina>
                          {formatNota(mediaDisc?.media)}
                        </S.containerMediaDisciplina>
                      </S.containerMediaDisc>
                    </Tooltip>
                  ) : (
                    ""
                  )}

                  {mediaDiscFilial?.media !== undefined ? (
                    <Tooltip
                      placement="bottom"
                      title="Média da disciplina na série na filial"
                    >
                      <S.containerMediaDisc>
                        <BankOutlined />
                        <S.containerMediaDisciplina>
                          {formatNota(mediaDiscFilial?.media)}
                        </S.containerMediaDisciplina>
                      </S.containerMediaDisc>
                    </Tooltip>
                  ) : (
                    ""
                  )}
                </S.containerMedia>
              </S.containerSuperior>

              <S.h2>
                {`${bim}º BIMESTRE`}
                {tipop ? (
                  <>
                    <span>
                      <RightOutlined
                        style={{
                          fontSize: 13,
                          stroke: "#009240",
                          strokeWidth: "80px",
                          margin: "0px 5px",
                        }}
                      />
                    </span>
                    <span>{tipop}</span>
                  </>
                ) : (
                  ""
                )}
                {tipom ? (
                  <>
                    <span>
                      <RightOutlined
                        style={{
                          fontSize: 13,
                          stroke: "#009240",
                          strokeWidth: "80px",
                          margin: "0px 5px",
                        }}
                      />
                    </span>
                    <span>{tipom === "S" ? "SEM NTD" : "COM NTD"}</span>
                  </>
                ) : (
                  ""
                )}
              </S.h2>
            </S.TitleWrapper>
          </S.TitleWrapperContainer>
          <TableDisciplina
            dadosDisciplina={dadosDisciplina}
            status={status}
            data={dataAll.return}
            bim={bim}
            tipop={tipop}
            tipom={tipom}
            mediaDisc={mediaDisc}
            setMediaDisc={setMediaDisc}
            mediaDiscFilial={mediaDiscFilial}
            setMediaDiscFilial={setMediaDiscFilial}
            perfil={perfil}
          />
        </>
      );
    default:
  }
};

export default DisciplinaPage;
