import { Button, Modal } from "antd";
import React, { useState } from "react";
import TableAtendimentos from "./TableAtendimentos";
import NovoAtendimento from "./NovoAtendimento";
import { novoContato } from "../../../../../Services/Aluno/Service";
import { getAtendimentos } from "../../../../../Services/Atendimento/Service";
import Swal from "sweetalert2";
import { TituloTabelaAtendimento } from "../../Aluno/styles/styled";

const ModalAtendimentos = ({
  Registro,
  visibleAtendimentos,
  setVisibleAtendimentos,
  dadosSelecionados,
  dados,
  setDados,
}) => {
  const [visibility, setVisibility] = useState(false);
  const [atendimento, setAtendimento] = useState({});

  const modeloInicial = {
    DataOcorrencia: null,
    DataAgendamenteo: null,
    CodigoColigada: 3,
    Matricula: Registro?.ra,
    Observacao: null,
    ObservacaoRestrita: null,
    CodigoFilial: Registro?.CodFilial,
    Etapa: null,
  };
  const [modeloAtendimento, setModeloAtendimento] = useState({
    ...modeloInicial,
  });
  const [carregando, setCarregando] = useState(false);

  const Titulo = ({ texto }) => {
    return <TituloTabelaAtendimento style={{ marginTop: "25px" }}>{texto}</TituloTabelaAtendimento>;
  };

  const Capitalize = (str) => {
    if (str) {
      let arr = str?.toLowerCase().split(" ");

      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }

      const str2 = arr?.join(" ");

      return str2;
    }
  };

  const novoAtendimento = () => {
    setModeloAtendimento(modeloInicial);
    setVisibility(!visibility);
  };

  const salvar = async (contato) => {
    setCarregando(true);
    await novoContato(contato)
      .then((result) => {
        if (result === true) {
          setVisibility(false);

          Swal.fire({
            title: "Contato cadastrado!",
            icon: "success",
            confirmButtonText: "Ok!",
          });

          getAtendimentos(contato?.Matricula).then((resultado) => {
            setDados(resultado);
            setCarregando(false);
          });
        }else if(result.response.status !== 200){
          throw new Error(result.response.data)
        }
      })
      .catch((erro) => {
        Swal.fire({
          text:erro.message,
          icon: "error",
          confirmButtonText: "Ok!",
        });

        setCarregando(false);
      });
  };

  return (
    <>
      <Modal
        open={visibleAtendimentos}
        destroyOnClose={true}
        closable={true}
        maskClosable={true}
        keyboard={false}
        width={"auto"}
        onCancel={() => setVisibleAtendimentos("", false)}
        footer={""}
        height={200}
      >
        <Button type="primary" onClick={novoAtendimento}>
          Registrar Novo Contato
        </Button>
        <Titulo
          texto={`Atendimentos do tipo 'RENDIMENTO ESCOLAR' realizados para ${Capitalize(
            Registro?.nome
          )}`}
        />
        <TableAtendimentos
          Registro={Registro}
          dados={dados}
          setDados={setDados}
        />
      </Modal>

      <NovoAtendimento
        visibility={visibility}
        setVisibility={setVisibility}
        Registro={Registro}
        atendimento={atendimento}
        setAtendimento={setAtendimento}
        modeloAtendimento={modeloAtendimento}
        setModeloAtendimento={setModeloAtendimento}
        salvar={salvar}
        carregando={carregando}
        dados={dados}
        setDados={setDados}
      />
    </>
  );
};

export default ModalAtendimentos;
