import React, { useCallback } from "react";
import { Home } from "../components";
import { GraficosPage } from "../pages";
import { useState, useEffect } from "react";
import {
  getAllNotas,
  getAllQuantidades,
  getNotasFormatless,
} from "../Services/Notas/Service";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import {
  setMainPage,
  setChangePage,
  setIsEmail,
  setDataAll,
  setDataComplete,
  setSGE
} from "../redux/ducks/commons";

const MainPages = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { mainPage, changePage, isEmail, sge, page, tipoTurmaSelecionado } = useSelector(
    (state) => state.commons
  );
  const usuario = useSelector((state) => state.usuario.usuario);

  const [quantidadeTotal, setQuantidadeTotal] = useState();
  const [filial, setFilial] = useState();
  const [carregando, setCarregando] = useState(true);
  const [carregandoAllNotas, setCarregandoAllNotas] = useState(true);
  const [carregandoAllQuantidades, setCarregandoAllQuantidades] =
    useState(true);
  const [alertaEmExibicao, setAlertaEmExibicao] = useState(false);
  const [disciplinas, setDisciplinas] = useState();
  const [disciplinaSelecionada, setDisciplinaSelecionada] = useState();

  const [dados, setDados] = useState();
  const [perfil, setPerfil] = useState();

  const userSGE = useParams();

  useEffect(() => {
    dispatch(setChangePage("Inicial"));

    if (!usuario && !window.location.pathname.includes("/sge/")) {
      navigate("/user_error");
    } else {
      dispatch(setSGE(window.location.pathname.includes("/sge/")));
      setCarregandoAllNotas(true);
      if (usuario.nameid) {
        getNotas(usuario.nameid, disciplinaSelecionada);
      }

      dispatch(setMainPage("Graficos"));
    }
  }, [usuario, disciplinaSelecionada]);

  const getNotas = async (codusuario, disciplina) => {
    await getAllNotas(codusuario, disciplina).then((retorno) => {
      if (retorno.return.length > 0) {
        setDados(retorno.return);
        setCarregandoAllNotas(false);
        setPerfil(retorno.return[0]?.tipo !== "PROFESSOR");
      } else {
        setDados(retorno.return);
        if (!alertaEmExibicao) {
          Swal.fire({
            title: "Não foram encontrados registros para seu usuário.",
            showConfirmButton: false,
            allowOutsideClick: false,
            customClass: "usuario-sem-registros",
          });
          setAlertaEmExibicao(true);
        }
      }
    });
  };

  useEffect(() => {
    const getQuantidades = async () => {
      await getAllQuantidades(usuario.nameid).then((result) => {
        setQuantidadeTotal(result);
        setCarregandoAllQuantidades(false);
      });
    };

    if (usuario.nameid) {
      getQuantidades();
    }
  }, []);



  useEffect(() => {
    if (!usuario) {
      navigate("/user_error");
    } else if (isEmail) {
      dispatch(setMainPage("Graficos"));
      getNotasFormatless(usuario.nameid, disciplinaSelecionada).then(
        (resultado) => {
          dispatch(setDataAll(resultado));
          dispatch(setDataComplete(resultado));
          setCarregando(false);
          dispatch(setIsEmail(false));
        }
      );
    } else {
      setCarregando(true);
      if (usuario.nameid) {
        getNotasFormatless(usuario.nameid, disciplinaSelecionada).then(
          (resultado) => {
            dispatch(setDataAll(resultado));
            dispatch(setDataComplete(resultado));
            setCarregando(false);
          }
        );
      }
    }
  }, [navigate, usuario, disciplinaSelecionada]);

  switch (mainPage) {
    case "Inicial":
      return (
        <Home
          setMainPage={setMainPage}
          mainPage={mainPage}
          changePage={changePage}
          setChangePage={setChangePage}
          dados={dados}
        />
      );
    case "Graficos":
      return (
        <GraficosPage
          carregando={carregando}
          setCarregando={setCarregando}
          carregandoAllNotas={carregandoAllNotas}
          carregandoAllQuantidades={carregandoAllQuantidades}
          dados={dados}
          setDados={setDados}
          quantidadeTotal={quantidadeTotal}
          setQuantidadeTotal={setQuantidadeTotal}
          filial={filial}
          setFilial={setFilial}
          sge={sge}
          perfil={perfil}
          disciplinas={disciplinas}
          setDisciplinas={setDisciplinas}
          disciplinaSelecionada={disciplinaSelecionada}
          setDisciplinaSelecionada={setDisciplinaSelecionada}
        />
      );
    default:
      navigate("/erro");
  }
};

export default MainPages;
