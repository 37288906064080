import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    padding: 0;
  }

  .flex-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  /* Definir flex-wrap: wrap como padrão para qualquer contêiner com display: flex */
  [data-display="flex"] {
    display: flex;
    flex-wrap: wrap;
  }
`;
