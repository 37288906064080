import React from 'react'

import * as S from './styled'

const Footer = ({children}) => {
   
   return (
<S.Main>
    <S.FooterSection1>
    </S.FooterSection1>

    <S.FooterSection2>
    </S.FooterSection2>

    <S.FooterSection3>
    </S.FooterSection3>
</S.Main> 
   )
}

export default Footer;