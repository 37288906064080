import styled from 'styled-components'

export const Bars = styled.section`
    display: flex;
    height: 1vh;
    width: 100%;
`

export const Bar = styled.div`
    background-color: ${(props) => `#${props.backgroundColor}`};
    height: 100%;
    width: 20%;
`
