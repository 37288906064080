import './App.css';
import './styles/mobile.css';
import MainRoutes from './routes/MainRoutes';

function App() {

  return (
    <>
      <MainRoutes />
    </>
  );

}

export default App;
