import React, { memo, useRef } from "react";
import { MDBDataTable as Table } from "mdbreact";

const Tabela = ({ dadosAluno, dadosSelecionados, handleClick, perfil }) => {
  const refDataTable = useRef(null);

  const organizarRegistro = React.useCallback((data) => {
      const status = dadosSelecionados?.menu[0];
      if(data){
      var result = data
        .filter((x) => x.codFilial === status.codFilial)[0]
        .bimestres.filter((y) => y.bimestre === status.bimestre)[0]
        .avaliacoes.filter((p) => p.tipoAvaliacao === status.tipoProva)[0]
        .niveisEnsino.filter((i) => i.nivelEnsino === status.nivelEnsino)[0]
        .series.filter((l) => l.codHabilitacao === status.codHabilitacao)[0];

      const semDuplicidade = removerDuplicidade(result.alunos);

      return semDuplicidade;
      }
    },
    [dadosSelecionados]
  );

  const removerDuplicidade = (alunos) => {
    const alunosUnicos = [];
    const alunosDuplicados = {};

    alunos.forEach((aluno) => {
      const {
        bimestre,
        codFilial,
        ra,
        nome,
        serie,
        tipoAluno,
        tipoAvaliacao,
        disciplina,
        nota,
      } = aluno;

      const chave = `${bimestre}-${codFilial}-${ra}-${nome}-${serie}-${aluno}-${tipoAluno}-${tipoAvaliacao}-${disciplina}-${nota}`;

      if (!alunosDuplicados[chave]) {
        alunosUnicos.push(aluno);
        alunosDuplicados[chave] = true;
      }
    });

    return alunosUnicos;
  };

  const colunas = React.useMemo(() => {
    return [
      {
        label: `${perfil ? "RA" : ""}`,
        field: `${perfil ? "ra" : ""}`,
      },
      {
        label: "Aluno",
        field: "nome",
        sort: "asc",
      },
      {
        label: "Tipo",
        field: "tipoTurma",
      },
      {
        label: "Turno",
        field: "turno",
      },
      {
        label: "Turma",
        field: "turma",
      },
      {
        label: "Disciplina",
        field: "disciplina",
      },
      {
        label: "Nota",
        field: "nota",
      },
    ];
  }, []);

  const dadosDaTabela = React.useMemo(() => {
    return {
      columns: colunas,
      rows: organizarRegistro(dadosAluno)
        .sort((a, b) => a.nome.localeCompare(b.nome))
        .map((registro) => ({
          ...registro,
          turno: registro.turno === "M" ? "MANHÃ" : "TARDE",
          nota: (Math.floor(registro.nota) / 10).toFixed(1),
          clickEvent: () => {
            handleClick({ ...registro }, true);
          },
        })),
    };
  }, [colunas, dadosAluno, handleClick, organizarRegistro]);

  return (
    <Table
      striped={dadosDaTabela.rows.length > 0 ? true : false}
      hover={dadosDaTabela.rows.length > 0 ? true : false}
      autoWidth={true}
      small
      noBottomColumns
      displayEntries={false}
      entries={20}
      infoLabel={["Mostrando", "a", "de", "registros"]}
      paginationLabel={["<", ">"]}
      paging={true}
      pagesAmount={5}
      searchLabel=" "
      responsive
      disableRetreatAfterSorting={true}
      data={dadosDaTabela}
      ref={refDataTable}
    />
  );
};

export default memo(Tabela);
