
import styled from 'styled-components'

export const MenuContent = styled.div`
    height: 100%;
    width: 100%;

    display:flex;
    justify-content: center;
    align-items: center;
`

export const ArrowContent = styled.div`
    height: 40px;
    width: 40px;

    background-color: #FFF;
    border-radius:100%;

    margin-left:50px;

    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 600px) {
        height: 40px;
        width: 40px;
        margin-left:0;
    }
`
export const MainTitle = styled.span`
    color: #FFF;

    font-size: 2em;
    font-weight: 600;
    text-transform: uppercase;
    white-space: nowrap;
    
    margin-left: 15px;

    @media screen and (max-width: 600px) {
        font-size: 1em;
        margin-left: 0px;
        margin-right: 10px
    }
`

export const MainHome = styled.div`
    width: 100%;
`
