import React, { createContext, useContext, useState } from 'react';

export const GlobalContext = createContext("");

export const GlobalContextProvider = ({ children }) => {
  const [myValue, setMyValue] = useState('initial value');
  const [quantidadeAlunos, setQuantidadeAlunos] = useState();

  const updateValue = (newValue) => {
    setMyValue(newValue);
  };

  const updateQuantidade = (value) => {
    setQuantidadeAlunos(value);
  }

  return (
    <GlobalContext.Provider value={{ myValue, updateValue, quantidadeAlunos, updateQuantidade }}>
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => useContext(GlobalContext);
export default GlobalContext;