import PropTypes from 'prop-types';
import React, { useState, useCallback, memo } from 'react'
import { ContainerTabela, Estilos, TituloTabela } from '../styles/styled';
import ModalDetalhes from '../../Components/Modal/ModalDetalhes';
import Tabela from './Tabela';

const TableAluno = ({ dadosAluno, dadosSelecionados, data, bim, tipop, tipom, perfil }) => {
  const [current, setCurrent] = useState();
  const [visible, setVisible] = useState(false);

 
  const handleClick = useCallback((params, visibility) => {
    setCurrent({...params, CodFilial: dadosSelecionados?.menu[0].codFilial});
    setVisible(visibility)
  },[dadosSelecionados]);

  const Titulo = ({ texto }) => {

    return <TituloTabela>{texto}</TituloTabela>;
  };

  return (
    <>
      <Estilos>
        <ContainerTabela>
          <Titulo texto="Alunos com baixo rendimento" />
          <Tabela dadosAluno={dadosAluno} dadosSelecionados={dadosSelecionados} handleClick={handleClick} perfil={perfil}/>
        </ContainerTabela>
      </Estilos>
      <ModalDetalhes visible={visible} setVisible={handleClick} current={current} data={data} bim={bim} tipop={tipop} tipom={tipom} perfil={perfil}/>
    </>

  )
}
TableAluno.propTypes = {
  registros: PropTypes.array,
};
export default memo(TableAluno);