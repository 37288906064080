import { Button, Form, Input, Skeleton, Space } from "antd";
import { Flex } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UsuarioActions as UsuarioCreators } from "../redux/ducks/usuario";
import { Navigate } from "react-router-dom";
import {
  UserOutlined as User,
  LockOutlined as Lock,
  PhoneTwoTone as Phone,
  MailTwoTone as Mail,
} from "@ant-design/icons";
import BG from "assets/bg/Wallpaper2024.jpg";
import styled from "styled-components";

const Login = ({ currentUser, setCurrentUser }) => {
  const { isEmail, sge } = useSelector((state) => state.commons);

  const dispatch = useDispatch();
  const autenticado = useSelector((state) => state.usuario.autenticado);
  const [form] = Form.useForm();

  const formRef = React.useRef(null);

  useEffect(() => {
    formRef?.current?.setFieldsValue({
      username: currentUser?.usuario,
    });
  }, [currentUser]);

  useEffect(() => {
    dispatch(UsuarioCreators.autenticarIp());
  }, [autenticado, dispatch]);

  const autenticar = ({ username, passwd }) => {
    dispatch(UsuarioCreators.autenticar({ usuario: username, senha: passwd }));
  };

  const changeUser = () => {
    setCurrentUser("");
  };

  const FlexMain = styled(Flex)`
    height: 100vh;
    background-image: url(BG);

    @media screen and (max-width: 600px) {
      background-size: cover;
      background-position: right;
    }
  `;

  const FlexContainer = styled(Flex)`
    width: 40%;
    background-color: rgba(255, 255, 255, 0.93);
    padding: 25px 40px 10px;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    @media screen and (max-width: 600px) {
      width: 90%;
    }
  `;

  if (autenticado && !currentUser) {
    return <Navigate to="/" />;
  }
  return (
    !sge && (
      <FlexMain
        align="center"
        justify="center"
        vertical
        style={{ height: "100vh", backgroundImage: `url(${BG})` }}
      >
        <FlexContainer className="login-form">
          {currentUser?.usuario ||
          (currentUser === "" && currentUser !== undefined) ||
          !isEmail ? (
            <Form
              ref={formRef}
              form={form}
              layout="vertical"
              size="large"
              onFinish={autenticar}
              className="w-100"
              autoComplete="off"
            >
              <Form.Item>
                <Form.Item
                  label="Entre com seu usuário"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Por favor digite seu usuário!",
                    },
                  ]}
                  required={false}
                >
                  <Input
                    name="username-input"
                    addonBefore={<User style={{ fontSize: "14px" }} />}
                    disabled={currentUser?.usuario ? true : false}
                    autoComplete="new-username"
                  />
                </Form.Item>
                <Form.Item
                  required={false}
                  label="Entre com sua senha"
                  name="passwd"
                  rules={[
                    {
                      required: true,
                      message: "Por favor digite sua senha!",
                    },
                  ]}
                >
                  <Input.Password
                    addonBefore={<Lock style={{ fontSize: "14px" }} />}
                  />
                </Form.Item>
                <Form.Item style={{ margin: "0", marginTop: "30px" }}>
                  <Flex>
                    <Button
                      type="primary"
                      htmlType="submit"
                      // size='large'
                      className="float-end w-75"
                      style={{ borderRadius: 5, margin: "0 auto" }}
                    >
                      ENTRAR
                    </Button>
                  </Flex>
                  {currentUser && (
                    <Flex justify="center" style={{ marginTop: "30px" }}>
                      <a onClick={changeUser}>Entrar com outro usuário.</a>
                    </Flex>
                  )}
                </Form.Item>
              </Form.Item>
            </Form>
          ) : (
            <>
              <FlexContainer
                style={{
                  flexDirection: "column",
                  alignItems: "baseline",
                  gap: "20px",
                  paddingBottom: "25px",
                }}
              >
                <Skeleton.Input
                  active
                  size="small"
                  style={{ width: "200px" }}
                />
                <Skeleton.Input
                  active
                  size="large"
                  style={{ width: "534px", marginBottom: "15px" }}
                />
                <Skeleton.Input
                  active
                  size="small"
                  style={{ width: "200px" }}
                />
                <Skeleton.Input
                  active
                  size="large"
                  style={{ width: "534px" }}
                />
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: "25px",
                  }}
                >
                  <Skeleton.Button
                    active
                    size="large"
                    style={{ width: "370px" }}
                  />
                  <Skeleton.Input
                    active
                    size="small"
                    style={{ width: "200px" }}
                  />
                </div>
              </FlexContainer>
            </>
          )}
        </FlexContainer>
      </FlexMain>
    )
  );
};

export default Login;
