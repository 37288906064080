export const updateBreadcrumb = (e, setBim, setTipop, setTipom) => {
    switch (e.target.value) {
      case "1":
        setBim("1");
        break;
      case "2":
        setBim("2");
        break;
      case "3":
        setBim("3");
        break;
      case "4":
        setBim("4");
        break;
      case "S":
        setTipom("S");
        break;
      case "C":
        setTipom("C");
        break;
      case "VP":
        setTipop("VP");
        break;
      case "VG":
        setTipop("VG");
        break;
      case "MEDIA":
        setTipom("S");
        setTipop("MEDIA");
        break;
      default:
    }
  };

  export default updateBreadcrumb;