import React from 'react'

import * as S from './styled'

const Bars = () => {
  const backgroundColors = ['012326', '025940', '03A65A', '26BF4C', 'F0F2B3']

  return (
    <S.Bars>
      {backgroundColors.map((backgroundColor, i) => (
        <S.Bar backgroundColor={backgroundColor} key={i} />
      ))}
    </S.Bars>
  )
}

export default Bars
