import React from "react";
import { Space, Tooltip } from "antd";
import { PhoneOutlined } from "@ant-design/icons";
import { ReactComponent as ThumbsUp } from "../../../../../assets/icons/thumbsUp.svg";
import { ReactComponent as PercentDown } from "../../../../../assets/icons/percentDown.svg";

const InfoAluno = ({
  handleClickAtendimento,
  Registro,
  handleClickReducao,
  handleClickBeneficio,
  qtdAtendimentos,
  setQtdAtendimentos,
  reducao,
  beneficios,
  perfil
}) => {
  const estilo = {
    backgroundColor: "#fff",
    padding: "8px",
    borderRadius: "8px",
    minWidth: "60px",
  };

  const estiloSpace = {
    marginBottom: "30px",
    marginTop: "15px",
    backgroundColor: "#f0f0f0",
    width: "100%",
    padding: "15px",
    borderRadius: "8px",
  };

  return (
    <>{perfil === true ?
      <Space style={estiloSpace}>


           <Tooltip title={qtdAtendimentos?.length > 0 ? "Ver atendimentos" : "Não há atendimentos" }>
            <span
              style={{...estilo, cursor: "pointer"}}
              onClick={() => {handleClickAtendimento({ ra: "", nome: "" }, true)}}
            >
              <PhoneOutlined style={{ fontSize: "1.5em" }} /> (
              {qtdAtendimentos?.length ?? 0})
            </span>
          </Tooltip>

        <Tooltip title={reducao?.length > 0 ? "Ver redução" : "Não há redução" }>
          <span
            style={{...estilo, cursor: reducao?.length > 0 ? "pointer" : "not-allowed",}}
            onClick={() => {if (reducao?.length > 0) handleClickReducao({ ra: "", nome: "" }, true)}}
          >
            <PercentDown style={{ width:'20px', height:'20px'  }} /> (
            {reducao?.length ?? 0})
          </span>
        </Tooltip>

        <Tooltip
          title={
            beneficios?.length > 0 ? "Ver Benefícios" : "Não há benefícios"
          }
        >
          <span
            style={{
              ...estilo,
              cursor: beneficios?.length > 0 ? "pointer" : "not-allowed",
            }}
            onClick={() => {
              if (beneficios?.length > 0)
                handleClickBeneficio({ ra: "", nome: "" }, true);
            }}
          >
            <ThumbsUp style={{ width: "20px", height: "20px" }} /> (
            {beneficios?.length ?? 0})
          </span>
        </Tooltip>
      </Space>:''}
    </>
  );
};

export default InfoAluno;
