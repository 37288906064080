import React, { memo, useRef, useEffect } from "react";
import { useState } from "react";
import { Tabela } from "../ModalReducao/styles/Style";
import { Empty } from "antd";
import { IconeTriste } from "../../../../../components/Icons/icons";

const TableBeneficio = ({ Registro, beneficios }) => {
  const refDataTable = useRef(null);
  const [dados, setDados] = useState();

  const colunas = React.useMemo(() => { 
    return [
      {
        label: "Benefício",
        field: "beneficio",
      },
      {
        label: "Observações",
        field: "observacao",
        innerWidth: 1000,
        outerWidth: 1000,
        width: 1000,
      },
      {
        label: "Usuario Cadastro",
        field: "usuarioCadastro",
      },
      {
        label: "Data Cadastro",
        field: "dataCadastro",
      }
    ];
  }, []);

  function formatarData(dataOriginal) {
    const data = new Date(dataOriginal);

    function adicionarZero(numero) {
      if (numero < 10) {
        return "0" + numero;
      }
      return numero;
    }

    const dia = adicionarZero(data.getDate());
    const mes = adicionarZero(data.getMonth() + 1);
    const ano = data.getFullYear();
    // const horas = adicionarZero(data.getHours());
    // const minutos = adicionarZero(data.getMinutes());

    const dataFormatada = `${dia}/${mes}/${ano}`;

    return dataFormatada;
  }

  const dadosDaTabela = React.useMemo(() => {
    return {
      columns: colunas,
      rows: dados?.map((item, index) => ({
        ...item,
        usuarioCriacao: item?.usuarioCadastro.toUpperCase(),
        dataCadastro: formatarData(item.dataCadastro) ?? "",
        key: index.toString()
      })),
    };
  }, [colunas, dados]);

  useEffect(() =>{
    if(beneficios){
    setDados(beneficios);
    }
  },[beneficios])

  return (
    <Tabela
      striped={dadosDaTabela?.rows?.length > 0 ? true : false}
      hover={dadosDaTabela?.rows?.length > 0 ? true : false}
      small
      style={{ marginTop: "60px" }}
      noBottomColumns
      displayEntries={false}
      entries={20}
      infoLabel={["Mostrando", "a", "de", "registros"]}
      paginationLabel={["<", ">"]}
      paging={dadosDaTabela?.rows?.length > 20 ? true : false}
      pagesAmount={5}
      // searchLabel=" "
      searching={false}
      responsive
      disableRetreatAfterSorting={true}
      data={dadosDaTabela}
      ref={refDataTable}
      noRecordsFoundLabel={
       ( <Empty
          image={<IconeTriste />}
          description={"Não foram encontrados resultados"}
          style={{ marginTop: "50px", marginBottom: "50px" }}
        />)
      }
    />
  );
};

export default memo(TableBeneficio);
