import { createSlice } from "@reduxjs/toolkit";
import {
  STORAGE_TOKEN,
  STORAGE_TIPO_CURSO,
  STORAGE_USER,
} from "Const/webConst";

const getStorageUser = () => {
  const storageUser = sessionStorage.getItem(STORAGE_USER);
  if (!storageUser) return {};
  return JSON.parse(atob(storageUser));
};

const usuarioSlice = createSlice({
  name: "usuario",
  initialState: {
    autenticado: !!sessionStorage.getItem(STORAGE_TOKEN),
    autenticando: false,
    usuario: getStorageUser(),
    checandoAcesso: false,
    checandoAcessoSucesso: false,
    checandoAcessoFalhou: false,
    tiposCursos: [],
    listandoTiposCursos: false,
    listagemTiposCursosSucesso: false,
    listagemTiposCursosFalhou: false,
    tipoCursoAtual: {},
    atualizandoTipoCursoAtual: false,
    atualizacaoTipoCursoAtualSucesso: false,
    atualizacaoTipoCursoAtualFalhou: false,
  },
  reducers: {
    autenticar: (state, action, outro) => {
      state.autenticado = !!sessionStorage.getItem(STORAGE_TOKEN);
      state.autenticando = true;
      state.usuario = getStorageUser();
    },
    autenticarSGE: (state, action, outro) => {
      state.autenticado = !!sessionStorage.getItem(STORAGE_TOKEN);
      state.autenticando = true;
      state.usuario = getStorageUser();
    },
    autenticarSucesso: (state, action) => {
      state.autenticado = true;
      state.autenticando = false;
      state.usuario = action.payload;
    },
    autenticarIp: (state) => {
      state.autenticado = !!sessionStorage.getItem(STORAGE_TOKEN);
      state.autenticando = true;
      state.usuario = getStorageUser();
    },
    sair: (state) => {
      localStorage.removeItem(STORAGE_TIPO_CURSO);
      sessionStorage.removeItem(STORAGE_USER);
      sessionStorage.removeItem(STORAGE_TOKEN);
      state.autenticado = false;
      state.username = {};
    },
    checarAcesso: (state) => {
      state.checandoAcesso = true;
      state.checandoAcessoSucesso = false;
    },
    checarAcessoSucesso: (state, action) => {
      state.checandoAcesso = false;
      state.checandoAcessoSucesso = action.payload.permitido;
    },
    checarAcessoFalhou: (state) => {
      state.checandoAcesso = false;
      state.checandoAcessoFalhou = true;
    },
    getTiposCursos: (state) => {
      state.tiposCursos = [];
      state.listandoTiposCursos = true;
      state.listagemTiposCursosSucesso = false;
      state.listagemTiposCursosFalhou = false;
    },
    getTiposCursosSucesso: (state, action) => {
      state.listandoTiposCursos = false;
      state.tiposCursos = action.payload.tiposCursos;
      state.tipoCursoAtual = action.payload.tipoCursoAtual;
    },
    atualizarTipoCursoAtual: (state) => {
      state.tipoCursoAtual = {};
      state.atualizandoTipoCursoAtual = true;
      state.atualizacaoTipoCursoAtualSucesso = false;
      state.atualizacaoTipoCursoAtualFalhou = false;
    },
    atualizarTipoCursoAtualSucesso: (state, action) => {
      state.tipoCursoAtual = action.payload;
      state.atualizandoTipoCursoAtual = false;
      state.atualizacaoTipoCursoAtualSucesso = true;
    },
  },
});

export const { actions: UsuarioActions, reducer: usuarioReducer } = usuarioSlice;

export default usuarioSlice.reducer;
