// src/redux/ducks/commons.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  mainPage: "Inicial",
  changePage: "Inicial",
  isEmail: false,
  sge: false,
  page: "Graficos",
  comesFromEmail: false,
  dataComplete: null,
  dataAll: null,
  serie: null,
  serieSelecionada: null,
  tipoTurma:null,
  tipoTurmaSelecionado: null
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setMainPage: (state, action) => {
      state.mainPage = action.payload;
    },
    setChangePage: (state, action) => {
      state.changePage = action.payload;
    },
    setIsEmail: (state, action) => {
      state.isEmail = action.payload;
    },
    setSGE: (state, action) => {
      state.sge = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setComesFromEmail: (state, action) => {
      state.comesFromEmail = action.payload;
    },
    setDataComplete: (state, action) => {
      state.dataComplete = action.payload;
    },
    setDataAll: (state, action) => {
      state.dataAll = action.payload;
    },
    setSerie: (state, action) => {
      state.serie = action.payload;
    },
    setSerieSelecionada: (state, action) => {
      state.serieSelecionada = action.payload;
    },
    setTipoTurma: (state, action) => {
      state.tipoTurma = action.payload;
    },
    setTipoTurmaSelecionado: (state, action) => {
      state.tipoTurmaSelecionado = action.payload;
    },
  },
});

export const {
  setMainPage,
  setChangePage,
  setIsEmail,
  setSGE,
  setPage,
  setComesFromEmail,
  setDataComplete,
  setDataAll,
  setSerie,
  setSerieSelecionada,
  setTipoTurma,
  setTipoTurmaSelecionado
} = appSlice.actions;

export default appSlice.reducer;
