import { takeLatest, put, all, call } from "redux-saga/effects";
import { jwtDecode } from "jwt-decode";
import { UsuarioActions } from "../ducks/usuario";
import { RequestActions as RequestCreators } from "../ducks/request";
import { REQUISICAO_FALHOU, REQUISICAO_SUCESSO } from "Const/webConst";
import ApiUsuarios from "api/ApiUsuarios";
import getUserInfo from "Services/User/Services";

const api = ApiUsuarios();

function* autenticar(action) {
  try {
    yield put(RequestCreators.beginRequest());
    const { data } = yield call(
      api.autenticar,
      action.payload.usuario,
      action.payload.senha
    );

    if (data.access_token) {
      const expires_in = data.expires_in;
      const token = data.access_token;
      const { nameid, unique_name } = jwtDecode(token);

      try {
        const usuarioCompleto = yield call(getUserInfo, nameid);

        sessionStorage.setItem("fbeducacional_token", token);
        sessionStorage.setItem(
          "fbeducacional_user",
          btoa(
            JSON.stringify({
              nameid,
              unique_name,
              expires_in,
              ...usuarioCompleto.return,
            })
          )
        );

        yield put(
          UsuarioActions.autenticarSucesso({
            nameid,
            unique_name,
            expires_in,
            ...usuarioCompleto.return,
          })
        );
        yield put(
          RequestCreators.showMessageSuccess("Requisição bem-sucedida")
        );
      } catch (userInfoError) {
        console.error(
          `Erro ao buscar informações do usuário -> ${
            JSON.stringify(userInfoError) || userInfoError
          }`
        );
        yield put(
          RequestCreators.showMessageFailure(
            "Falha ao buscar informações do usuário"
          )
        );
      }
    }
  } catch (err) {
    console.error(
      `Erro na requisição -> ${JSON.stringify(err.response) || err}`
    );
    if (err.response && err.response.status === 401) {
      yield put(RequestCreators.showMessageFailure(err.response.data));
    } else {
      yield put(RequestCreators.showMessageFailure("Requisição falhou"));
    }
  }
}

function* autenticarSGE(action) {
  try {
    yield put(RequestCreators.beginRequest());
    if (action) {
      const { payload } = action;
      const { usuario } = payload;
      const token = usuario;
      const { nameid, unique_name, exp } = jwtDecode(token);

      try {
        const usuarioCompleto = yield call(getUserInfo, nameid);

        sessionStorage.setItem("fbeducacional_token", token);
        sessionStorage.setItem(
          "fbeducacional_user",
          btoa(
            JSON.stringify({
              nameid,
              unique_name,
              expires_in: exp,
              ...usuarioCompleto.return,
            })
          )
        );

        yield put(
          UsuarioActions.autenticarSucesso({
            nameid,
            unique_name,
            expires_in: exp,
            ...usuarioCompleto.return,
          })
        );
        yield put(
          RequestCreators.showMessageSuccess("Requisição bem-sucedida")
        );
      } catch (userInfoError) {
        console.error(
          `Erro ao buscar informações do usuário -> ${
            JSON.stringify(userInfoError) || userInfoError
          }`
        );
        yield put(
          RequestCreators.showMessageFailure(
            "Falha ao buscar informações do usuário"
          )
        );
      }
    }
  } catch (err) {
    console.error(
      `Erro na requisição -> ${JSON.stringify(err.response) || err}`
    );
    if (err.response && err.response.status === 401) {
      yield put(RequestCreators.showMessageFailure(err.response.data));
    } else {
      yield put(RequestCreators.showMessageFailure("Requisição falhou"));
    }
  }
}

function* autenticarIp() {
  try {
    yield put(RequestCreators.beginRequest());
    const { data } = yield call(api.autenticarIp);
    if (data.access_token) {
      const expires_in = data.expires_in;
      const token = data.access_token;
      const { nameid, unique_name } = jwtDecode(token);

      sessionStorage.setItem("fbeducacional_token", token);
      sessionStorage.setItem(
        "fbeducacional_user",
        btoa(JSON.stringify({ nameid, unique_name, expires_in }))
      );

      yield put(
        UsuarioActions.autenticarSucesso({ nameid, unique_name, expires_in })
      );
      yield put(RequestCreators.showMessageSuccess(REQUISICAO_SUCESSO));
    }
  } catch (err) {
    console.error(
      `Erro na requisição -> ${JSON.stringify(err.response) || err}`
    );
  } finally {
    yield put(RequestCreators.resetRequest());
  }
}

function* checarAcesso(action) {
  try {
    yield put(RequestCreators.beginRequest());
    const { data } = yield call(api.checarAcesso, action.payload.codigo);
    yield put(UsuarioActions.checarAcessoSucesso({ permitido: data }));
  } catch (err) {
    console.error(
      `Erro na requisição -> ${JSON.stringify(err.response) || err}`
    );
    yield put(RequestCreators.showMessageFailure(REQUISICAO_SUCESSO));
    yield put(UsuarioActions.checarAcessoFalhou());
  } finally {
    yield put(RequestCreators.resetRequest());
  }
}

function* getTiposCursos() {
  try {
    yield put(RequestCreators.beginRequest());

    let { data } = yield call(api.getTiposCursos);
    let tipoCursoAtual = null;

    data = data || [];

    if (data.some((tc) => tc.padrao)) {
      tipoCursoAtual = data.find((tc) => tc.padrao);
    } else {
      tipoCursoAtual = data[0];
    }

    if (tipoCursoAtual) {
      localStorage.setItem(
        "fbeducacional_tipo_curso",
        tipoCursoAtual.codigoTipoCurso
      );

      yield put(
        UsuarioActions.getTiposCursosSucesso({
          tiposCursos: data,
          tipoCursoAtual,
        })
      );
    } else {
      yield put(
        RequestCreators.showMessageFailure(
          "Desculpe, você não possui acesso a nenhum Tipo de Curso. Entre em contato com a Central de TI no 7862 ou mande um e-mail para ti.atendimento@fariasbrito.com.br solicitando acesso."
        )
      );
    }
  } catch (err) {
    console.error(
      `Erro na requisição -> ${JSON.stringify(err.response) || err}`
    );
    yield put(RequestCreators.showMessageFailure(REQUISICAO_FALHOU));
  } finally {
    yield put(RequestCreators.resetRequest());
  }
}

function* atualizarTipoCursoAtual(action) {
  if (action.payload.tipoCurso === undefined) {
    action.payload = { ...action.payload, tipoCurso: action.payload };
  }
  try {
    yield put(RequestCreators.beginRequest());

    yield call(
      api.atualizarTipoCursoAtual,
      action.payload.tipoCurso.codigoTipoCurso
    );

    localStorage.setItem(
      "fbeducacional_tipo_curso",
      action.payload.tipoCurso.codigoTipoCurso
    );

    yield put(
      UsuarioActions.atualizarTipoCursoAtualSucesso(action.payload.tipoCurso)
    );
    yield put(RequestCreators.showMessageSuccess(REQUISICAO_SUCESSO));
  } catch (err) {
    console.error(
      `Erro na requisição -> ${JSON.stringify(err.response) || err}`
    );
    yield put(RequestCreators.showMessageFailure(REQUISICAO_FALHOU));
  }
}

export default function* usuario() {
  yield all([
    takeLatest(UsuarioActions.autenticar.type, autenticar),
    takeLatest(UsuarioActions.autenticarIp.type, autenticarIp),
    takeLatest(UsuarioActions.checarAcesso.type, checarAcesso),
    takeLatest(UsuarioActions.getTiposCursos.type, getTiposCursos),
    takeLatest(
      UsuarioActions.atualizarTipoCursoAtual.type,
      atualizarTipoCursoAtual
    ),
    takeLatest(UsuarioActions.autenticarSGE.type, autenticarSGE),
  ]);
}
