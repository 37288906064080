import { Modal } from "antd";
import React, { memo, useState, useEffect } from "react";
import TableDetalhes from "./TableDetalhes";
import HeaderModal from "./HeaderModal";
import InfoAluno from "./InfoAluno";
import ModalAtendimentos from "../ModalAtendimentos/ModalAtendimentos";
import { useCallback } from "react";
import ModalReducao from "../ModalReducao/ModalReducao";
import ModalBeneficios from "../ModalBeneficios/ModalBeneficios";
import { getAtendimentos } from "../../../../../Services/Atendimento/Service";
import { getBeneficios, getReducoes } from "../../../../../Services/Merito/Service";



const ModalDetalhes = ({
  visible,
  setVisible,
  current,
  data,
  bim,
  tipop,
  tipom,
  perfil
}) => {
  const [visibleAtendimentos, setVisibleAtendimentos] = useState(false);
  const [visibleReducao, setVisibleReducao] = useState(false);
  const [visibleBeneficio, setVisibleBeneficio] = useState(false);

  const [qtdAtendimentos, setQtdAtendimentos] = useState();
  const [beneficios, setBeneficios] = useState();
  const [reducao, setReducao] = useState();
  const [dados, setDados] = useState();




  const handleClickAtendimento = useCallback((params, visibility) => {
    setVisibleAtendimentos(visibility);
  }, []);

  const handleClickReducao = useCallback((params, visibility) => {
    setVisibleReducao(visibility);
  }, []);

  const handleClickBeneficio = useCallback((params, visibility) => {
    setVisibleBeneficio(visibility);
  }, []);

  useEffect(() => {
    if(current){
    getAtendimentos(current?.ra).then((resultado) =>
      setQtdAtendimentos(resultado)
    );

    getReducoes(current?.ra).then((resultado) => setReducao(resultado));

    getBeneficios(current?.ra).then((resultado) => setBeneficios(resultado));
    }
  }, [current]);

  return (
    <Modal
      open={visible}
      destroyOnClose={true}
      closable={true}
      maskClosable={true}
      keyboard={false}
      width={660}
      onCancel={() => setVisible("", false)}
      footer={""}
      height={200}
      className="modalDetalhes"
    >
      <HeaderModal current={current} data={data} perfil={perfil} />
      <InfoAluno
        handleClickAtendimento={handleClickAtendimento}
        handleClickReducao={handleClickReducao}
        handleClickBeneficio={handleClickBeneficio}
        Registro={current}
        qtdAtendimentos={qtdAtendimentos}
        setQtdAtendimentos={setQtdAtendimentos}
        reducao={reducao}
        beneficios={beneficios}
        perfil={perfil}
      />
      <TableDetalhes dados={data.filter((x) => x.ra === current?.ra)} perfil={perfil} />
      <ModalAtendimentos
        Registro={current}
        visibleAtendimentos={visibleAtendimentos}
        setVisibleAtendimentos={setVisibleAtendimentos}
        dados={dados} setDados={setDados}
      />
      <ModalReducao
        Registro={current}
        visibleReducao={visibleReducao}
        setVisibleReducao={setVisibleReducao}
        reducao={reducao}
      />
      <ModalBeneficios 
      Registro={current}
      visibleBeneficio={visibleBeneficio}
      setVisibleBeneficio={setVisibleBeneficio}
      beneficios={beneficios}
      />
    </Modal>
  );
};

export default memo(ModalDetalhes);
