import React, { memo, useRef, useEffect } from "react";
import { getAtendimentos } from "../../../../../Services/Atendimento/Service";
import { Tabela } from "./styles/Style";
import { Empty } from "antd";
import { IconeTriste } from "../../../../../components/Icons/icons";

const TableAtendimentos = ({ Registro, dados, setDados }) => {
  const refDataTable = useRef(null);

  const colunas = React.useMemo(() => { 
    return [
      {
        label: "Data da Ocorrencia",
        field: "dataOcorrencia",
      },
      {
        label: "Observações",
        field: "observacoes",
        innerWidth: 1000,
        outerWidth: 1000,
        width: 1000,
      },
      {
        label: "Usuario Criação",
        field: "usuarioCriacao",
      },
      {
        label: "Data Criação",
        field: "dataCriacao",
      },
      {
        label: "Data Publicação",
        field: "dataPublicacao",
      },
    ];
  }, []);

  function formatarData(dataOriginal) {
    const data = new Date(dataOriginal);

    function adicionarZero(numero) {
      if (numero < 10) {
        return "0" + numero;
      }
      return numero;
    }

    const dia = adicionarZero(data.getDate());
    const mes = adicionarZero(data.getMonth() + 1);
    const ano = data.getFullYear();
    const horas = adicionarZero(data.getHours());
    const minutos = adicionarZero(data.getMinutes());

    const dataFormatada = `${dia}/${mes}/${ano} às ${horas}:${minutos}`;

    return dataFormatada;
  }

  const dadosDaTabela = React.useMemo(() => {
    return {
      columns: colunas,
      rows: dados?.map((item, index) => ({
        ...item,
        dataCriacao: formatarData(item.dataCriacao),
        dataOcorrencia: formatarData(item.dataOcorrencia),
        usuarioCriacao: item.usuarioCriacao.toUpperCase(),
        dataPublicacao: item.dataPublicacao === null ? "" : formatarData(item.dataPublicacao),
        key: index.toString()
      })),
    };
  }, [colunas, dados]);

  useEffect(() => {
    getAtendimentos(Registro.ra).then((resultado) => setDados(resultado));
  }, [Registro, setDados]);

  return (
    <Tabela
      striped={dadosDaTabela?.rows?.length > 0 ? true : false}
      hover={dadosDaTabela?.rows?.length > 0 ? true : false}
      // autoWidth={true}
      small
      style={{ marginTop: "60px" }}
      noBottomColumns
      displayEntries={false}
      entries={20}
      infoLabel={["Mostrando", "a", "de", "registros"]}
      paginationLabel={["<", ">"]}
      paging={dadosDaTabela?.rows?.length > 20 ? true : false}
      pagesAmount={5}
      // searchLabel=" "
      searching={false}
      responsive
      disableRetreatAfterSorting={true}
      data={dadosDaTabela}
      ref={refDataTable}
      noRecordsFoundLabel={
      <Empty
          image={<IconeTriste />}
          description={"Não foram encontrados resultados"}
          style={{ marginTop: "50px", marginBottom: "50px" }}
        />
      }
    />
  );
};

export default memo(TableAtendimentos);
