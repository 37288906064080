import { Button, Radio } from 'antd'
import { DoubleLeftOutlined } from '@ant-design/icons';
import styled from 'styled-components'

export const PieCharts = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${props => !props.responsive ? "" : "27px"} !important;
`

export const PieChartContainer = styled.div`
    width: ${props => !props.responsive ? "100%" : "fit-content"} !important;
    min-width: ${props => !props.responsive ? "" : "300px"} !important;
    /* margin-right: ${props => !props.responsive ? "" : "20px"} !important; */

    display: flex;
    flex-direction: column;
    gap: 25px;

    @media screen and (max-width: 600px) {
        justify-content: center;
        align-items: center;
        gap:10px;
    }
`

export const FilrosContainer = styled.div`
    display: flex;
    align-items: center;

    color: #727272;

    width: 100%;
    height: 40px;

    margin-top: 25px;
    margin-bottom: 25px;

    border-radius: 8px;
    background-color:#D9D9D9;

    @media screen and (max-width: 974px) {
        width: 94%;
        height: auto;
        padding-bottom: 15px;
        
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: start;
        align-items: start;
    }

`
export const RadioGroup = styled(Radio.Group)`
    display: flex;
    @media screen and (max-width: 1200px) {
        display: flex;
    }

    @media screen and (max-width: 975px) {
        display: flex;
    }
`


export const RadioButton = styled(Radio.Button)`
    white-space: pre;

    margin-top: ${props => !props.margintop ? 0 : props.margintop} !important;
    margin-bottom: ${props => !props.marginbottom ? 0 : props.marginbottom};
    margin-left: ${props => !props.marginleft ? 0 : props.marginleft};
    margin-right: ${props => !props.marginright ? 0 : props.marginright};

    @media screen and (max-width: 974px) {
        margin: 0;
        margin-left: ${props => !props.marginleftmobile ? 0 : props.marginleftmobile};

        display:flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        max-height: 40px;
        height: 40px !important;
        width: 80px;

        &:hover {
            max-height: 40px;
            height: 40px !important;
            width: 80px;    
        }
    }

    @media screen and (min-width: 975px) and (max-width: 1200px) {
        margin-left: 3px !important;
        margin-right: 0px !important;
    }
`
export const StyledP  = styled.p`
    margin-left: ${props => !props.marginleft ? 0 : props.marginleft};

    color: #727272;

    text-transform: uppercase;
    font-weight: 500;
    font-size: .8em;
    letter-spacing: .04em;
    margin-top: 15px;

    @media screen and (max-width: 975px) {
        margin:0;
        margin-top: 15px;
        margin-left:${props => !props.marginleftmobile ? 0 : props.marginleftmobile};
        margin-right: ${props => !props.marginRightMobile ? '20px' : props.marginRightMobile};
    }

    @media screen and (min-width: 975px) and (max-width: 1200px) {
        letter-spacing: .02em;
        margin-right: 5px;
        margin-left: 12px;
    }
`

export const p = styled.p`
    margin-left: ${props => !props.marginleft ? 0 : props.marginleft};

    color: #727272;

    text-transform: uppercase;
    font-weight: 500;
    font-size: .8em;
    letter-spacing: .04em;
    margin-top: 15px;

    @media screen and (max-width: 975px) {
        margin:0;
        margin-top: 15px;
        margin-left:${props => !props.marginleftmobile ? 0 : props.marginleftmobile};
        margin-right: ${props => !props.marginRightMobile ? '20px' : props.marginRightMobile};
    }

    @media screen and (min-width: 975px) and (max-width: 1200px) {
        letter-spacing: .02em;
        margin-right: 5px;
        margin-left: 12px;
    }
`

export const PieChartWrapperContainer = styled.div`
display: flex;
flex-wrap: wrap;
flex-direction: column;

background-color: #FFF;
padding: 20px 0px 20px 20px;

border-radius: 20px;

margin-bottom: 25px;

@media screen and (max-width: 975px) {
    justify-content: center;
    width: 95%;
}
`

export const PieChartWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;

    flex-direction:${props => !props.responsive ? "row" : "column"} !important;
    gap:${props => !props.responsive ? "25px" : ""} !important;

    /* gap: 65px; */

    @media screen and (max-width: 975px) {
      justify-content: center;
  }
` 

export const PieChartItemWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
` 
export const pCharTitle = styled.p`
    margin: 0px 0px 20px 0px;
    padding: 0;
    color: #009240;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.3em;
    
    white-space: break-spaces;
    word-break: break-word;
    max-width: 200px;
`


export const SeparadorFiltro = styled.div`
    height: 25px;
    width: 2px;

    margin: 0px 15px 0px 20px;

    background-color: #B1B6BA;

    @media screen and (max-width: 975px) {
        display: none;
    }

    @media screen and (min-width: 975px) and (max-width: 1200px) {
        margin: 0px 0px 0px 13px;
    }

`

export const FiliaisContainer = styled.div`
    background-color: #FFF;
    border-radius: 8px;

    padding: 8px;

    display: flex;
    justify-content: space-evenly;

    width: 100%;

    margin-bottom: 15px;

    font-weight: 500;

    @media screen and (max-width: 975px) {
      width: 95%;

      flex-wrap: wrap;
      justify-content: space-around;
    }
`

export const FilialAnchor = styled.a`
    background-color: #FFF;
    border-radius: 5px;
    
    padding: 3px;
    padding-left: 5px;
    padding-right: 5px;

    color: rgb(0, 146, 64) !important;

    &:hover{
      background-color: #F1F1F1;
    }

    @media screen and (max-width: 975px) {
      margin: 5px;
    }
`

export const DataProcessamentoDiv = styled.span`
    letter-spacing: .05em;
    font-size: .7em;
    color: #777;

    position: relative;
    width: 100%;

    display: flex;
    justify-content: flex-end;
    align-items: center;
`

export const ArrowUpContainer = styled.div`
    position: fixed;
    right: 3%;
    bottom: 5%;

    font-size: 2.5em;
`

export const ArrowUp = styled.a`
`
export const TitleWrapperContainer = styled.div`
    width: 100%;

    display: flex;
    justify-content: start;

    margin-bottom: 25px;

    align-items: center;

    @media screen and (max-width: 600px) {
      width: 90%;
    }
`
export const TitleWrapper = styled.div`
    width:100%;
    display: flex;
    flex-direction: column;
    color: #009240;

    text-transform: uppercase;

    /*margin-bottom: 25px;*/

    @media screen and (max-width: 600px) {
        width: 100%;
        padding-left: 20px;
        justify-content: start;
    }
`

export const DoubleArrowIcon = styled(DoubleLeftOutlined)`
    cursor: pointer;

    font-size: 1.2em;

    display: flex;
    justify-content: center;

    width: 40px;
    height: 40px;

    border-radius: 100%;
    background-color: #FFF;

    margin-right: 25px;
    margin-left: -25px;


    color: rgb(0, 146, 64);

    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

      &:hover{
        color: #FFF;

        background-color: rgb(0, 146, 64);
      }

      @media screen and (max-width: 600px) {
        width: 50px;

        margin-right: 15px;
        margin-left: 0px;
      }
`

export const containerSuperior = styled.div`
    display: flex;
    align-items: center;

    justify-content: space-between;

    margin-right: -25px;

    @media screen and (max-width: 600px) {
      margin-top: 45px;
      margin-left: -10px;
      flex-direction: column;
      justify-content: start;
      align-items: start;

      width:100%;
      max-width: 100%;

      }
`

export const containerPaginaSelecionada = styled.div`
    display: flex;
    align-items: center;

    @media screen and (max-width: 600px) {
      margin-bottom: 0px;

      position: absolute;
      left: 20px;
      top: 300px;
    }
`

export const containerMedia = styled.div`
    display: flex;
    align-items: center;

    gap: 15px;

    @media screen and (max-width: 600px) {
      margin-top: 15px;
    }
`

export const containerMediaDisciplina = styled.div`
    display: flex;
    align-items: center;

    font-size: .8em !important;

    margin-left:8px;
`

export const containerMediaDisc = styled.div`
    background-color: #FFF;
    padding: 10px;

    font-size: 1.2em;

    border-radius: 12px;

    font-weight: 600;

    display: flex;
    justify-content: center;
    align-items: center;
`

export const Span = styled.span`
  color:#A9A9A9;
  font-weight:500;

  @media screen and (max-width: 600px) {
    font-size: .8em;
    white-space: pre;
}
`

export const h1 = styled.h1`
    text-transform: uppercase;
    font-size: 2em;
    font-weight: 500;
    margin:0;

    color: #009240;


    @media screen and (max-width: 600px) {
        font-size: 1.2em;
        white-space: pre;
    }

`

export const h2 = styled.h2`
    font-weight: 500;
    font-size: 1.2em;

    color: #009240;
    text-transform: uppercase;

    margin:0;

    @media screen and (max-width: 600px) {
        font-size: .8em;
        margin-left: 0px;
        margin-top: 20px;
        margin-bottom:20px;
    }

    @media screen and (min-width: 601px) and (max-width: 1200px) {
    }
`

export const nivelTituloContainer = styled.div`
    width: ${props => !props.responsive ? "100%" : "fit-content"} !important;
    display: flex;
    align-items: end;
    margin-bottom: -15px;
    margin-top: 15px;
`

export const legendaContainer = styled.div `
    display: ${props => !props.responsive ? "flex" : "none"} !important;
    flex-direction: column;

    margin-top: 65px;
    padding: 15px;
    max-width: 600px;
    background-color: #F9F9F9;

    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;

    @media screen and (max-width: 600px) {
      max-width: 320px;
    }
`

export const legendaItemContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: .7em;
    gap: 6px;

    @media screen and (max-width: 600px) {
      font-size: .65em;
    }
`

export const legendaItem = styled.div`
    display: flex;
    width: 10px;
    height: 10px;
    min-width: 10px;
`

export const ButtonContainer = styled.div `
    display: flex;
    flex-direction: column;

    gap: 20px;
`

export const ButtonVisao = styled(Button)`
    margin: 0 auto;

    width: 80%;
    height: 80px;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;

    border-radius: 8px;

    color: #009240;
    text-transform: uppercase;
    font-size: 1.2em;
    letter-spacing: .03em;

    &:hover{
        background-color: #009240 !important;
        color: #FFF !important;
        fill: #FFF;
    }
`

export const IconContainer = styled.div`
    width: 30%;

    display: flex;
    justify-content: end;

`

export const TitleContainer = styled.div`
    width: 70%;

    display: flex;
    justify-content: start;
`

export const Estilos = styled.div`
  td {
    vertical-align: middle !important;
  }

  .botao-filtrar {
    font: 600 12px "Poppins";
    color: #727272;
    border-radius: 5px 0 0 5px;
  }
  .botao-exportar {
    font: 600 12px "Poppins";
    color: #727272;
    border-radius: 0 5px 5px 0;
  }

  .container-botoes-superior {
    position: absolute;
    right: 32px;
    top: 25px;
    display: flex;
    flex-direction: row;
  }
  .container-botoes-inferior {
    position: absolute;
    right: 32px;
    bottom: 52px;
    display: flex;
    flex-direction: row;
  }

  .ant-card-body {
    padding: 17px !important;
  }

  div.ant-card.ant-card-bordered {
    border: 1px solid #f0f0f0 !important;
  }

  .ant-empty-description {
    font: 14px "Poppins";
    color: #b1b1b1;
  }

  div.ant-drawer-header {
    border-bottom: none !important;
  }

  .ant-empty {
    margin: 94px auto;
  }

  .mdb-datatable-filter {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .mdb-datatable-filter::before {
    content: "Buscar na tabela: ";
    font: 14px "Poppins";
    color: #b1b1b1;
    position: absolute;
    top: 10px;
    right: 216px;
  }

  table > thead > tr > th {
    font: 500 13px "Poppins";
    padding-left: 15px;
    color: #727272;
    background: #efefef;
    border: none;
    border-right: medium solid white;
  }

  .table td {
    border-top: none;
  }

  .dataTables_wrapper.dt-bootstrap4 > div:nth-child(2) {
    margin-top: 15px;
  }

  .dataTables_wrapper.dt-bootstrap4 > div:last-child {
    background: #efefef;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    margin: auto 0px;
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background: transparent;
  }

  .table-striped tbody tr:nth-of-type(even) {
    background: rgba(240, 173, 78, 0.11);
  }

  table.table-sm td {
    font: 13px "Poppins";
    color: #727272;
  }

  /***************************************************
* ELEMENTOS DE PAGINAÇÃO
****************************************************/

  div.dataTables_paginate > ul.pagination > li.page-item {
    background: white;
  }

  div.dataTables_paginate > ul.pagination > li.page-item > a > span {
    font: 500 14px "Poppins";
    color: #727272;
  }

  .page-item.active > a {
    font: 500 14px "Poppins";
    color: #727272 !important;
    background-color: #ffefd8 !important;
    border: none;
  }

  div.dataTables_info[role="status"] {
    font: 500 14px "Poppins";
    text-align: right;
    color: #b1b1b1;
  }
`;