import { useCallback } from 'react';
import { useSelector } from 'react-redux';

const useOrganizeNotas = (dados) => {

  const {tipoTurmaSelecionado} = useSelector((state) => state.commons);

  function filterData(data) {
    var dadosFiltrados = data.filter(filial => {
      const filteredBimestres = filial.bimestres.map(bimestre => {
          const filteredAvaliacoes = bimestre.avaliacoes.map(avaliacao => {
              const filteredNivelEnsino = avaliacao.nivelEnsino.map(nivel => {
                  const filteredSeries = nivel.series.filter(serie => serie.quantidade > 0);
                  return {
                      ...nivel,
                      series: filteredSeries
                  };
              }).filter(nivel => nivel.series.length > 0);

              return {
                  ...avaliacao,
                  nivelEnsino: filteredNivelEnsino
              };
          }).filter(avaliacao => avaliacao.nivelEnsino.length > 0);

          return {
              ...bimestre,
              avaliacoes: filteredAvaliacoes
          };
      }).filter(bimestre => bimestre.avaliacoes.length > 0);

      filial.bimestres = filteredBimestres;
      return filteredBimestres.length > 0;
  });

    return dadosFiltrados;
}

  const organizeNotas = useCallback((tipom, setQtd, setQtdFiliais) => {
      const nomesUnicosPorFilial = [];

      dados.forEach((dadosFilial) => {
        const { filial, codFilial, dados: dadosFilialArray } = dadosFilial;

        const nomesUnicosPorBimestre = [];

        dadosFilialArray.forEach((dadosBimestre) => {
          const { bimestre, dadosAvaliacao } = dadosBimestre;

          const nomesUnicosPorAvaliacao = [];

          dadosAvaliacao.forEach((dadosAvaliacao) => {
            const { tipoAvaliacao, niveisEnsino } = dadosAvaliacao;

            if (tipoAvaliacao === 'MEDIA') {
              const nomesUnicosPorNivelEnsino = [];

              niveisEnsino.forEach((niveisEnsino) => {
                const { nivelEnsino, dadosSerie } = niveisEnsino;

                const nomesUnicosPorSerie = [];

                dadosSerie.forEach((dadosSerie) => {
                  const { serie, data } = dadosSerie;
                  const nomesUnicos = new Set();

                  data.forEach((dadosAluno) => {
                    const { nome, mediaNTD, mediaSemNTD } = tipoTurmaSelecionado ? (dadosAluno.tipoTurma === tipoTurmaSelecionado ? dadosAluno : {mediaNTD:100, mediaSemNTD:100}) : dadosAluno;

                    if (
                      (tipom === 'C' && mediaNTD < 70 && mediaNTD > 0) ||
                      (tipom === 'S' && mediaSemNTD < 70 && mediaSemNTD > 0)
                    ) {
                      nomesUnicos.add(nome);
                    }
                  });

                  nomesUnicosPorSerie.push({
                    serie,
                    codFilial: dadosFilial.codFilial,
                    codHabilitacao: dadosSerie.data[0].codHabilitacao,
                    quantidade: nomesUnicos.size,
                  });
                });

                nomesUnicosPorNivelEnsino.push({
                  nivelEnsino,
                  series: nomesUnicosPorSerie,
                });
              });

              nomesUnicosPorAvaliacao.push({
                tipoAvaliacao,
                nivelEnsino: nomesUnicosPorNivelEnsino,
              });
            } else {
              const nomesUnicosPorNivelEnsino = [];

              niveisEnsino.forEach((niveisEnsino) => {
                const { nivelEnsino, dadosSerie } = niveisEnsino;

                const nomesUnicosPorSerie = [];

                dadosSerie.forEach((dadosSerie) => {
                  const { serie, data } = dadosSerie;
                  const nomesUnicos = new Set();

                  data.forEach((dadosAluno) => {
                    const { nome, nota } = tipoTurmaSelecionado ? (dadosAluno.tipoTurma === tipoTurmaSelecionado ? dadosAluno : {nota:100}) : dadosAluno;

                    if (nota < 70 && nota > 0) {
                      nomesUnicos.add(nome);
                    }
                  });

                  nomesUnicosPorSerie.push({
                    serie,
                    codFilial: dadosFilial.codFilial,
                    codHabilitacao: dadosSerie.data[0].codHabilitacao,
                    quantidade: nomesUnicos.size,
                  });
                });

                nomesUnicosPorNivelEnsino.push({
                  nivelEnsino,
                  series: nomesUnicosPorSerie,
                });
              });

              nomesUnicosPorAvaliacao.push({
                tipoAvaliacao,
                nivelEnsino: nomesUnicosPorNivelEnsino,
              });
            }
          });

          nomesUnicosPorBimestre.push({
            bimestre,
            avaliacoes: nomesUnicosPorAvaliacao,
          });
        });

        nomesUnicosPorFilial.push({
          filial,
          codFilial,
          bimestres: nomesUnicosPorBimestre,
        });
      });

      setQtd(filterData(nomesUnicosPorFilial));
      setQtdFiliais(filterData(nomesUnicosPorFilial).map((item) => item.filial).length <= 3);
    },
    [dados, tipoTurmaSelecionado]
  );

  return { organizeNotas };
};

export default useOrganizeNotas;
