import styled from 'styled-components'

export const Main = styled.div`
    width:100%;
    min-height:100px;

    display: flex;
`

export const BodySection1 = styled.div`
    height: 100px;
    width: ${props => props.sge ? "0" : "5%"};

    @media screen and (max-width: 600px) {
        display: none;
    }
`

export const BodySection2 = styled.div`
    width: ${props => props.sge ? "" : "90% !important;"};
    background-color:#F6F6F6;
    min-height: 300px;
    display: flex;
    flex-wrap:wrap;
    min-width:  ${props => props.sge ? "100% !important" : ""};
    min-height:  ${props => props.sge ? "100vh !important" : ""};
    align-content:  ${props => props.sge ? "baseline" : ""};


    padding: 20px 50px 0px;

    @media screen and (max-width: 600px) {
        width: 100% !important;
        justify-content: center;
        padding: 25px 0px 20px 0px;
    }

`

export const BodySection3 = styled.div`
    height: 100px;
    width: ${props => props.sge ? "0" : "5%"};

    @media screen and (max-width: 600px) {
        display: none;
    }
`