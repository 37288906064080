import React, { useCallback } from 'react'
import * as S from "../../../styles/styled";
import PieChartComp from 'pages/Gráficos/components/PieChart';
import { useSelector } from 'react-redux';

const PieCharts = ({ item, count, bim, tipop, tipom, handleClick, COLORS, setCarregando, quantidadeTotal, setQuantidadeTotal, dados, setDados, setFilial, responsive }) => {

    return (
    <>
    {item.bimestres?.filter((x) => x.bimestre.toString() === bim)
        .length > 0 ? (
        item.bimestres
          ?.filter((x) => x.bimestre.toString() === bim)
          .map((b, indice) => (
            <S.PieChartWrapper
            className='pie2'
            responsive={responsive}
              key={`pieChart-graficos_${
                b.bimestre
              }_${indice}_${count()}`}
            >
              <PieChartComp
                data={b.avaliacoes.filter(
                  (x) => x.tipoAvaliacao === tipop
                )}
                handleClick={handleClick}
                COLORS={COLORS}
                bim={bim}
                tipop={tipop}
                tipom={tipom}
                filial={item.codFilial}
                setCarregando={setCarregando}
                quantidadeTotal={quantidadeTotal}
                setQuantidadeTotal={setQuantidadeTotal}
                dados={dados}
                setDados={setDados}
                setFilial={setFilial}
                filialNomeCurto={item.filial}
                responsive={responsive}
              />
            </S.PieChartWrapper>
          ))
      ) : (
        <p>Nenhum resultado encontrado.</p>
      )}
      </>
  )
}

export default PieCharts