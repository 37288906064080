import PropTypes from "prop-types";
import React, { useState, useCallback, memo } from "react";
import { ContainerTabela, Estilos, TituloTabela } from "../styles/styled";
import ModalDetalhes from "../../Components/Modal/ModalDetalhes";
import Tabela from "./Tabela";
import ModalAtendimentos from "../../Components/ModalAtendimentos/ModalAtendimentos";

const TableTipoTurma = ({
  dadosTipoTurma,
  status,
  data,
  bim,
  tipop,
  tipom,
  setMediaSerieFilial,
  setMediaSerieOrg,
  perfil
}) => {
  const [current, setCurrent] = useState();
  const [currentRA, setCurrentRA] = useState();
  const [visible, setVisible] = useState(false);
  const [visibleAtendimentos, setVisibleAtendimentos] = useState(false);

  const handleClick = useCallback((params, visibility) => {
    setCurrent(params);
    setVisible(visibility);
  }, []);

  const handleClickAtendimento = useCallback((params, visibility) => {
    setCurrentRA(params);
    setVisibleAtendimentos(visibility);
  }, []);

  const Titulo = ({ texto }) => {
    return <TituloTabela>{texto}</TituloTabela>;
  };

  return (
    <>
      <Estilos>
        <ContainerTabela>
          <Titulo texto="Alunos com baixo rendimento" />
          <Tabela
            dadosTipoTurma={dadosTipoTurma}
            status={status}
            handleClick={handleClick}
            setMediaSerieFilial={setMediaSerieFilial}
            setMediaSerieOrg={setMediaSerieOrg}
            visibleAtendimentos={visibleAtendimentos}
            setVisibleAtendimentos={setVisibleAtendimentos}
            handleClickAtendimento={handleClickAtendimento}
            perfil={perfil}
          />
        </ContainerTabela>
      </Estilos>
      <ModalDetalhes
        visible={visible}
        setVisible={handleClick}
        current={current}
        data={data}
        bim={bim}
        tipop={tipop}
        tipom={tipom}
        perfil={perfil}
      />
      <ModalAtendimentos
        Registro={currentRA}
        visibleAtendimentos={visibleAtendimentos}
        setVisibleAtendimentos={setVisibleAtendimentos}
      />
    </>
  );
};
TableTipoTurma.propTypes = {
  registros: PropTypes.array,
};
export default memo(TableTipoTurma);
