import axios from "axios";
import store from "../redux/store"; // ajuste o caminho conforme necessário
import { basePath } from "Const/webConst";

// Crie uma instância do Axios
const api = axios.create({
  baseURL: basePath,
});

// Adicione um interceptor para incluir o usuário em todas as requisições
api.interceptors.request.use((config) => {
  const state = store.getState();
  const {nameid} = state.usuario.usuario;

  if (nameid) {
    config.params = config.params || {};
    config.params.user = nameid;
  }

  return config;
}, (error) => {
  return Promise.reject(error);
});

export default api;
