import React from 'react';
import * as S from './styled'
import ButtonList from '../../pages/Home/HomeItems.json';
import { ButtonIcon, ButtonSimple } from './Button/Buttons'

const Main = ({changePage, setChangePage, setMainPage, mainPage, dados}) => {

  return (
    dados?.length > 0 ?
    <>
      <S.Container>
        {
          ButtonList.data.map((button) =>
            button.icone ?
              <ButtonIcon key={button.nome+button.icone} link={button.link} nome={button.nome} icone={button.icone} setMainPage={setMainPage} />
              : <ButtonSimple key={button.nome+button.link} link={button.link} nome={button.nome} icone={button.icone} setMainPage={setMainPage}/>)
        }
      </S.Container>
    </>
    : ''
  )
}

export default Main;