import React, { useContext } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ConfigProvider } from 'antd';
import { GlobalStyle } from './styles/GlobalStyles';
import GlobalContext from './global/Context';
import 'overlayscrollbars/overlayscrollbars.css';
import { OverlayScrollbars } from "overlayscrollbars";
import Notification from './components/Notification'
import {Provider} from 'react-redux'
import store from './redux/store';
import Startup from 'startup';


function MyApp() {
  const { quantidade, updateQuantidade } = useContext(GlobalContext);

  return (
    <Provider store={store}>
    <GlobalContext.Provider value={{ quantidade, updateQuantidade }}>
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "Poppins",
          colorPrimary: '#3AB97E'
        }
      }}
    >
      <GlobalStyle />
      <Notification />
        <App />
    </ConfigProvider>
    </GlobalContext.Provider>
    </Provider>
  );
}
// const root = ReactDOM.createRoot(document.getElementById('root'));

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);
const osInstance = OverlayScrollbars(document.body, { scrollbars: { autoHide: "scroll" } });

root.render(
    <MyApp />

);
