import React from 'react'
import * as S from "../../../styles/styled";

const FiliaisContainer = ({qtd, qtfFiliais, findCodHabilitacao}) => {
  return (
    <S.FiliaisContainer
          style={{
            justifyContent: qtfFiliais ? "center" : "space-evenly",
            gap: qtfFiliais ? "20px" : "",
          }}
        >
          
          {qtd?.map((item, index) => (
            findCodHabilitacao(item)?.length > 0 &&
            <S.FilialAnchor
              key={item.filial + "_" + index}
              href={`${window.location.origin}${window.location.pathname}#${item.filial}`}
            >
              {item.filial}
            </S.FilialAnchor>
          ))}
        </S.FiliaisContainer>
  )
}

export default FiliaisContainer