import React, { useEffect, useState } from 'react';
import { ArrowLeftOutlined, PieChartFilled } from '@ant-design/icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Menu } from 'antd';
import * as S from './styled'
import {setMainPage, setPage} from '../../../redux/ducks/commons'
import { useDispatch, useSelector } from 'react-redux';


const Navbar = () => {
  const { mainPage, changePage, isEmail, sge, page } = useSelector((state) => state.commons);
  const dispatch = useDispatch();

  
const style = {
  borderTopLeftRadius: sge ? '0px' :'40px',
  borderTopRightRadius: sge ? '0px' : '40px',
  height: '80px',
  position: 'relative',
  zIndex: 1,
  padding: '0px 1rem',
  backgroundColor: 'rgb(2, 89, 64)',
  maxWidth: sge ? '98% !important' : '90%',
  width:  sge ? '98% !important' : '90% !important',
  top: sge ? '0px' : '-50px',
  margin: '0 auto',
}

  const navigate = useNavigate();
  const location = useLocation();

  const [home, setHome] = useState(false);

  const mudarPagina = () => {
    if(mainPage === "Graficos" && page !== "Graficos"){
      dispatch(setPage(changePage));
    }else if(mainPage === "Erro"){
      navigate('/', { replace: true })
      location.pathname = '/';
    }
    else{
    setMainPage(changePage)
    }
  }

  useEffect(() => {
   if (mainPage === 'Inicial' || page === "Graficos") {
       setHome(true);
    } else {
      setHome(false);
    }
  }, [location, mainPage, page]);

  return (
    <>
    <Menu
      mode="horizontal"
      className="d-flex align-items-center w-100 navbar-menu"
      style={style}
    >
      {home || (sge && page === "Graficos") ?
        <S.MainHome>
          <S.MenuContent className='pie-title-menu'>
            <PieChartFilled className='pie-chart' style={{ fontSize: '2.5em', color: '#FFF' }} />
            <S.MainTitle>painel de rendimento</S.MainTitle>
          </S.MenuContent>
        </S.MainHome>
        : <>
          <S.MenuContent className='menu-arrow' style={{ justifyContent: 'start' }}>
            <S.ArrowContent>
              <Link onClick={() => mudarPagina()}>
                <ArrowLeftOutlined style={{ fontSize: '1.3em', color: '#025940' }} />
              </Link>
            </S.ArrowContent>
          </S.MenuContent>

          <S.MenuContent className='pie-title-menu'>

            <PieChartFilled className='pie-chart' style={{ fontSize: '2.5em', color: '#FFF' }} />
            <S.MainTitle>painel de rendimento</S.MainTitle>
          </S.MenuContent>
          <S.MenuContent className='hidden-menu'>
          </S.MenuContent>

        </>
      }
    </Menu>
    </>
  )
};

export default Navbar;
