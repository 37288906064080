
import styled from "styled-components";

export const Estilos = styled.div`
  margin-left: -25px;
  margin-right: -25px;
  width: 110%;
  background-color: #FFF;
  padding: 15px;
  border-radius: 12px;
  th, td {
    border: 0;
  }
  td {
    vertical-align: middle !important;
  }

  .botao-filtrar {
    font: 600 12px "Poppins";
    color: #727272;
    border-radius: 5px 0 0 5px;
  }
  .botao-exportar {
    font: 600 12px "Poppins";
    color: #727272;
    border-radius: 0 5px 5px 0;
  }

  .container-botoes-superior {
    position: absolute;
    right: 32px;
    top: 25px;
    display: flex;
    flex-direction: row;
  }
  .container-botoes-inferior {
    position: absolute;
    right: 32px;
    bottom: 52px;
    display: flex;
    flex-direction: row;
  }

  .ant-card-body {
    padding: 17px !important;
  }

  div.ant-card.ant-card-bordered {
    border: 1px solid #f0f0f0 !important;
  }

  .ant-empty-description {
    font: 14px "Poppins";
    color: #b1b1b1;
  }

  div.ant-drawer-header {
    border-bottom: none !important;
  }

  .ant-empty {
    margin: 94px auto;
  }

  .mdb-datatable-filter {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .mdb-datatable-filter::before {
    content: "Buscar na tabela: ";
    font: 14px "Poppins";
    color: #b1b1b1;
    position: absolute;
    top: 10px;
    right: 216px;
  }

  table > thead > tr > th {
    font: 500 13px "Poppins";
    padding-left: 15px;
    color: #727272;
    background: #efefef;
    border: none;
    border-right: medium solid white;
  }
  .table{
    width: 100% !important;
  }
  .table td {
    width:auto !important;
    border-top: none;
    padding-right:
  }

  .table td:first-child {
    text-align: center;
  }

  .table td:last-child {
    text-align: center;
  }

  .table td:nth-child(2) {
    padding-left: 1rem;
  }

  .table td:nth-child(3) {
    width: 150px !important;
    text-align: center;
    padding-left:0;
  }

  .table td:nth-child(4) {
    padding-left: 1rem;
  }

  .dataTables_wrapper.dt-bootstrap4 > div:nth-child(2) {
    margin-top: 15px;
  }

  .dataTables_wrapper.dt-bootstrap4 > div:last-child {
    background: #efefef;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    margin: auto 0px;
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background: transparent;
  }

  .table-striped tbody tr:nth-of-type(even) {
    background: rgba(240, 173, 78, 0.11);
  }

  table.table-sm td {
    font: 13px "Poppins";
    color: #727272;
    text-align: start;
  }

  /***************************************************
* ELEMENTOS DE PAGINAÇÃO
****************************************************/

  div.dataTables_paginate > ul.pagination > li.page-item {
    background: white;
  }

  div.dataTables_paginate > ul.pagination > li.page-item > a > span {
    font: 500 14px "Poppins";
    color: #727272;
  }

  .page-item.active > a {
    font: 500 14px "Poppins";
    color: #727272 !important;
    background-color: #ffefd8 !important;
    border: none;
  }

  div.dataTables_info[role="status"] {
    font: 500 14px "Poppins";
    text-align: right;
    color: #b1b1b1;
  }

  @media screen and (max-width: 600px) {
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;

    table > thead > tr > th {
      padding-left: 0px;
    }

    .table td:first-child {
      padding:0;
    }

    .table td:nth-child(2) {
      padding-left: .5rem;
    }
    
  .table td:nth-child(3) {
    text-align: center;
    padding-left: .6rem;
    padding-right: .6rem;
  }

  .table td:nth-child(4) {
    padding-left: .6rem;
    padding-right: 0;
  }


    .table td:last-child {
      text-align: left;
      padding-left: 0.6rem;
      padding-right: 0;
    }
  }
`;

export const ContainerTabela = styled.div`
  width:100%;

  td {
    vertical-align: middle !important;
  }

  .page-item.active > a {
    background-color: #3ab97e !important;
  }

  td > a {
    text-decoration: none;
    background-image: linear-gradient(#3ab97e, #3ab97e);
    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: 0% 4px;
    transition: background-size cubic-bezier(0, 0.5, 0, 1) 0.6s;
  }

  td > a:hover,
  td > a:focus {
    text-decoration: none;
    background-size: 100% 3px;
    color: #3ab97e !important;
  }

  .btn-acao {
    transition: all 0.2s ease-in-out;
  }

  .btn-acao:hover {
    cursor: pointer;
    transform: scale(1.3);
  }
  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
  }
`;

export const TituloTabela = styled.div`
  font: 600 17px Poppins;
  color: rgba(74,74,74,74%);
  position: absolute;

  @media screen and (max-width: 600px) {
    position: relative;
    text-align: center;
    margin-bottom: 15px;
  }
`