import { CheckOutlined } from "@ant-design/icons";
import { Button, DatePicker, Divider, Form, Input, Select, Space } from "antd";
import locale from "antd/es/date-picker/locale/pt_BR";
import React from "react";

const FormNovoAtendimento = ({
  modeloAtendimento,
  setModeloAtendimento,
  setVisibility,
  salvar,
  carregando,
}) => {
  const [form] = Form.useForm();
  const { TextArea } = Input;

  const setInput = (newValue) => {
    setModeloAtendimento((valores) => ({ ...valores, ...newValue }));
  };

  const etapas = [1, 2, 3, 4];

  return (
    <Form
      id="novo-contato"
      // style={{ marginTop: "30px" }}
      form={form}
      onFinish={() => salvar(modeloAtendimento)}
      labelWrap
    >
      <Form.Item
        label="Etapa"
        name="etapa"
        rules={[{ required: true, message: "Etapa é um campo obrigatório." }]}
      >
        <Select
          placeholder="Selecione"
          onChange={(e) => setInput({ Etapa: e })}
          // defaultValue={modeloAtendimento.Etapa}
        >
          {etapas.map((item) => (
            <Select.Option key={item} value={item}>
              {item}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="Data Contato"
        name="data-contato"
        rules={[
          {
            required: true,
            message: "Data do Contato é um campo obrigatório.",
          },
          () => ({
            validator(_, value) {
              if (value < new Date()) {
                return Promise.resolve();
              } else {
                return Promise.reject(
                  new Error(
                    `A data não pode ser maior que a data de hoje.`
                  )
                );
              }
            },
          }),
        ]}
      >
        <DatePicker
          defaultValue={modeloAtendimento?.DataOcorrencia}
          locale={locale}
          placeholder="Selecione uma data"
          style={{ width: "100%" }}
          format="DD/MM/YYYY"
          onChange={(e) => setInput({ DataOcorrencia: e })}
        />
      </Form.Item>

      <Form.Item
        label="Observação"
        // style={{ marginBottom: "40px" }}
        name="obs1"
        rules={[
          { required: true, message: "Observação é um campo obrigatório." },
        ]}
      >
        <TextArea
          showCount
          maxLength={100}
          onChange={(e) => setInput({ Observacao: e.target.value })}
        />
      </Form.Item>

      <Form.Item
        label="Obs. Restrita"
        // style={{ marginBottom: "60px" }}
      >
        <TextArea
          showCount
          maxLength={100}
          onChange={(e) => setInput({ ObservacaoRestrita: e.target.value })}
        />
      </Form.Item>

      <Divider />

      <Form.Item
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "right",
          width: "100%",
        }}
      >
        <Space
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "right",
          }}
        >
          <Button onClick={() => setVisibility(false)}>Cancelar</Button>
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            form="novo-contato"
            icon={<CheckOutlined />}
            style={{ display: "flex", alignItems: "center" }}
            loading={carregando}
          >
            Salvar
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default FormNovoAtendimento;
