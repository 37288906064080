import React from 'react'
import { ErrorPage, EmailPage } from '../components';
import { Routes, Route, BrowserRouter, Navigate  } from 'react-router-dom';
import { useState } from 'react';
import HomePage from './Home';
// import { ErrorUser } from '../components/Error';
import {Authorization} from '../components/Authorization'
import Login from 'pages/Login';


const MainRoutes = () => {

    return (
        <>
            <BrowserRouter basename='/'>
                <Routes>
                    <Route element={<HomePage />}path="/" exact />
                    <Route element={<Login/>} path='/login' exact/>
                    <Route element={<EmailPage />} path="/email/:usuario" exact />
                    <Route element={<EmailPage />} path="/email" exact />
                    <Route element={<HomePage />} path="/sge/:usuario" exact />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </BrowserRouter>
        </>
    )
}

export default MainRoutes;