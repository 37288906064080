import { Modal } from "antd";
import React from "react";
import { TituloTabela } from "../../TipoTurma/styles/styled";
import TableBeneficio from "./TableBeneficio";

const ModalBeneficios = ({
  Registro,
  visibleBeneficio,
  setVisibleBeneficio,
  beneficios
}) => {
  const Titulo = ({ texto }) => {
    return <TituloTabela style={{ marginTop: "25px" }}>{texto}</TituloTabela>;
  };

  const Capitalize = (str) => {
    if (str) {
      let arr = str?.toLowerCase().split(" ");

      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }

      const str2 = arr?.join(" ");

      return str2;
    }
  };

  return (
    <Modal
      open={visibleBeneficio}
      destroyOnClose={true}
      closable={true}
      maskClosable={true}
      keyboard={false}
      width={"auto"}
      onCancel={() => setVisibleBeneficio("", false)}
      footer={""}
      height={200}
    >
      <Titulo
        texto={`Benefícios de ${Capitalize(
          Registro?.nome
        )}`}
      />
      <TableBeneficio Registro={Registro} beneficios={beneficios}/>
    </Modal>
  );
};

export default ModalBeneficios;
