import styled from "styled-components";
import { MDBDataTable } from "mdbreact";

export const Tabela = styled(MDBDataTable)`
  td:first-child {
    width: auto;
    white-space: pre;
    padding-left: 15px;
    padding-right: 15px;
  }

  td:last-child {
    width: auto;
    white-space: pre;
    padding-left: 15px;
    padding-right: 15px;
  }

  td:nth-child(4){
    white-space: pre;
  }

  .table-striped tbody tr:nth-of-type(odd){
    background-color:rgba(240, 173, 78, 0.11);
  }

  table.table-hover tbody tr:nth-of-type(odd):hover{
    background-color:rgba(240, 173, 78, 0.18);
  }

  th, td {
    border-bottom: 0px;
  }

  .table-striped tbody tr:last-child {
    border-bottom: 1px solid #dee2e6;
  }

  @media screen and (max-width: 600px){
    table.table.table-hover.table-sm.table-striped.dataTable {
      margin-top: 0px !important;
    }
  }
`;
