import React, { useEffect, useState, useRef } from "react";
import * as S from "./styles";
import Avatar from "./icon/Avatar.svg";
import {
  getMediaByRA,
  getFotoByRA,
} from "../../../../../Services/Aluno/Service";
import { TailSpin } from "react-loading-icons";
import Classificacao from "./Classificacao";

const HeaderModal = ({ current, data, perfil }) => {
  const [dadosAluno, setDadosAluno] = useState();
  const [media, setMedia] = useState();
  const [imagem, setImagem] = useState();
  const scrollableDivRef = useRef(null);
  const contentRef = useRef(null);
  const [isScrolling, setIsScrolling] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [isCursorCustom, setIsCursorCustom] = useState(false);

  useEffect(() => {
    setDadosAluno(data?.find((x) => x.ra === current?.ra));
  }, [current?.ra, data]);

  useEffect(() => {
    if (dadosAluno?.ra) {
      getMediaByRA(dadosAluno?.ra).then((resultado) => {
        setMedia(resultado);
      });
      getFotoByRA(dadosAluno?.ra).then((resultado) => {
        setTimeout(setImagem(resultado[0]), 3000);
      });
    }
  }, [dadosAluno]);

  const handleStartScroll = (event) => {
    setIsScrolling(true);
    setStartX(event.clientX);
    setScrollLeft(scrollableDivRef.current.scrollLeft);
  };

  const handleStopScroll = () => {
    setIsScrolling(false);
  };

  const handleMouseMove = (event) => {
    if (!isScrolling) return;
    event.preventDefault();
    const x = event.clientX;
    const walk = (x - startX) * 2;
    scrollableDivRef.current.scrollLeft = scrollLeft - walk;
  };

  const checkCursorCustom = (scrollableDivRef) => {
    if(scrollableDivRef.current){
    const hasScroll = scrollableDivRef.current.scrollWidth > scrollableDivRef.current.clientWidth;
    // const hasOverflow = scrollableDivRef.current.style.overflowX === 'auto' || scrollableDivRef.current.style.overflow === 'scroll';
    setIsCursorCustom(hasScroll);
    }
  };

  useEffect(() => {
    checkCursorCustom(scrollableDivRef);
    window.addEventListener('resize', checkCursorCustom);
    return () => {
      window.removeEventListener('resize', checkCursorCustom);
    };
  }, [scrollableDivRef]);

  const renderSerie = (serie) => {
    switch (serie) {
      case "1o ANO EF1":
        return "1º ANO EF";
      case "2o ANO EF1":
        return "2º ANO EF";
      case "3o ANO EF1":
        return "3º ANO EF";
      case "4o ANO EF1":
        return "4º ANO EF";
      case "5o ANO EF1":
        return "5º ANO EF";
      case "6o ANO EF2":
        return "6º ANO EF";
      case "7o ANO EF2":
        return "7º ANO EF";
      case "8o ANO EF2":
        return "8º ANO EF";
      case "9o ANO EF2":
        return "9º ANO EF";
      case "1o ANO E.M":
        return "1º ANO EM";
      case "2o ANO E.M":
        return "2º ANO EM";
      case "3o ANO E.M":
        return "3º ANO EM";
      default:
        return serie.toString();
    }
  };

  const backgroundColorCase = (media) => {
    if (media > 59 && media < 70) {
      return "#FFA1A1";
    }
    if (media < 60) {
      return "#FFA1A1";
    }
    if (media >= 60) {
      return "#99D3B3";
    }
  };

  return (
    dadosAluno &&
    Avatar && (
      <>
      <S.HeaderContainer>

        {perfil && <S.HeaderAvatarContainer>
          {imagem && perfil ? (
            <S.FotoAlunoContainer
              backgroundImage={`data:image/gif;base64,${imagem.src}`}
            />
          ) : (
            <S.AvatarImg src={Avatar} alt="" />
          )}

        </S.HeaderAvatarContainer>}


        <S.HeaderInformationContainer perfil={perfil}>
          <S.Nome
            className={`scrollable-div ${isCursorCustom ? 'custom-cursor' : ''}`}
            ref={scrollableDivRef}
            onMouseDown={handleStartScroll}
            onMouseUp={handleStopScroll}
            onMouseLeave={handleStopScroll}
            onMouseMove={handleMouseMove}
            style={{minWidth:`${perfil ? "": "100% !important"}`}}
            perfil={perfil}
          >
            <span className="scrollable-content" ref={contentRef}>{dadosAluno?.nome}</span>
          </S.Nome>
          <S.Oferta>{`${renderSerie(dadosAluno.serie)} - ${
            dadosAluno.tipoTurma
          } - TURMA ${dadosAluno.turma}`}</S.Oferta>
          <S.DetalhesAcademicos>
            <p>{dadosAluno.filial}</p>

            {perfil && <S.DetalhesMatricula>
              <p>RA: {dadosAluno.ra}</p>
              <p>Matricula: {dadosAluno.matricula}</p>
            </S.DetalhesMatricula>}

           {perfil && <S.MediaContainer>
              <S.MediaSquare
                backgroundColor={
                  media ? backgroundColorCase(media[0]?.media) : "#FFF7B2"
                }
              >
                {media ? (
                  <>
                    <S.MediaTitle>Média geral do aluno</S.MediaTitle>
                    <S.MediaNotaContainer>
                      <S.MediaNotaP>
                        {(Math.floor(media[0]?.media) / 10).toFixed(1)}
                      </S.MediaNotaP>
                    </S.MediaNotaContainer>
                  </>
                ) : (
                  <S.ContainerLoader>
                    <TailSpin fill="#025940" stroke="rgb(117, 117, 117)" />
                  </S.ContainerLoader>
                )}
              </S.MediaSquare>
            </S.MediaContainer>}

          </S.DetalhesAcademicos>
        </S.HeaderInformationContainer>
      </S.HeaderContainer>
     <Classificacao RA={dadosAluno?.ra} perfil={perfil} />
     </>
    )
  );
};

export default HeaderModal;
