import styled from 'styled-components';

export const Container = styled.section`
    display: flex;
    flex-wrap: wrap;
    gap: 35px;
    justify-content: start;

    @media screen and (max-width: 600px) {
        gap: 15px;
        flex-direction: column;
        justify-content: center !important;
        align-items: center;
    }
`

export const Button = styled.button`
    height: 80px;
    width: 250px;
        
    cursor: pointer;

    font-weight: 500;
    font-size: 1em;
    letter-spacing: .03em;
    text-transform: uppercase;

    color: #FFF;
    background-color: #025940;

    border: 0px;
    border-radius: 15px;
    &:hover{
        background-color: #138356;
    }
`

export const ButtonInsideContainer = styled.div`

`

export const Bar = styled.div``