import axios from 'axios'
import {
    STORAGE_TOKEN,
    STORAGE_TIPO_CURSO,
    COLIGADA,
    SISTEMA,
} from '../Const/webConst'

export function getHeader() {
    const token = sessionStorage.getItem(STORAGE_TOKEN)
    const tipoCurso = localStorage.getItem(STORAGE_TIPO_CURSO)

    return {
        Authorization: `Bearer ${token}`,
        CodigoTipoCurso: tipoCurso,
        CodigoColigada: COLIGADA,
        CodigoSistema: SISTEMA,
    }
}

const Api = (base) => {
    const client = axios.create({
        baseURL: base,
    })

    const get = (endpoint, params) =>
        client.get(endpoint, { params, headers: getHeader() })

    const post = (endpoint, data, params) =>
        client.post(endpoint, data, { params, headers: getHeader() })

    const del = (endpoint) => client.delete(endpoint, { headers: getHeader() })

    const update = (endpoint, data, params) =>
        client.patch(endpoint, data, { params, headers: getHeader() })

    return {
        get,
        post,
        del,
        update,
    }
}

export default Api
