import { Modal, Divider } from "antd";
import React from "react";
import FormNovoAtendimento from "./FormNovoAtendimento";

const NovoAtendimento = ({
  visibility,
  setVisibility,
  Registro,
  modeloAtendimento,
  setModeloAtendimento,
  salvar,
  carregando,
  
}) => {
  return (
    <Modal
      open={visibility}
      destroyOnClose={true}
      closable={true}
      maskClosable={true}
      keyboard={false}
      // width={"auto"}
      onCancel={() => setVisibility(false)}
      style={{ marginTop: "-15px" }}
      footer={""}
      // height={200}
    >
      <p style={{ marginTop: "15px" }}>
        Registrando novo contato para:
        <br />
        <strong>{Registro?.nome}</strong>
      </p>

      <Divider />

      <FormNovoAtendimento
        modeloAtendimento={modeloAtendimento}
        setModeloAtendimento={setModeloAtendimento}
        setVisibility={setVisibility}
        salvar={salvar}
        carregando={carregando}
      />
      
    </Modal>
  );
};

export default NovoAtendimento;
