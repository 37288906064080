
/* DECLARAÇÃO DO AMBIENTE */
export const basePath = process.env.REACT_APP_API_RENDIMENTO_PROD;
export const GLOBAIS_BASE_URL = process.env.REACT_APP_GLOBAIS_BASE_URL_PROD;

export const STORAGE_TOKEN = 'fbeducacional_token';
export const STORAGE_TIPO_CURSO = 'fbeducacional_tipo_curso';
export const STORAGE_USER = 'fbeducacional_user';
export const COLIGADA = 3;
export const SISTEMA = 90;


export const REQUISICAO_FALHOU =
  'Desculpe, algo deu errado. Entre em contato com a Central de TI no 7862 ou mande um e-mail para ti.atendimento@fariasbrito.com.br relatando o problema.';
export const REQUISICAO_SUCESSO = 'Sua solicitação foi processada com sucesso!';
export const REQUISICAO_NAO_AUTORIZADO = (
  <span>
    Desculpe, você não está autorizado a acessar esta página.
    <br />
    Se você precisa deste acesso entre em contato com a&nbsp;
    <strong style={{ fontWeight: 'bold' }}>Central de TI</strong> no&nbsp;
    <strong style={{ fontWeight: 'bold' }}>7862</strong> ou mande um&nbsp;
    <strong style={{ fontWeight: 'bold' }}>e-mail</strong> para&nbsp;
    <a
      href={`mailto:ti.atendimento@fariasbrito.com.br?subject=Solicitação de acesso a função [CÓDIGO] do FB Educacional&body=Solicito acesso à função [CÓDIGO] do FB Educacional.%0D%0AVeja abaixo meu dados para conferência:%0D%0ANome completo: [Substitua pelo seu nome completo]%0D%0ACodinome de usuário: [Substitua pelo seu login do Sistema FB]%0D%0ARamal: [Substitua pelo seu ramal para contato]%0D%0ASuperior imediato: [Substitua pelo nome do seu superior imediato]%0D%0AFilial: [Substitua pelo nome da sua filial]%0D%0AMotivo do acesso: [Substitua por uma breve descrição do motivo do acesso]`}
    >
      ti.atendimento@fariasbrito.com.br 
    </a>{' '}
    solicitando.
  </span>
);


/* HEADER PADRÃO  */
export const getHeader = () => {
  return {
    "Content-Type": "text/plain",
    "Access-Control-Allow-Origin": "*",
  };
};

export const getHeaderBody = () => {
  return {
    'Content-Type': 'application/json',
    "Access-Control-Allow-Origin": "*",
  };
};
