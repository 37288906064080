import axios from 'axios'
import {basePath, getHeader} from '../../Const/webConst'

export const getAtendimentos = async (RA) => {
  const resposta = await axios.get(
    `${basePath}/Atendimento/Atendimentos?ra=${RA}`,
    {
      headers: getHeader()
    }
  ); if (!resposta.data)
    throw new Error(resposta.data.exception.errorMessage);
  return resposta.data;
}
