import React, { useState } from 'react'
import MainPages from './MainPages'
import PrimaryLayout from '../layout/PrimaryLayout'
import { useSelector } from 'react-redux';

const HomePage = () => {

    return (
        <PrimaryLayout>
            <MainPages/> 
        </PrimaryLayout>
       
    )

    // return "teste"
}

export default HomePage