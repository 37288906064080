import React from "react";
import * as S from "./styles";
import { ReactComponent as RankIcon } from "./icon/rank.svg";
import { ReactComponent as StarIcon } from "./icon/star.svg";
import { ReactComponent as TrophyIcon } from "./icon/Trophy.svg";
import { ReactComponent as TicketIcon } from "./icon/ticket.svg";
import { Tooltip } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import {
  getPontosOcorrencia,
  getClassificacao,
} from "../../../../../Services/Aluno/Service";

const Classificacao = ({ RA, perfil }) => {
  const [classifColegio, setClassifColegio] = useState();
  const [classifSerie, setClassifSerie] = useState();
  const [classifTurma, setClassifTurma] = useState();
  const [ocorrencias, setOcorrencias] = useState();

  useEffect(() => {
    getPontosOcorrencia(RA).then((resultado) => {
      formatNumber(resultado);
    });

    getClassificacao(RA).then((resultado) => formatClassificacao(resultado));
  }, [RA]);

  function formatNumber(value) {
    if (value) {
      if (Number.isInteger(value.pontos)) {
        setOcorrencias(value.pontos.toFixed(1));
      } else {
        setOcorrencias(value.pontos.toString());
      }
    }
  }

  function formatClassificacao(value) {
    setClassifColegio(value.classificacaoGeral);
    setClassifSerie(value.classificacaoSerie);
    setClassifTurma(value.classificacaoTurma);
  }

  function bgColor(value) {
    if (value >= 6 && value <= "7.5") return "#FEC901";

    if (value < 6) return "";

    if (value > "7.5") return "#DC3545";
  }

  return (
    <S.ClassificacaoContainer
      style={{
        justifyContent:
          classifColegio && classifTurma && ocorrencias ? "" : "left",
        gap: classifColegio && classifTurma && ocorrencias ? "" : "15px",
      }}
    >
      {classifColegio && (
        <Tooltip title="Classificação do aluno na série instituição">
          <S.ClassificacaoInner>
            <S.InnerIcon>
              <RankIcon width={17} height={17} />
            </S.InnerIcon>
            <S.InnerNumber>{classifColegio}</S.InnerNumber>
          </S.ClassificacaoInner>
        </Tooltip>
      )}

      {classifSerie && (
        <Tooltip title="Classificação do aluno na série na unidade">
          <S.ClassificacaoInner>
            <S.InnerIcon>
              <TrophyIcon width={25} height={25} />
            </S.InnerIcon>
            <S.InnerNumber>{classifSerie}</S.InnerNumber>
          </S.ClassificacaoInner>
        </Tooltip>
      )}

      {classifTurma && (
        <Tooltip title="Classificação do aluno na turma">
          <S.ClassificacaoInner>
            <S.InnerIcon>
              <StarIcon width={17} height={17} />
            </S.InnerIcon>
            <S.InnerNumber>{classifTurma}</S.InnerNumber>
          </S.ClassificacaoInner>
        </Tooltip>
      )}

      {ocorrencias && perfil && (
        <Tooltip title="Pontos de ocorrência">
          <S.ClassificacaoInner
            style={{
              backgroundColor: bgColor(ocorrencias),
              color: ocorrencias > "7.5" ? "#FFF" : "",
            }}
          >
            <S.InnerIcon>
              <TicketIcon
                width={17}
                height={17}
                style={{ fill: ocorrencias > "7.5" ? "#FFF" : "" }}
              />
            </S.InnerIcon>
            <S.InnerNumber>{ocorrencias}</S.InnerNumber>
          </S.ClassificacaoInner>
        </Tooltip>
      )}
    </S.ClassificacaoContainer>
  );
};

export default Classificacao;
