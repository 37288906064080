import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import NovidadeEmailGif from "../../assets/gif/novidadeEmail1.gif";

function ModalNovidadesEmail() {
  const [showModal, setShowModal] = useState(false);
  

  useEffect(() => {
    const hasVisitedBeforeEmail = localStorage.getItem("hasVisitedBeforeEmail");
    localStorage.removeItem("hasVisitedBeforeEmail");
    if (!hasVisitedBeforeEmail) {
      // setShowModal(true);
      
    }
  }, []);

  const closeModal = () => {
    setShowModal(false);
    localStorage.setItem("hasVisitedBeforeEmail", "true");
  };

  return (
    <>
      <Modal
        open={showModal}
        // destroyOnClose={true}
        closable={false}
        maskClosable={false}
        keyboard={false}
        width={'70%'}
        onCancel={() => closeModal()}
        footer={""}
        style={{ marginTop: "-70px" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ width: "100%" }}>
            <h3>Novidade!</h3>
            <p>Visualize todas as turmas e alunos que você tem acesso apenas clicando no botão verde ao lado de qualquer card na tela.</p>
          </div>
          <img src={NovidadeEmailGif} width="100%" alt="Animação com as ultimas novidades"/>
          <div style={{marginTop:'15px'}}>
            {NovidadeEmailGif && <Button size="large" type="primary" onClick={closeModal}>Entendi!</Button>}
          </div>
        </div>
      </Modal>
      {/* Restante do conteúdo da sua tela */}
    </>
  );
}

export default ModalNovidadesEmail;
