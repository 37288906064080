import React, { memo, useRef, useEffect } from "react";
import { MDBDataTable as Table } from "mdbreact";
import {
  getMediaDisciplina,
  getMediaDisciplinaFilial,
} from "../../../../../Services/Notas/Service";
import { useCallback } from "react";

const Tabela = ({
  dadosDisciplina,
  handleClick,
  status,
  setCurrent,
  mediaDisc,
  setMediaDisc,
  mediaDiscFilial,
  setMediaDiscFilial,
  perfil,
}) => {
  const refDataTable = useRef(null);

  const colunas = React.useMemo(() => {
    return [
      {
        label: `${perfil ? "RA" : ""}`,
        field: `${perfil ? "ra" : ""}`,
      },
      {
        label: "Aluno",
        field: "nome",
        sort: "asc",
      },
      {
        label: "Tipo",
        field: "tipoTurma",
      },
      {
        label: "Turno",
        field: "turno",
      },
      {
        label: "Turma",
        field: "turma",
        width: "30px",
      },
      {
        label: "Nota",
        field: "nota",
      },
    ];
  }, []);

  const organizarRegistro = useCallback(
    (data) => {
      var result = data
        .filter((x) => x.codFilial === status.codFilial)[0]
        .bimestres.filter((y) => y.bimestre === status.bimestre)[0]
        .avaliacoes.filter((p) => p.tipoAvaliacao === status.tipoProva)[0]
        .niveisEnsino.filter((i) => i.nivelEnsino === status.nivelEnsino)[0]
        .series.filter((l) => l.codHabilitacao === status.codHabilitacao)[0]
        .disciplinas.filter((q) => q.disciplina === status.title)[0];

      return result.alunos;
    },
    [status]
  );

  const dadosDaTabela = React.useMemo(() => {
    return {
      columns: colunas,
      rows: organizarRegistro(dadosDisciplina).map((registro, index) => ({
        ...registro,
        nota: (Math.floor(registro.nota) / 10).toFixed(1),
        turno: registro.turno === "M" ? "MANHÃ" : "TARDE",
        clickEvent: () => {
          handleClick({ ...registro }, true);
        },
        key: index.toString(),
      })),
    };
  }, [colunas, dadosDisciplina, handleClick, organizarRegistro]);

  useEffect(() => {
      if (status?.disciplina && status?.tipoTurma && status?.codHabilitacao) {
        getMediaDisciplina(
          status?.disciplina,
          status?.tipoTurma,
          status?.codHabilitacao
        ).then((resultado) => {
          setMediaDisc(resultado[0]);
        });
      }

      if (
        status?.disciplina &&
        status?.codFilial &&
        status?.tipoTurma &&
        status?.codHabilitacao
      ) {
        getMediaDisciplinaFilial(
          status?.disciplina,
          status?.codFilial,
          status?.tipoTurma,
          status?.codHabilitacao
        ).then((resultado) => {
          setMediaDiscFilial(resultado[0]);
        });
      }
    //}
  }, [status, setMediaDisc, setMediaDiscFilial]);

  return (
    <Table
      striped={dadosDaTabela.rows.length > 0 ? true : false}
      hover={dadosDaTabela.rows.length > 0 ? true : false}
      autoWidth={true}
      small
      noBottomColumns
      displayEntries={false}
      entries={20}
      infoLabel={["Mostrando", "a", "de", "registros"]}
      paginationLabel={["<", ">"]}
      paging={true}
      pagesAmount={5}
      searchLabel=" "
      responsive
      disableRetreatAfterSorting={true}
      data={dadosDaTabela}
      ref={refDataTable}
    />
  );
};

export default memo(Tabela);
