import axios from 'axios'
import {basePath, getHeader} from '../../Const/webConst'

const api = axios.create({
  baseURL: basePath,
});

api.interceptors.response.use(
  response => {
    const data = response;
    return data;
  },
  error => {
    console.error('Erro ao fazer requisição à API:', error);
    return Promise.reject(error);
  }
);



export const getNotas = async (user) => {

    const resposta = await api.get(
      `${basePath}/Desempenho/email/${user}`,
      {
        headers: getHeader()
      }
    );
    if (!resposta.data)
      throw new Error(resposta.data.exception.errorMessage);
    return resposta.data;
  };


export default getNotas;
