import React from 'react'
import * as S from './styled'
import { useSelector } from 'react-redux'

const Body = ({children}) => {
   const {sge} = useSelector((state) => state.commons);
   
   return (
<S.Main>
    <S.BodySection1>
    </S.BodySection1>

    <S.BodySection2 sge={sge} className='body-middle'>
    {children}
    </S.BodySection2>

    <S.BodySection3>
    </S.BodySection3>
</S.Main> 
   )
}

export default Body;