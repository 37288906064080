import styled from 'styled-components'

export const Main = styled.div`
    width:100%;
    height:50px;

    display: flex;

    @media screen and (max-width: 600px) {
        display: none;
    }
`

export const FooterSection1 = styled.div`
    height: 50px;
    width: 5%;

    @media screen and (max-width: 600px) {
        display: none;
    }
`

export const FooterSection2 = styled.div`
    width: 90%;
    background-color:#F6F6F6;
    min-height: 50px;

    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;

    @media screen and (max-width: 600px) {
        width: 100%;

        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;

        min-height: 40vh;
    }

`

export const FooterSection3 = styled.div`
    height: 100px;
    width: 5%;

    @media screen and (max-width: 600px) {
        display: none;
    }
`