import axios from 'axios'
import {basePath, getHeader} from '../../Const/webConst'

const api = axios.create({
  baseURL: basePath,
});

api.interceptors.response.use(
  response => {
    const data = response;
    return data;
  },
  error => {
    console.error('Erro ao fazer requisição à API:', error);
    return Promise.reject(error);
  }
);



export const getAllNotas = async (user, disciplina = "") => {
  const resposta = await api.get(
    `${basePath}/Notas?user=${user}&disciplina=${disciplina}`,
    {
      headers: getHeader()
    }
  );
  if (!resposta.data)
    throw new Error(resposta.data.exception.errorMessage);
  return resposta.data;
};


export const getAllQuantidades = async (user) => {
  const resposta = await api.get(
    `${basePath}/Notas/TotalAlunos?codUsuario=${user}`,
    {
      headers: getHeader()
    }
  ); if (!resposta.data)
    throw new Error(resposta.data.exception.errorMessage);
  return resposta.data;
};


export const getNotasFormatless = async (user, disciplina = "") => {
  
  const resposta = await api.get(
    `${basePath}/Notas/formatless?user=${user}&disciplina=${disciplina}`,
    {
      headers: getHeader()
    }
  ); if (!resposta.data)
    throw new Error(resposta.data.exception.errorMessage);
  return resposta.data;
}

export const getMediaDisciplina = async (disc, tipoTurma, codHabilitacao) => {
  
  const resposta = await api.get(
    `${basePath}/Notas/MediaDisciplina?disciplina=${disc}&tipoTurma=${tipoTurma}&codHabilitacao=${codHabilitacao}`,
    {
      headers: getHeader()
    }
  ); if (!resposta.data)
    throw new Error(resposta.data.exception.errorMessage);
  return resposta.data;
}


export const getMediaDisciplinaFilial = async (disc, codFilial, tipoTurma, codHabilitacao) => {
  
  const resposta = await api.get(
    `${basePath}/Notas/MediaDisciplinaFilial?disciplina=${disc}&codFilial=${codFilial}&tipoTurma=${tipoTurma}&codHabilitacao=${codHabilitacao}`,
    {
      headers: getHeader()
    }
  ); if (!resposta.data)
    throw new Error(resposta.data.exception.errorMessage);
  return resposta.data;
}


export const getMediaTurmaFilial = async (turma, codFilial, tipoTurma, codHabilitacao) => {
  
  const resposta = await api.get(
    `${basePath}/Notas/MediaTurmaFilial?turma=${turma}&codFilial=${codFilial}&tipoTurma=${tipoTurma}&codHabilitacao=${codHabilitacao}`,
    {
      headers: getHeader()
    }
  ); if (!resposta.data)
    throw new Error(resposta.data.exception.errorMessage);
  return resposta.data;
}


export const getMediaSerieFilial = async (codFilial, tipoTurma, codHabilitacao) => {
  
  const resposta = await api.get(
    `${basePath}/Notas/MediaSerieFilial?codFilial=${codFilial}&tipoTurma=${tipoTurma}&codHabilitacao=${codHabilitacao}`,
    {
      headers: getHeader()
    }
  ); if (!resposta.data)
    throw new Error(resposta.data.exception.errorMessage);
  return resposta.data;
}


export const getMediaSerieOrg = async (tipoTurma, codHabilitacao) => {
  
  const resposta = await api.get(
    `${basePath}/Notas/MediaSerieOrg?tipoTurma=${tipoTurma}&codHabilitacao=${codHabilitacao}`,
    {
      headers: getHeader()
    }
  ); if (!resposta.data)
    throw new Error(resposta.data.exception.errorMessage);
  return resposta.data;
}

export const getDataProcessamento = async () => {
  
  const resposta = await api.get(
    `${basePath}/Notas/DataProcessamento`,
    {
      headers: getHeader()
    }
  ); if (!resposta.data)
    throw new Error(resposta.data.exception.errorMessage);
  return resposta.data;
}


export const getAtendimentos = async (RA) => {
  
  const resposta = await api.get(
    `${basePath}/Notas/Atendimentos?ra=${RA}`,
    {
      headers: getHeader()
    }
  ); if (!resposta.data)
    throw new Error(resposta.data.exception.errorMessage);
  return resposta.data;
}

export default getAllNotas;
