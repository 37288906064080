import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { message } from 'antd'
import {RequestActions}   from '../../redux/ducks/request'; 

const Notification = () => {
    const dispatch = useDispatch()
    const request = useSelector((state) => state.request);

    useEffect(() => {
        const showMessage = (type) => {
            dispatch(RequestActions.resetRequest());
            message.destroy();
            message[type]({
                content: request[`${type}Message`],
                key: 'updatable',
                duration: 2,
                maxCount: 1,
            })
        }

        if (request.success) {
            showMessage('success')
        }

        if (request.error) {
            showMessage('error')
        }

    }, [request, dispatch])

    return null
}

export default Notification
