import React, { useEffect, useState } from "react";
import { getNotas } from "Services/Email/Services";
import { useParams } from "react-router-dom";
import { Body, Header } from "./index";
import { LoadingOutlined, UpCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { setIsEmail } from "../../redux/ducks/commons";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo } from "Services/User/Services";
import ModalNovidadesEmail from "../ModalNovidades/ModalNovidadesEmail";
import Login from "pages/Login";

const MainEmail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { mainPage, changePage, isEmail, sge, page } = useSelector(
    (state) => state.commons
  );
  const user = useSelector((state) => state.usuario.usuario);

  const autenticado = useSelector((state) => state.usuario.autenticado);

  const [currentUser, setCurrentUser] = useState();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [porcentagem, setPorcentagem] = useState(0);

  const { usuario } = useParams();

  useEffect(() => {
    if(autenticado){
    setTimeout(() => {
      if (porcentagem < 100) {
        setPorcentagem(porcentagem + 1);
      }
    }, 150);}
  }, [porcentagem, autenticado]);


  useEffect(() => {
    const getInfo = async (user) => {
      if (user.nameid) {
        setLoading(true);
        await getNotas(user.nameid).then((result) => {
          if (result?.return?.length > 0) {
            setData(
              result.return.sort((a, b) =>
                a.codHabilitacao > b.codHabilitacao ? 1 : -1
              )
            );
            setLoading(false);
          }
        });
      }
    };

    if (autenticado && user && user.nameid) {
      getInfo(user);
    }
  }, [user, autenticado]);

  useEffect(() => {
    dispatch(setIsEmail(true));

    getUserInfo(usuario.slice(5, -5)).then((result) => {
      setCurrentUser(result.return);
    });
  }, [usuario]);

  const CompEmail = () => {
    return loading === true && !data ? (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <div
          style={{
            position: "absolute",
            fontSize: "3.5em",
            color: "#009240",
            fontWeight: "600",
          }}
        >
          {porcentagem}%
        </div>
        {/* <div>
          <LoadingOutlined style={{ fontSize: "160px", color: "#009240" }} />
        </div> */}
      </div>
    ) : (
      <>
        <div className="ArrowUpEmailContainer">
          <a href={`${window.location.origin}${window.location.pathname}#`}>
            <UpCircleOutlined />
          </a>
        </div>
        <Header />
        {data && (
          <div className="lista-filiais">
            {data
              .sort((a, b) => a.filial.localeCompare(b.filial))
              .map((itens, index) => (
                <a
                  key={`${itens.filial}_${index}`}
                  href={`${window.location.origin}${window.location.pathname}#${itens.filial}`}
                >
                  {itens.filial}
                </a>
              ))}
          </div>
        )}
        {data && <Body data={data} />}
        <ModalNovidadesEmail />
        <div style={{ height: "20px" }}></div>
      </>
    );
  };

  return !autenticado ? (
    <Login currentUser={currentUser} setCurrentUser={setCurrentUser} />
  ) : (
    <CompEmail />
  );
};

export default MainEmail;
