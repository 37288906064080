import React, { useCallback, useEffect, useState } from "react";
import { Table } from "antd";
import * as S from "./styles";

const { Column, ColumnGroup } = Table;

const TableDetalhes = ({ dados, perfil }) => {
  const [data, setData] = useState();

  const formatNota = (nota) => {
    var inteiro = Math.floor(nota);
    return (inteiro / 10).toFixed(1);
  };

  const validarDados = useCallback(() => {
    const resultArray = [];

    dados.forEach((obj) => {
      const disciplina = obj.disciplina;
      const bimestre = obj.bimestre;
      const tipoAvaliacao = obj.tipoAvaliacao;
      const nota = obj.nota;
      const mediaSemNTD = obj.mediaSemNTD;

      let disciplinaObj = resultArray.find(
        (item) => item.disciplina === disciplina
      );
      if (!disciplinaObj) {
        disciplinaObj = {
          disciplina: disciplina,
        };
        resultArray.push(disciplinaObj);
      }

      const key = `${tipoAvaliacao}${bimestre}`;
      disciplinaObj[key] = formatNota(nota);

      if (tipoAvaliacao === "VP" && mediaSemNTD > 0 && mediaSemNTD < 70) {
        const mdKey = `MD${bimestre}`;
        disciplinaObj[mdKey] = formatNota(mediaSemNTD);
      }
    });

    setData(resultArray);
  }, [dados]);

  useEffect(() => {
    validarDados();
  }, [validarDados]);

  return (
    data && (
      <div>
        <S.SpanTitle>{perfil ? "Disciplinas com baixo desempenho" : "Histórico do aluno na disciplina"}</S.SpanTitle>
        <div
          style={{
            backgroundColor: "#FBFBFB",
            paddingTop: "40px",
            borderRadius: "10px",
          }}
        >
          <Table dataSource={data} style={{ width: "100%" }}>
            <Column
              title="Disciplina"
              dataIndex="disciplina"
              key="disciplina"
              width={300} 
            />

            <ColumnGroup title="1BIM" width={60} key="coluna_1BIM">
              <Column title="VP" dataIndex="VP1" key="VP1" width={30} />
              <Column title="VG" dataIndex="VG1" key="VG1" width={30} />
              <Column title="MD" dataIndex="MD1" key="MD1" width={30} />
            </ColumnGroup>

            <ColumnGroup title="2BIM" className="teste1"  key="coluna_2BIM">
              <Column title="VP" dataIndex="VP2" key="VP2" />
              <Column title="VG" dataIndex="VG2" key="VG2" />
              <Column title="MD" dataIndex="MD2" key="MD2" />
            </ColumnGroup>

            <ColumnGroup title="3BIM"  key="coluna_3BIM">
              <Column title="VP" dataIndex="VP3" key="VP3" />
              <Column title="VG" dataIndex="VG3" key="VG3" />
              <Column title="MD" dataIndex="MD3" key="MD3" />
            </ColumnGroup>

            <ColumnGroup title="4BIM"  key="coluna_4BIM">
              <Column title="VP" dataIndex="VP4" key="VP4" />
              <Column title="VG" dataIndex="VG4" key="VG4" />
              <Column title="MD" dataIndex="MD4" key="MD4" />
            </ColumnGroup>
          </Table>
        </div>
      </div>
    )
  );
};

export default TableDetalhes;
