import styled from "styled-components";

export const Container = styled.div``;

export const HeaderContainer = styled.div`
  display: flex;

  @media screen and (max-width: 600px) {
    margin-top: 15px;
  }
`;

export const HeaderAvatarContainer = styled.div`
  margin-top: 15px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FotoAlunoContainer = styled.div`
  width: 190px;
  height: 190px;

  border-radius: 50px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  background-image: url(${(props) => !props.backgroundImage ? "" : props.backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media screen and (max-width: 600px) {
    width: 100px;
    height: 100px;

    border-radius: 25px;
  }
`;

export const AvatarImg = styled.img`
  @media screen and (max-width: 600px) {
    width: 100px;
  }
`;

export const HeaderInformationContainer = styled.div`
  padding-left: ${(props) => props.perfil ? "25px" : ""};
  padding-top: 10px;

  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: end;
`;

export const Nome = styled.h3`
  overflow: auto;
  white-space: pre;
  max-width: ${(prop) => prop.perfil ? "397px" : ""};
  overflow-y: hidden;

  user-select: none;

  margin: 0;

  color: #009240;
  font-size: 1.5rem;
  font-weight: 500;
  text-transform: uppercase;

  /* scrollbar-width: thin;
    scrollbar-gutter: stable;
    scrollbar-color: #99D3B3;
    scrollbar-width: thin;
    scrollbar-gutter: stable;
    scrollbar-color: #99D3B3 #d2e8dc;

    &::-webkit-scrollbar {
        display: block;
        width: 10px;
        height: 5px;
    }

    &::-webkit-scrollbar-track {
        background-color: #d2e8dc;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #99D3B3;
        width: 50%;
      } */

  --sb-track-color: #e8e8e8;
  --sb-thumb-color: #6baf8d;
  --sb-size: 5px;

  ::-webkit-scrollbar {
    width: var(--sb-size);
    height: 7px;
  }

  ::-webkit-scrollbar-track {
    background: var(--sb-track-color);
    border-radius: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--sb-thumb-color);
    border-radius: 6px;
  }

  @supports not selector(::-webkit-scrollbar) {
    scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
    scrollbar-width: thin;
    padding-bottom: 9px;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 399px) {
    font-size: 1em;
    max-width: 190px;
  }

  @media screen and (min-width: 400px) and (max-width: 500px) {
    font-size: 1em;
    max-width: 250px;
  }

  @media screen and (min-width: 501px) and (max-width: 550px) {
    font-size: 1em;
    max-width: 290px;
  }

  @media screen and (min-width: 551px) and (max-width: 600px) {
    font-size: 1em;
    max-width: 335px;
  }

  @media screen and (min-width: 601px) and (max-width: 650px) {
    font-size: 1.5em;
    max-width: 325px;
  }

  @media screen and (min-width: 651px) and (max-width: 700px) {
    max-width: 350px;
  }
`;

export const Oferta = styled.h5`
  margin: 0;

  color: #757575;
  font-weight: 500;
  text-transform: uppercase;

  @media screen and (max-width: 600px) {
    font-size: 0.8em;
  }
`;

export const DetalhesAcademicos = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  p {
    margin: 0;
    padding: 0;
  }

  @media screen and (max-width: 600px) {
    font-size: 0.8em;
  }
`;

export const DetalhesMatricula = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const MediaContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const MediaSquare = styled.div`
  width: 170px;
  height: 85px;

  margin-top: 10px;
  padding-top: 10px;
  margin-bottom: 15px;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: ${(props) =>
    !props.backgroundColor ? "" : props.backgroundColor};
  border-radius: 12px;

  @media screen and (max-width: 600px) {
    height: 60px;
    width: 130px;

    margin-bottom: 15px;
  }

  @media screen and (min-width: 601px) and (max-width: 1200px) {
    width: 160px;
    margin-bottom: 15px;
  }
`;

export const MediaTitle = styled.p`
  text-transform: uppercase;
  font-size: 0.9em;
  font-weight: 500;
`;

export const MediaNotaContainer = styled.div`
  display: flex;
`;

export const ContainerLoader = styled.div`
  width: 100%;
  height: 100%;

  margin-top: -5px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MediaNotaP = styled.span`
  margin: 0;
  padding: 0;
  margin-top: -5px;

  font-weight: 500;
  font-size: 2.5em;
`;

export const SpanTitle = styled.span`
  position: absolute;

  margin-top: 10px;
  margin-left: 10px;

  color: #797979;
  font-weight: 500;
`;

export const ClassificacaoContainer = styled.div`
  display: flex;
  /*justify-content: space-between;*/
  gap: 15px;

  margin: 15px 0;
`;

export const ClassificacaoInner = styled.div`
  width: 55px;
  height: 30px;

  background-color: #efefef;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

  display: flex;
`;

export const InnerIcon = styled.div`
  width: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InnerNumber = styled.div`
  font-weight: 400;

  width: 50%;

  display: flex;
  justify-content: start;
  align-items: center;
`;
