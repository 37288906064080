import React, { useEffect } from "react";
import * as S from "../styles/styled";
import { Button, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import {
  setSerieSelecionada,
  setTipoTurmaSelecionado,
  setDataAll,
} from "../../../redux/ducks/commons";
import { useDispatch, useSelector } from "react-redux";

const Filtros = ({
  bim,
  filtroBimestre,
  tipop,
  tipom,
  disciplinas,
  setDisciplinas,
  disciplinaSelecionada,
  setDisciplinaSelecionada,
}) => {
  const {
    serie,
    serieSelecionada,
    tipoTurma,
    tipoTurmaSelecionado,
    dataComplete,
  } = useSelector((state) => state.commons);

  const dispatch = useDispatch();

  const items = [
    {
      key: "",
      label: (
        <span
          className="filtro"
          onClick={() => {
            console.log("");
            setDisciplinaSelecionada("");
          }}
        >
          TODAS AS DISCIPLINAS
        </span>
      ),
      series: ["TODOS"],
      tipoTurma: ["TODOS"],
    },
  ];

  const itemsMap = [
    {
      key: "",
      label: <span className="filtro">TODAS AS SÉRIES</span>,
      disciplinas: ["TODOS"],
      tipoTurma: ["TODOS"],
    },
  ];

  const itemsTipoTurma = [
    {
      key: "",
      label: <span className="filtro">TODOS OS TIPOS DE TURMA</span>,
      series: ["TODOS"],
      disciplinas: ["TODOS"],
    },
  ];

  disciplinas.map((item) =>
    items.push({
      key: item.disciplina,
      label: <span className="filtro">{item.disciplina}</span>,
      series: item.series,
      tipoTurma: item.tipoTurmas,
    })
  );
  serie.map((item) =>
    itemsMap.push({
      key: item.codHabilitacao,
      label: <span className="filtro">{item.serie}</span>,
      disciplinas: item.disciplinas,
      tipoTurma: item.tipoTurmas,
    })
  );
  tipoTurma.map((item) =>
    itemsTipoTurma.push({
      key: item.tipoTurma,
      label: <span className="filtro">{item.tipoTurma}</span>,
      series: item.series,
      disciplinas: item.disciplinas,
      tipoTurma: item.tipoTurmas,
    })
  );

  const enterDisc = ({ key }) => {
    setDisciplinaSelecionada(key);
  };

  const changeSerie = ({ key }) => {
    dispatch(setSerieSelecionada(key));
  };

  const changeTipoTurma = ({ key }) => {
    dispatch(setTipoTurmaSelecionado(key));
  };

  const filterDisciplinas = (
    items,
    selectedDisciplina,
    tipoTurmaSelecionado
  ) => {
    return items.filter((item) => {
      if (item?.disciplinas[0] === "TODOS") {
        return true;
      } else if (selectedDisciplina) {
        return item?.disciplinas.includes(selectedDisciplina);
      } else if (tipoTurmaSelecionado) {
        return item?.tipoTurma.includes(tipoTurmaSelecionado);
      }
      return true;
    });
  };

  // Obtem os itens filtrados
  const filteredItems = filterDisciplinas(
    itemsMap,
    disciplinaSelecionada,
    tipoTurmaSelecionado
  );

  // Ordena os itens por codHabilitacao (key)
  const sortedItems = filteredItems.sort((a, b) => a.key.localeCompare(b.key));

  const filterSeries = (items, selectedSerie) => {
    return items.filter((item) => {
      if (item?.series[0] === "TODOS") {
        return true;
      } else if (selectedSerie) {
        var retorno = item?.series.includes(selectedSerie);
        return retorno;
        // if(){

        // }
      } else if (tipoTurmaSelecionado) {
        return item?.tipoTurma.includes(tipoTurmaSelecionado);
      }
      return true;
    });
  };

  useEffect(() => {
    var dadoAtual = dataComplete?.return;
    var dadosFiltrados;
    if (tipoTurmaSelecionado) {
      dadosFiltrados = dadoAtual.filter(
        (x) => x.tipoTurma === tipoTurmaSelecionado
      );
    } else if (serieSelecionada) {
      dadosFiltrados = dadosFiltrados
        ? dadosFiltrados.filter((x) => x.serie === serieSelecionada)
        : dadoAtual.filter((x) => x.serie === serieSelecionada);
    } else if (disciplinaSelecionada) {
      dadosFiltrados = dadosFiltrados
        ? dadosFiltrados.filter((x) => x.disciplina === disciplinaSelecionada)
        : dadoAtual.filter((x) => x.disciplina === disciplinaSelecionada);
    } else {
      dadosFiltrados = dataComplete?.return;
    }
    dispatch(setDataAll({ return: dadosFiltrados }));
  }, [tipoTurmaSelecionado, serieSelecionada, disciplinaSelecionada]);

  return (
    <>
      <S.FilrosContainer>
        {/*--------------- FILTROS DE BIMESTRES --------------*/}
        <S.StyledP marginleft="15px" marginleftmobile="15px">
          Bimestre:{" "}
        </S.StyledP>
        <S.RadioGroup
          optionType="button"
          buttonStyle="solid"
          defaultValue={bim}
          value={bim}
        >
          <S.RadioButton
            className="filtro"
            marginleftmobile="8px"
            marginleft="8px"
            marginright="8px"
            value="1"
            onClick={filtroBimestre}
          >
            1BIM
          </S.RadioButton>
          <S.RadioButton
            className="filtro"
            marginright="8px"
            marginleftmobile="5px"
            value="2"
            onClick={filtroBimestre}
          >
            2BIM
          </S.RadioButton>
          <S.RadioButton
            className="filtro"
            marginright="8px"
            marginleftmobile="5px"
            value="3"
            onClick={filtroBimestre}
          >
            3BIM
          </S.RadioButton>
          <S.RadioButton
            className="filtro"
            value="4"
            marginleftmobile="5px"
            onClick={filtroBimestre}
          >
            4BIM
          </S.RadioButton>
        </S.RadioGroup>
        <S.SeparadorFiltro />

        {/*--------------- FILTROS DE TIPO DE PROVA --------------*/}
        <S.p marginleftmobile="15px" marginRightMobile="120px">
          {" "}
          Tipo de Prova:{" "}
        </S.p>
        <S.RadioGroup
          optionType="button"
          buttonStyle="solid"
          defaultValue={tipop}
          value={tipop}
        >
          <S.RadioButton
            className="filtro"
            marginleft="8px"
            marginright="8px"
            marginleftmobile="12px"
            value="VP"
            onClick={filtroBimestre}
          >
            VP
          </S.RadioButton>
          <S.RadioButton
            className="filtro"
            value="VG"
            onClick={filtroBimestre}
            marginright="8px"
            marginleftmobile="5px"
          >
            VG
          </S.RadioButton>
          <S.RadioButton
            className="filtro"
            value="MEDIA"
            onClick={filtroBimestre}
            marginleftmobile="5px"
          >
            MÉDIA
          </S.RadioButton>
        </S.RadioGroup>
        <S.SeparadorFiltro />

        {/* --------------- FILTROS DE TIPO DE MÉDIA --------------*/}
        <S.p marginleftmobile="15px" marginRightMobile="120px">
          {" "}
          Tipo de Média:{" "}
        </S.p>
        <S.RadioGroup
          optionType="button"
          buttonStyle="solid"
          defaultValue={tipom}
          value={tipom}
          disabled={tipop === "MEDIA" ? false : true}
        >
          <S.RadioButton
            className="filtro"
            marginleft="8px"
            marginright="8px"
            marginleftmobile="12px"
            value="S"
            onClick={filtroBimestre}
          >
            SEM NTD
          </S.RadioButton>
          <S.RadioButton
            className="filtro"
            value="C"
            onClick={filtroBimestre}
            marginleftmobile="5px"
          >
            COM NTD
          </S.RadioButton>
        </S.RadioGroup>
      </S.FilrosContainer>

      <S.FilrosContainer
        style={{
          padding: "15px !important",
          marginTop: "0",
          paddingLeft: "5px",
        }}
      >
        {/*--------------- FILTROS DE SERIE --------------*/}
        <Dropdown.Button
          className="filtro"
          style={{ width: "fit-content" }}
          trigger={["click"]}
          icon={<DownOutlined />}
          placement="bottomRight"
          menu={{
            items: sortedItems,
            selectable: true,
            onClick: (e) => changeSerie(e),
            defaultSelectedKeys: [serieSelecionada],
            placement: "bottom",
          }}
        >
          {serieSelecionada
            ? sortedItems.find((x) => x.key === serieSelecionada)?.label
            : "TODAS AS SÉRIES"}
        </Dropdown.Button>
        <S.SeparadorFiltro />

        {/*--------------- FILTROS DE TIPO E TURMA --------------*/}
        <Dropdown.Button
          className="filtro"
          style={{ width: "fit-content" }}
          trigger={["click"]}
          icon={<DownOutlined />}
          placement="bottomRight"
          menu={{
            items: itemsTipoTurma,
            selectable: true,
            onClick: (e) => changeTipoTurma(e),
            defaultSelectedKeys: [tipoTurmaSelecionado],
            placement: "bottom",
          }}
        >
          {tipoTurmaSelecionado
            ? itemsTipoTurma.find((x) => x.key === tipoTurmaSelecionado)?.label
            : "TODOS OS TIPOS DE TURMA"}
        </Dropdown.Button>

        <S.SeparadorFiltro />
        {/*--------------- FILTROS DE DISCIPLINA --------------*/}
        <Dropdown.Button
          className="filtro"
          style={{ width: "fit-content" }}
          trigger={["click"]}
          icon={<DownOutlined />}
          placement="bottomRight"
          menu={{
            items: filterSeries(items, serieSelecionada),
            selectable: true,
            onClick: enterDisc,
            defaultSelectedKeys: [disciplinaSelecionada],
          }}
        >
          {disciplinaSelecionada && disciplinaSelecionada !== ""
            ? disciplinaSelecionada
            : "TODAS AS DISCIPLINAS"}
        </Dropdown.Button>
      </S.FilrosContainer>
    </>
  );
};

export default Filtros;
