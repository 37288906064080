import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AlunoPage, DisciplinaPage, TipoTurmaPage, TurmaPage } from "./pages";
import { getDataProcessamento } from "../../Services/Notas/Service";
import ModalNovidades from "../../components/ModalNovidades/ModalNovidades";
import GraficosMainPage from "./pages/Main/GraficosMainPage";
import { setSerie, setTipoTurma } from "../../redux/ducks/commons";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  useOrganizeNotas,
  updateBreadcrumb,
  useChangePage,
  renderSerie,
} from "./functions/";

const COLORS = ["#FF8042", "#00C49F"];

const Graficos = ({
  carregando,
  setCarregando,
  carregandoAllNotas,
  carregandoAllQuantidades,
  dados,
  setDados,
  quantidadeTotal,
  setQuantidadeTotal,
  setFilial,
  perfil,
  disciplinas,
  setDisciplinas,
  disciplinaSelecionada,
  setDisciplinaSelecionada,
}) => {
  const navigate = useNavigate();
  const { changePage, page, dataAll, serie, tipoTurma, tipoTurmaSelecionado } =
    useSelector((state) => state.commons, shallowEqual);
  const autenticado = useSelector((state) => state.usuario.autenticado);

  const { organizeNotas } = useOrganizeNotas(dados);
  const handlePageChange = useChangePage(page, changePage);
  const dispatch = useDispatch();

  const [qtd, setQtd] = useState();
  const [visible, setVisible] = useState(false);
  const [bim, setBim] = useState("1");
  const [tipop, setTipop] = useState("VP");
  const [tipom, setTipom] = useState("S");
  const [dadosSelecionados, setDadosSelecionados] = useState();
  const [qtfFiliais, setQtdFiliais] = useState();
  const [dtProcessamento, setDtProcessamento] = useState();

  //   const seriesWithDisciplinas = () => {

  //     return Object.values(dataAll?.return.reduce((acc, curr) => {
  //     const key = JSON.stringify({ serie: curr.serie, codHabilitacao: curr.codHabilitacao });
  //     if (!acc[key]) {
  //       acc[key] = {
  //         serie: curr.serie,
  //         codHabilitacao: curr.codHabilitacao,
  //         disciplinas: new Set(),
  //         tipoTurmas: new Set(),
  //       };
  //     }
  //     acc[key].disciplinas.add(curr.disciplina);
  //     acc[key].tipoTurmas.add(curr.tipoTurma);
  //     return acc;
  //   }, {})).map(item => ({ ...item, disciplinas: [...item.disciplinas], tipoTurmas: [...item.tipoTurmas] }));
  // }

  // const groupByDisciplinas = (data) => {
  //   const grouped = data.reduce((acc, curr) => {
  //     if (!acc[curr.disciplina]) {
  //       acc[curr.disciplina] = {
  //         disciplina: curr.disciplina,
  //         series: new Set(),
  //         tipoTurmas: new Set()
  //       };
  //     }
  //     acc[curr.disciplina].series.add(curr.codHabilitacao);
  //     acc[curr.disciplina].tipoTurmas.add(curr.tipoTurma);
  //     return acc;
  //   }, {});

  //   return Object.values(grouped).map(item => ({
  //     disciplina: item.disciplina,
  //     series: [...item.series],
  //     tipoTurmas: [...item.tipoTurmas]
  //   }));
  // };

  // const groupTipoTurma = (data) => {
  //   const grouped = data.reduce((acc, curr) => {
  //     if (!acc[curr.tipoTurma]) {
  //       acc[curr.tipoTurma] = {
  //         tipoTurma: curr.tipoTurma,
  //         series: new Set(),
  //         disciplinas: new Set(),
  //       };
  //     }
  //     acc[curr.tipoTurma].series.add(curr.codHabilitacao);
  //     acc[curr.tipoTurma].disciplinas.add(curr.disciplina);
  //     return acc;
  //   }, {});

  //   return Object.values(grouped).map(item => ({
  //     tipoTurma: item.tipoTurma,
  //     series: [...item.series],
  //     disciplinas: [...item.disciplinas]
  //   }));
  // };

  const groupByDisciplinas = (data) => {
    const grouped = data.reduce((acc, curr) => {
      const key = JSON.stringify({
        disciplina: curr.disciplina,
      });
      if (!acc[key]) {
        acc[key] = {
          disciplina: curr.disciplina,
          series: new Set(),
          tipoTurmas: new Set(),
        };
      }
      acc[key].series.add(curr.codHabilitacao);
      acc[key].tipoTurmas.add(curr.tipoTurma);
      return acc;
    }, {});

    return Object.values(grouped).map((item) => ({
      disciplina: item.disciplina,
      series: [...item.series],
      tipoTurmas: [...item.tipoTurmas],
    }));
  };

  const seriesWithDisciplinas = (data) => {
    const grouped = data.reduce((acc, curr) => {
      const key = JSON.stringify({
        serie: curr.serie,
        codHabilitacao: curr.codHabilitacao,
      });
      if (!acc[key]) {
        acc[key] = {
          serie: curr.serie,
          codHabilitacao: curr.codHabilitacao,
          disciplinas: new Set(),
          tipoTurmas: new Set(),
        };
      }
      acc[key].disciplinas.add(curr.disciplina);
      acc[key].tipoTurmas.add(curr.tipoTurma);
      return acc;
    }, {});

    return Object.values(grouped).map((item) => ({
      serie: item.serie,
      codHabilitacao: item.codHabilitacao,
      disciplinas: [...item.disciplinas],
      tipoTurmas: [...item.tipoTurmas],
    }));
  };

  const groupTipoTurma = (data) => {
    const grouped = data.reduce((acc, curr) => {
      if (!acc[curr.tipoTurma]) {
        acc[curr.tipoTurma] = {
          tipoTurma: curr.tipoTurma,
          series: new Set(),
          disciplinas: new Set(),
        };
      }
      acc[curr.tipoTurma].series.add(curr.codHabilitacao);
      acc[curr.tipoTurma].disciplinas.add(curr.disciplina);
      return acc;
    }, {});

    return Object.values(grouped).map((item) => ({
      tipoTurma: item.tipoTurma,
      series: [...item.series],
      disciplinas: [...item.disciplinas],
    }));
  };

  useEffect(() => {
    if (!autenticado) {
      navigate("/user_error");
    }

    if (!carregando && dados && dataAll) {
      organizeNotas(tipom, setQtd, setQtdFiliais);
      if (!disciplinas) {
        setDisciplinas(groupByDisciplinas(dataAll.return));
      }
      if (!serie) {
        dispatch(setSerie(seriesWithDisciplinas(dataAll.return)));
      }
      if (!tipoTurma) {
        dispatch(setTipoTurma(groupTipoTurma(dataAll.return)));
      }
    }
  }, [
    carregando,
    dados,
    tipom,
    dataAll,
    navigate,
    organizeNotas,
    tipoTurmaSelecionado,
  ]);

  useEffect(() => {
    handlePageChange();
  }, [page]);

  useEffect(() => {
    getDataProcessamento().then((resultado) => {
      setDtProcessamento(resultado);
    });
  }, []);

  const filtroBimestre = (e) => {
    updateBreadcrumb(e, setBim, setTipop, setTipom);
  };

  const handleClick = (e) => {
    if (e.name.includes("abaixo")) {
      setVisible(true);
    }
    setDadosSelecionados(e);
  };

  let contador = 0;
  const count = () => {
    return contador++;
  };

  switch (page) {
    case "Graficos":
      return (
        <>
          <ModalNovidades />
          <GraficosMainPage
            bim={bim}
            tipop={tipop}
            tipom={tipom}
            filtroBimestre={filtroBimestre}
            quantidadeTotal={quantidadeTotal}
            carregando={carregando}
            carregandoAllNotas={carregandoAllNotas}
            carregandoAllQuantidades={carregandoAllQuantidades}
            qtd={qtd}
            dtProcessamento={dtProcessamento}
            COLORS={COLORS}
            qtfFiliais={qtfFiliais}
            count={count}
            handleClick={handleClick}
            setCarregando={setCarregando}
            setQuantidadeTotal={setQuantidadeTotal}
            dados={dados}
            setDados={setDados}
            setFilial={setFilial}
            visible={visible}
            setVisible={setVisible}
            perfil={perfil}
            disciplinas={disciplinas}
            setDisciplinas={setDisciplinas}
            disciplinaSelecionada={disciplinaSelecionada}
            setDisciplinaSelecionada={setDisciplinaSelecionada}
          />{" "}
        </>
      );

    case "TipoTurma":
      return (
        <TipoTurmaPage
          dadosSelecionados={dadosSelecionados}
          COLORS={COLORS}
          bim={bim}
          tipop={tipop}
          tipom={tipom}
          quantidadeTotal={quantidadeTotal}
          perfil={perfil}
        />
      );

    case "Turma":
      return (
        <TurmaPage
          dadosSelecionados={dadosSelecionados}
          COLORS={COLORS}
          bim={bim}
          tipop={tipop}
          tipom={tipom}
          quantidadeTotal={quantidadeTotal}
          perfil={perfil}
        />
      );

    case "Disciplina":
      return (
        <DisciplinaPage
          dadosSelecionados={dadosSelecionados}
          COLORS={COLORS}
          bim={bim}
          tipop={tipop}
          tipom={tipom}
          quantidadeTotal={quantidadeTotal}
          perfil={perfil}
        />
      );

    case "Aluno":
      return (
        <AlunoPage
          dadosSelecionados={dadosSelecionados}
          COLORS={COLORS}
          bim={bim}
          tipop={tipop}
          tipom={tipom}
          quantidadeTotal={quantidadeTotal}
          perfil={perfil}
        />
      );
    default:
  }
};

export default Graficos;
