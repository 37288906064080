import React from 'react'

const Header = () => {
  // const navigate = useNavigate();
  return (
    <div style={{ marginTop: '15px' }} className="titulo-conteudo">
      <div className='container-logo'><img className='image1' src="https://sistemafb.com.br/wp-content/uploads/2020/10/oefb_logo.png" alt="Logo FB" /></div>
      <div className='container-titulo'><p className='p1' >Auditoria de Rendimento</p></div>
      {/* <Link to='/'><Button className='go-to-sistema'>Ver tudo</Button></Link> */}
    </div>

  )
}

export default Header;