import React, { memo, useRef, useEffect } from "react";
import { useState } from "react";
import { Tabela } from "./styles/Style";
import { Empty } from "antd";
import { IconeTriste } from "../../../../../components/Icons/icons";

const TableReducao = ({ reducao }) => {
  const refDataTable = useRef(null);
  const [dados, setDados] = useState();

  const colunas = React.useMemo(() => {
    return [
      {
        label: "Código Bolsa",
        field: "codBolsa",
      },
      {
        label: "Nome Bolsa",
        field: "nomeBolsa",
      },
      {
        label: "Parcela",
        field: "parcela",
      },
      {
        label: "Porcentagem",
        field: "porcentagemDesconto",
      },
      {
        label: "Valor Base",
        field: "valorBase",
      },
      {
        label: "Valor Desconto",
        field: "valorDesconto",
      },
      {
        label: "Vencimento",
        field: "planoVencimento",
      },
    ];
  }, []);

  function formatarData(dataOriginal) {
    const data = new Date(dataOriginal);

    function adicionarZero(numero) {
      if (numero < 10) {
        return "0" + numero;
      }
      return numero;
    }

    const dia = adicionarZero(data.getDate());
    const mes = adicionarZero(data.getMonth() + 1);
    const ano = data.getFullYear();

    const dataFormatada = `${dia}/${mes}/${ano}`;

    return dataFormatada;
  }

  function formatarMoeda(valor) {
    var numero = parseFloat(valor);

    if (isNaN(numero)) {
      return "Valor inválido";
    }
    var valorFormatado = numero.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });

    return valorFormatado;
  }

  const dadosDaTabela = React.useMemo(() => {
    return {
      columns: colunas,
      rows: dados?.map((item, index) => ({
        ...item,
        porcentagemDesconto: item.porcentagemDesconto.toFixed(2) + "%",
        valorBase: formatarMoeda(item.valorBase),
        valorDesconto: formatarMoeda(item.valorDesconto),
        planoVencimento: formatarData(item.planoVencimento),
        key: index.toString(),
      })),
    };
  }, [colunas, dados]);

  useEffect(() => {
    if (reducao) {
      setDados(reducao);
    }
  }, [reducao]);

  return (
    <Tabela
      striped={dadosDaTabela?.rows?.length > 0 ? true : false}
      hover={dadosDaTabela?.rows?.length > 0 ? true : false}
      small
      style={{ marginTop: "60px" }}
      noBottomColumns
      displayEntries={false}
      entries={20}
      infoLabel={["Mostrando", "a", "de", "registros"]}
      paginationLabel={["<", ">"]}
      paging={dadosDaTabela?.rows?.length > 20 ? true : false}
      pagesAmount={5}
      // searchLabel=" "
      searching={false}
      responsive
      disableRetreatAfterSorting={true}
      data={dadosDaTabela}
      ref={refDataTable}
      noRecordsFoundLabel={
        <Empty
          image={<IconeTriste />}
          description={"Não foram encontrados resultados"}
          style={{ marginTop: "50px", marginBottom: "50px" }}
        />
      }
    />
  );
};

export default memo(TableReducao);
