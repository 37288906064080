import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Layout, Menu } from 'antd'
import BackroudImage from '../../assets/img/topo.png'


const { Header } = Layout

export const HeaderWrapper = styled(Header)`
    background-color: #009240;
    background-image: url(${BackroudImage});
    background-size: cover;

    display: flex;

    width: 100%;
    height: 190px;

    overflow: hidden;
    position: relative;

    @media screen and (max-width: 600px) {
        position: inicial !important;
        justify-content: space-between;

        padding-inline: 0px;
    }

`

export const HeaderContent = styled.div`
    height: 100%;
    width: 100%;
    padding: 0 48px;

    @media screen and (max-width: 600px) {
        display: flex;
        align-items: center;

        padding: 0;

        width: auto;
    }
`

export const HeaderLogo = styled(Link)`
    display: flex;
    align-items: end;
    justify-content: center;
    text-decoration: none !important;

    & > img {
        height: 90px;
        width: auto;
        margin-right: 0.25rem;
    }

    & > span {
        color: #fff;
        font-size: 22px;
        font-family: 'Poppins', sans-serif;
        font-weight: normal;
        font-style: normal;
    }

    &:hover {
        color: #fff;
    }

    @media screen and (max-width: 600px) {
        align-items: center;

        & > img {
            height: 70px;
            width: auto;
        }
    }
`



export const HeaderControls = styled.section`
    display: flex;
    align-items: center;
    padding-right:70px;

    @media screen and (max-width: 600px) {
        margin-right: 0px;
        justify-content: center;
        padding: 0;
    }
`


export const pHeader = styled.p`
    margin:0;
    
    color: #FFF;
    text-transform: uppercase;
    margin-left: 15px;
`

export const HeaderMenu = styled(Menu)`
    background-color: #004b21;
    padding: 1rem;
`

export const HeaderMenuItem = styled(Menu.Item)`
    background-color: #004b21;
    color: #fff;

    &:hover {
        background-color: #004b21;
        color: #f8c414;
    }
`

export const HeaderPropaganda = styled.img`
    width: 150px;

    top: 0;
    left: 30px;
    position: absolute;

    @media screen and (max-width: 600px) {
        left: -20px !important;
        position: initial !important;

        margin-left: -20px;

    }
`

export const HeaderPropagandaContainer = styled.div`
    
`

export const NavBarContainer = styled.div`
    width:100%;
    height: 25px;

    display: flex;
    justify-content: center;

    @media screen and (max-width: 600px) {
        height: auto !important;
    }
`
