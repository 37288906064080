import axios from "axios";
import { STORAGE_USER, basePath, getHeader, getHeaderBody } from "../../Const/webConst";
import api from "Services/axiosConfig";

// const storageUser = sessionStorage.getItem(STORAGE_USER);
// const usuario = storageUser ? JSON.parse(atob(storageUser)) : "";
// const user = usuario?.nameid;

export const getMediaByRA = async (ra) => {
  const resposta = await api.get(`/AlunoInfo/Media`, {
    params: { ra },
    headers: getHeader(),
  });
  if (resposta.status !== 200) throw new Error(resposta.data.exception.errorMessage);
  return resposta.data;
};

export const getFotoByRA = async (ra) => {
  const resposta = await api.get(`/AlunoInfo/Foto`, {
    params: { ra },
    headers: getHeader(),
  });
  if (resposta.status !== 200) throw new Error(resposta.data.exception.errorMessage);
  return resposta.data;
};

export const getPontosOcorrencia = async (RA) => {
  const resposta = await api.get(`/AlunoInfo/Ocorrencia`, {
    params: { ra: RA },
    headers: getHeader(),
  });
  if (resposta.status !== 200) throw new Error(resposta.data.exception.errorMessage);
  return resposta.data;
};

export const getClassificacao = async (RA) => {
  const resposta = await api.get(`/AlunoInfo/Classificacao`, {
    params: { ra: RA },
    headers: getHeader(),
  });
  if (resposta.status !== 200) throw new Error(resposta.data.exception.errorMessage);
  return resposta.data;
};

export const novoContato = async (contato) => {
  const username = JSON.parse(sessionStorage.getItem("nmuser"));
  const body = {
    RA: contato.Matricula,
    Bimestre: contato.Etapa,
    DataOcorrencia: contato.DataOcorrencia,
    Observacoes: contato.Observacao,
    ObservacaoRestrita: contato.ObservacaoRestrita,
    UsuarioCriacao: username.value,
    CodFilial: contato.CodigoFilial,
  };

  try {
    const response = await api.post(`/Atendimento/NovaOcorrencia`, body, {
      headers: getHeaderBody(),
    });
    return response.data;
  } catch (error) {
    return error;
  }
};