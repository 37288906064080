import React from "react";
import UserIcon from "./Icons/user-square-svgrepo-com.svg";
import Logo from "../../assets/img/logo-farias-brito-branca.png";
import Propaganda from "../../assets/img/caio-sem-aviao.png";
import { Button, Tooltip } from "antd";
import Navbar from "./Navbar";
import * as S from "./styled";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { LogoutOutlined } from "@ant-design/icons";
import { UsuarioActions as UsuarioCreators } from "../../redux/ducks/usuario";


export const Header = () => {
  const user = useSelector((state) => state.usuario.usuario, shallowEqual);
  const dispatch = useDispatch();

  return (
    <>
      <S.HeaderWrapper>
        <S.HeaderPropagandaContainer>
          <S.HeaderPropaganda src={Propaganda} alt="Propaganda" />
        </S.HeaderPropagandaContainer>
        <S.HeaderContent>
          <div
            className="w-100 d-flex align-items-center justify-content-between topo1-container"
            style={{
              height: "61%",
              overflow: "hidden",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <S.HeaderControls></S.HeaderControls>

            <S.HeaderLogo to="/">
              <img src={Logo} alt="logomarca" />
            </S.HeaderLogo>

            <S.HeaderControls style={{ paddingTop: "40px" }}>
              <Tooltip
                placement="bottom"
                title="LOGOUT"
                style={{ display: "flex" }}
              >
                <Button
                onClick={() => dispatch(UsuarioCreators.sair())}
                  style={{
                    marginRight: "10px",
                    width: "30px",
                    height: "30px",
                    padding: "0px",
                    borderRadius: "12px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LogoutOutlined />
                </Button>
              </Tooltip>

              <Tooltip
                placement="bottom"
                title={user?.nome}
                style={{ display: "flex" }}
              >
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img width={30} src={UserIcon} alt="userIcon" />
                  <S.pHeader>{user?.unique_name}</S.pHeader>
                </span>
              </Tooltip>
            </S.HeaderControls>
          </div>
        </S.HeaderContent>
      </S.HeaderWrapper>
      <S.NavBarContainer>
        <Navbar />
      </S.NavBarContainer>
    </>
  );
};

export default Header;
