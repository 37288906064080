import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import { UsuarioActions as UsuarioCreators } from "./redux/ducks/usuario";
import { setSGE } from "./redux/ducks/commons";

const Startup = ({ children }) => {
  const autenticado = useSelector((state) => state.usuario.autenticado);
  const userSGE = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (autenticado) {
      dispatch(UsuarioCreators.getTiposCursos());
    }
  }, [autenticado, dispatch]);

  if (!autenticado) {
    if (window.location.pathname.includes("/sge/")) {
      dispatch(setSGE(window.location.pathname.includes("/sge/")));
      dispatch(UsuarioCreators.autenticarSGE(userSGE));
    } else {
      return <Navigate to="/login" />;
    }
  }
  return children;
};

export default Startup;
