import React from 'react'
import { PieChart, Pie, Tooltip, Cell } from 'recharts';
import * as S from '../../../styles/styled'


const PieChartCompTipoTurma = ({ data,
    handleClick,
    COLORS,
    bim,
    tipop,
    tipom,
    filial,
    setCarregando,
    quantidadeTotal,
    dadosSelecionados
}) => {


    const verificarDuplicatas = (alunos) => {
        const camposChave = ['ra'];
        const valoresUnicos = [];

        for (const aluno of alunos) {
            const duplicata = valoresUnicos.some((alunoExistente) => {
                return camposChave.every((campo) => alunoExistente[campo] === aluno[campo]);
            });

            if (!duplicata) {
                valoresUnicos.push(aluno);
            }
        }

        return valoresUnicos;
    }

    const formataItem = (item) => {
        if(item.tipoTurma === 'IME/ITA'){item.turmaTipo = 'ITA/IME'}else{item.turmaTipo = item.tipoTurma};
        var total = quantidadeTotal?.filter(x => x.codHabilitacao === dadosSelecionados.codHabilitacao && x.descricao === item.turmaTipo && x.tipo === 'TIPOTURMA'  && x.codFilial === dadosSelecionados.codFilial)[0].total;
        var totalUnico = verificarDuplicatas(item.alunos);
        var arr = [{
            name: 'Alunos rendimento abaixo da média',
            value: totalUnico.length,
            menu: [{
                title: item.tipoTurma,
                codHabilitacao: dadosSelecionados.codHabilitacao,
                bimestre: bim,
                tipoProva: tipop,
                codFilial: filial,
                nivelEnsino: dadosSelecionados.nivelEnsino
            }]
        },
        {
            name: 'Alunos com rendimento na média ou acima',
            value: total - totalUnico.length
        }];

        return arr;
    }

    const formataCell = (item) => {
        if(item.tipoTurma === 'IME/ITA'){item.turmaTipo = 'ITA/IME'}else{item.turmaTipo = item.tipoTurma};
        var total = quantidadeTotal?.filter(x => x.codHabilitacao === dadosSelecionados.codHabilitacao && x.descricao === item.turmaTipo && x.tipo === 'TIPOTURMA'  && x.codFilial === dadosSelecionados.codFilial)[0].total;
        var totalUnico = verificarDuplicatas(item.alunos);
        var arr = [{
            name: 'Alunos rendimento abaixo da média',
            value: totalUnico.length,
            menu: [{
                title: item.tipoTurma,
                codHabilitacao: dadosSelecionados.codHabilitacao,
                bimestre: bim,
                tipoProva: tipop,
                codFilial: filial,
                nivelEnsino: dadosSelecionados.nivelEnsino
            }]
        },
        {
            name: 'Alunos com rendimento na média ou acima',
            value: total - totalUnico.length
        }];


        var retorno = arr?.map((entry, index) => (
            <React.Fragment key={`cell-${index}`} >
                <Cell fill={COLORS[index % COLORS.length]} label />
            </React.Fragment>
        ))
        return retorno;
    }

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, value }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <>
                <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" style={{ fontSize: '1em' }}>
                    {`${value}`}
                </text>
                <text x={x} y={y + 15} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" style={{ fontSize: '.7em' }}>
                    {`(${(percent * 100).toFixed(0)}%)`}
                </text>
            </>
        );
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <p className="label">{`${payload[0].name} : ${payload[0].value}`}</p>
                </div>
            );
        }

        return null;
    };

    return (
        data && data.tiposTurma.map((item, index) => (
            <React.Fragment key={`pieChartWrapper-TipoTurma-${index}`}>
                <S.PieChartItemWrapper>
                    <S.pCharTitle>{item.tipoTurma}</S.pCharTitle>
                    <PieChart width={200} height={200}>
                        <Pie
                            data={formataItem(item)}
                            dataKey="value"
                            nameKey="name"
                            cx="50%"
                            cy="50%"
                            startAngle={0}
                            outerRadius={100}
                            label={renderCustomizedLabel}
                            labelLine={false}
                            onClick={(e) => handleClick(e.payload)}>
                            {formataCell(item)}
                        </Pie>
                        <Tooltip content={<CustomTooltip />} />
                    </PieChart>
                </S.PieChartItemWrapper>
            </React.Fragment>
        )))

}
export default PieChartCompTipoTurma