export const IconeTriste = ({ largura, marginTop }) => {
    return (
      <div>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width={largura || '87.5'}
          height='87.5'
          viewBox='0 0 87.5 87.5'
        >
          <g opacity='0.63'>
            <path
              fill='#d8d8d8'
              d='M43.75 0A43.75 43.75 0 1087.5 43.75 43.8 43.8 0 0043.75 0zm0 84A40.25 40.25 0 1184 43.75 40.295 40.295 0 0143.75 84z'
            ></path>
            <path
              fill='#d8d8d8'
              d='M8.348 0A8.358 8.358 0 000 8.35h3.5a4.85 4.85 0 119.7 0h3.5A8.358 8.358 0 008.348 0z'
              transform='translate(49.4 24.934)'
            ></path>
            <path
              fill='#d8d8d8'
              d='M8.348 0A8.358 8.358 0 000 8.35h3.5a4.85 4.85 0 119.7 0h3.5A8.36 8.36 0 008.348 0z'
              transform='translate(21.403 24.934)'
            ></path>
            <path
              fill='#d8d8d8'
              d='M20.118 0C11.209 0 3.124 4.18 0 10.4l3.127 1.572c2.545-5.069 9.374-8.475 16.991-8.475s14.446 3.406 16.992 8.475l3.127-1.572C37.111 4.18 29.025 0 20.118 0z'
              transform='translate(23.632 54.033)'
            ></path>
          </g>
        </svg>
      </div>
    );
  }