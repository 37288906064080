import React, { memo, useRef, useEffect, useCallback } from "react";
import { MDBDataTable as Table } from "mdbreact";
import {
  getMediaSerieFilial,
  getMediaSerieOrg,
} from "../../../../../Services/Notas/Service";

const Tabela = ({
  dadosTipoTurma,
  handleClick,
  status,
  setMediaSerieFilial,
  setMediaSerieOrg,
  perfil
}) => {
  const refDataTable = useRef(null);

  const colunas = React.useMemo(() => {
    return [
      {
        label: "Turma",
        field: "turma",
        width: 20,
        sort: "disabled",
      },
      {
        label: "Nome",
        field: "nome",
        sort: "asc",
      },
      {
        label: `${perfil ? "RA" : ""}`,
        field: `${perfil ? "ra" : ""}`,
      },
      {
        label: "Disciplina",
        field: "disciplina",
      },
      {
        label: "Nota",
        field: "nota",
      },
    ];
  }, []);

  const organizarRegistro = useCallback((data) => {
    const result = data
      .filter((x) => x.codFilial === status.codFilial)[0]
      .bimestres.filter((y) => y.bimestre === status.bimestre)[0]
      .avaliacoes.filter((p) => p.tipoAvaliacao === status.tipoProva)[0]
      .niveisEnsino.filter((i) => i.nivelEnsino === status.nivelEnsino)[0]
      .series.filter((l) => l.codHabilitacao === status.codHabilitacao)[0]
      .tiposTurma.filter((q) => q.tipoTurma === status.title)[0];

    const semDuplicidade = removerDuplicidade(result.alunos);

    return semDuplicidade;
  },[status]);

  const removerDuplicidade = (alunos) => {
    const alunosUnicos = [];
    const alunosDuplicados = {};

    alunos.forEach((aluno) => {
      const {
        bimestre,
        codFilial,
        ra,
        nome,
        serie,
        turma,
        tipoTurma,
        tipoAvaliacao,
        disciplina,
        nota,
      } = aluno;

      const chave = `${bimestre}-${codFilial}-${ra}-${nome}-${serie}-${turma}-${tipoTurma}-${tipoAvaliacao}-${disciplina}-${nota}`;

      if (!alunosDuplicados[chave]) {
        alunosUnicos.push(aluno);
        alunosDuplicados[chave] = true;
      }
    });

    return alunosUnicos;
  };

  const dadosDaTabela = React.useMemo(() => {
    return {
      columns: colunas,
      rows: organizarRegistro(dadosTipoTurma).map((registro, index) => ({
        ...registro,
        nota: (Math.floor(registro.nota) / 10).toFixed(1),
        clickEvent: () => {
          handleClick({ ...registro }, true);
        },
        key: index.toString()
      })),
    };
  }, [colunas, dadosTipoTurma, handleClick, organizarRegistro]);

  useEffect(() => {
    if (status?.codFilial && status?.title && status?.codHabilitacao) {
      getMediaSerieFilial(
        status?.codFilial,
        status?.title,
        status?.codHabilitacao
      ).then((resultado) => {
        setMediaSerieFilial(resultado[0]);
      });
    }

    if (status?.title && status?.codHabilitacao) {
      getMediaSerieOrg(status?.title, status?.codHabilitacao).then(
        (resultado) => {
          setMediaSerieOrg(resultado[0]);
        }
      );
    }
  }, [status, setMediaSerieFilial, setMediaSerieOrg]);

  return (
    <Table
      striped={dadosDaTabela.rows.length > 0 ? true : false}
      hover={dadosDaTabela.rows.length > 0 ? true : false}
      autoWidth={true}
      small
      noBottomColumns
      displayEntries={false}
      entries={20}
      infoLabel={["Mostrando", "a", "de", "registros"]}
      paginationLabel={["<", ">"]}
      paging={true}
      pagesAmount={5}
      searchLabel=" "
      responsive
      disableRetreatAfterSorting={true}
      data={dadosDaTabela}
      ref={refDataTable}
    />
  );
};

export default memo(Tabela);
