import React from 'react';
import * as Icons from '@ant-design/icons';
import * as S from './styled'


export const ButtonSimple = (props) => {
    return (
        <S.LinkContainer onClick={() => props.setMainPage(props.link)}>
            <S.Button>
                {props.nome}
            </S.Button>
        </S.LinkContainer>
    )
}

export const ButtonIcon = ({setMainPage, nome, icone,link}) => {
    const Icon = Icons[icone]; // obter ícone do Ant Design com base no nome fornecido

    return (
        <S.LinkContainer onClick={() => setMainPage(link)}>
            <S.Button>
            <S.ButtonInsideHiddenContainer/>
                <S.ButtonInsideContainerIcon>
                    <Icon />
                </S.ButtonInsideContainerIcon>
                <S.ButtonInsideContainerText>
                    {nome}
                </S.ButtonInsideContainerText>
            </S.Button>
        </S.LinkContainer>
    )
}