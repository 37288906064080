import axios from 'axios'
import {basePath, getHeader} from '../../Const/webConst'

export const getUserInfo = async (user) => {
    const resposta = await axios.get(
      `${basePath}/User?user=${user}`,
      {
        headers: getHeader()
      }
    );
    if (!resposta.data)
      throw new Error(resposta.data.exception.errorMessage);
    return resposta.data;
  };


export default getUserInfo;
