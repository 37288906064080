import { combineReducers } from '@reduxjs/toolkit';
import usuario from './usuario';
import request from './request';
import commons from './commons';

const rootReducer = combineReducers({
  usuario,
  request,
  commons,
});

export default rootReducer;