import React, { useCallback, useEffect, useState } from "react";
import PieChartComp from "../../components/PieChart";
import { RightOutlined, UpCircleOutlined } from "@ant-design/icons";
import * as S from "../../styles/styled";
import MenuModal from "../../components/MenuModal";
import Filtros from "../../components/Filtros";
import { ThreeDots } from "react-loading-icons";
import { useSelector } from "react-redux";
import TitleWrapper from "../Components/GraficosComponents/TitleWrapper";
import FiliaisContainer from "../Components/GraficosComponents/FiliaisContainer";
import PieCharts from "../Components/GraficosComponents/PieCharts";

const GraficosMainPage = ({
  bim,
  tipop,
  tipom,
  filtroBimestre,
  quantidadeTotal,
  carregando,
  carregandoAllNotas,
  carregandoAllQuantidades,
  qtd,
  dtProcessamento,
  COLORS,
  qtfFiliais,
  count,
  handleClick,
  setCarregando,
  setQuantidadeTotal,
  dados,
  setDados,
  setFilial,
  visible,
  setVisible,
  disciplinas,
  setDisciplinas,
  disciplinaSelecionada,
  setDisciplinaSelecionada,
  perfil,
}) => {
  const { sge, dataAll, serieSelecionada } = useSelector(
    (state) => state.commons
  );

  const [responsive, setResponsive] = useState(false);

  const findCodHabilitacao = useCallback(
    (data) => {
      if (serieSelecionada && serieSelecionada !== "") {
        let codHabilitacao = serieSelecionada;
        let results = [];

        data.bimestres.forEach((bimestre) => {
          bimestre.avaliacoes.forEach((avaliacao) => {
            avaliacao.nivelEnsino.forEach((nivel) => {
              nivel.series.forEach((serie) => {
                if (serie.codHabilitacao === codHabilitacao) {
                  results.push(serie);
                }
              });
            });
          });
        });

        return results;
      } else {
        return data.bimestres;
      }
    },
    [serieSelecionada]
  );


  useEffect(() => {
    if (serieSelecionada && serieSelecionada !== "") {
      setResponsive(true);
    } else if (perfil === "PROFESSOR") {
      setResponsive(true);
    } else {
      setResponsive(false);
    }
  }, [serieSelecionada, disciplinaSelecionada]);

  return !quantidadeTotal ||
    carregando === true ||
    carregandoAllNotas === true ||
    carregandoAllQuantidades === true ||
    !dataAll ||
    !qtd ||
    !dados ? (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        paddingTop: "150px",
      }}
    >
      <ThreeDots fill="#025940" />
    </div>
  ) : (
    dados && dataAll && qtd && (
      <>
        {dtProcessamento && (
          <S.DataProcessamentoDiv
            style={
              sge
                ? { marginBottom: "0", padding: "20px" }
                : { marginBottom: "25px" }
            }
          >
            Processado em: {dtProcessamento.dataFormatada}.
          </S.DataProcessamentoDiv>
        )}
        <S.ArrowUpContainer>
          <S.ArrowUp
            href={`${window.location.origin}${window.location.pathname}#`}
          >
            <UpCircleOutlined />
          </S.ArrowUp>
        </S.ArrowUpContainer>
        <TitleWrapper bim={bim} tipop={tipop} tipom={tipom} />

        <Filtros
          bim={bim}
          filtroBimestre={filtroBimestre}
          tipop={tipop}
          tipom={tipom}
          disciplinas={disciplinas}
          setDisciplinas={setDisciplinas}
          disciplinaSelecionada={disciplinaSelecionada}
          setDisciplinaSelecionada={setDisciplinaSelecionada}
        />

        <FiliaisContainer qtfFiliais={qtfFiliais} qtd={qtd} findCodHabilitacao={findCodHabilitacao}/>

        <S.PieCharts responsive={responsive}>
          {qtd?.map((item, index) => {
            const habilitacaoItems = findCodHabilitacao(item);
            return (
              habilitacaoItems?.length > 0 && (
                <React.Fragment
                  key={`title-graficos__${item.codFilial}_${
                    item.filial
                  }_${index}_${count()}`}
                >
                  <S.PieChartContainer responsive={responsive}>
                    <S.PieChartWrapperContainer id={`${item.filial}`}>
                      <S.h1>{item.filial}</S.h1>

                      <PieCharts
                        item={item}
                        count={count}
                        handleClick={handleClick}
                        COLORS={COLORS}
                        bim={bim}
                        tipop={tipop}
                        tipom={tipom}
                        filial={item.codFilial}
                        setCarregando={setCarregando}
                        quantidadeTotal={quantidadeTotal}
                        setQuantidadeTotal={setQuantidadeTotal}
                        dados={dados}
                        setDados={setDados}
                        setFilial={setFilial}
                        filialNomeCurto={item.filial}
                        responsive={responsive}
                      />

                      <S.legendaContainer responsive={responsive}>
                        <p style={{ margin: 0, fontSize: ".7em" }}>Legenda:</p>
                        <div style={{ display: "flex", gap: "20px" }}>
                          <S.legendaItemContainer
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontSize: ".7em",
                              gap: "6px",
                            }}
                          >
                            <S.legendaItem
                              style={{ backgroundColor: COLORS[0] }}
                            ></S.legendaItem>
                            <div>Alunos com rendimento abaixo da média</div>
                          </S.legendaItemContainer>
                          <S.legendaItemContainer>
                            <S.legendaItem
                              style={{ backgroundColor: COLORS[1] }}
                            ></S.legendaItem>
                            <div>Alunos com rendimento na média ou acima</div>
                          </S.legendaItemContainer>
                        </div>
                      </S.legendaContainer>
                    </S.PieChartWrapperContainer>
                  </S.PieChartContainer>
                  <MenuModal visible={visible} setVisible={setVisible} />
                </React.Fragment>
              )
            );
          })}
        </S.PieCharts>
      </>
    )
  );
};

export default GraficosMainPage;
