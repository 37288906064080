import React, { useState } from "react";
import { PieChart, Pie, Tooltip, Cell } from "recharts";
import * as S from "../styles/styled";
import { useEffect } from "react";
import { Divider } from "antd";
import { useCallback } from "react";
import { useSelector } from "react-redux";

const PieChartComp = ({
  data,
  handleClick,
  COLORS,
  bim,
  tipop,
  tipom,
  filial,
  quantidadeTotal,
  setFilial,
  filialNomeCurto,
  responsive
}) => {
  const [dadosAll, setDadosAll] = useState([]);
  const { serie, serieSelecionada } = useSelector((state) => state.commons);

  const enviaDados = useCallback((e) => {
    var tipo = e;
    var nivel = tipo[0]?.nivelEnsino;
    setDadosAll(nivel);
  }, []);

  const formataItem = (item, nivelEnsino, tipop, bim, tipom) => {
    var total = quantidadeTotal?.filter(
      (x) =>
        x.codHabilitacao === item.codHabilitacao &&
        x.descricao === "TOTALGERAL" &&
        x.tipo === "SERIE" &&
        x.codFilial === item.codFilial
    )[0]?.total;
    var arr = [
      {
        name: "Alunos rendimento abaixo da média",
        value: item.quantidade,
        menu: [
          {
            title: item.serie,
            link: item.serie,
            codHabilitacao: item.codHabilitacao,
            bimestre: bim,
            tipoProva: tipop,
            codFilial: filial,
            nivelEnsino,
            tipoMedia: tipom,
            filialNomeCurto,
          },
        ],
      },
      {
        name: "Alunos com rendimento na média ou acima",
        value: total - item.quantidade,
        menu: [
          {
            title: item.serie,
            link: item.serie,
          },
        ],
      },
    ];
    return arr;
  };

  const formataCell = (item) => {
    var arr = [
      {
        name: "Alunos rendimento abaixo da média",
        value: 25,
        menu: [
          {
            title: item.serie,
            link: item.serie,
            codHabilitacao: item.codHabilitacao,
          },
        ],
      },
      {
        name: "Alunos com rendimento na média ou acima",
        value: 100,
        menu: [
          {
            title: item.serie,
            link: item.serie,
          },
        ],
      },
    ];

    var retorno = arr?.map((entry, index) => (
        <React.Fragment key={`cell-${index}_${entry.value}_${entry.menu[0].codHabilitacao}_${entry.menu[0].link}`}>
          <Cell
            fill={COLORS[index % COLORS.length]}
            label
          />
        </React.Fragment>
      ));
    return retorno;
  };

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
    value,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <>
        <text
          x={x}
          y={y}
          fill="white"
          textAnchor={x > cx ? "start" : "end"}
          dominantBaseline="central"
          style={{ fontSize: "1em" }}
        >
          {`${value}`}
        </text>
        <text
          x={x}
          y={y + 15}
          fill="white"
          textAnchor={x > cx ? "start" : "end"}
          dominantBaseline="central"
          style={{ fontSize: ".7em" }}
        >
          {`(${(percent * 100).toFixed(0)}%)`}
        </text>
      </>
    );
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${payload[0].name} : ${payload[0].value}`}</p>
        </div>
      );
    }

    return null;
  };

  const renderNivelEnsino = (nivel) => {
    switch (nivel) {
      case "EF2":
        return "ENSINO FUNDAMENTAL II";
      case "EF1":
        return "ENSINO FUNDAMENTAL I";
      case "E.M":
        return "ENSINO MÉDIO";
      default:
        return nivel.toString();
    }
  };

  const renderSerie = (serie) => {
    switch (serie) {
      case "1o ANO EF1":
        return "1º ANO EF";
      case "2o ANO EF1":
        return "2º ANO EF";
      case "3o ANO EF1":
        return "3º ANO EF";
      case "4o ANO EF1":
        return "4º ANO EF";
      case "5o ANO EF1":
        return "5º ANO EF";
      case "6o ANO EF2":
        return "6º ANO EF";
      case "7o ANO EF2":
        return "7º ANO EF";
      case "8o ANO EF2":
        return "8º ANO EF";
      case "9o ANO EF2":
        return "9º ANO EF";
      case "1o ANO E.M":
        return "1º ANO EM";
      case "2o ANO E.M":
        return "2º ANO EM";
      case "3o ANO E.M":
        return "3º ANO EM";
      default:
        return serie.toString();
    }
  };

  useEffect(() => {
    setFilial(filial);
    enviaDados(data);
  }, [data, enviaDados, setFilial, filial]);

  let contador = 0;
  const count = () => {
    return contador++;
  };

  return quantidadeTotal && dadosAll ? (
    dadosAll?.map((niveis, index) => {
      return (
        niveis.series.filter(x => x.codHabilitacao == (serieSelecionada ? serieSelecionada : x.codHabilitacao)).length > 0 && niveis.series.filter(x => x.quantidade > 0) &&
        <React.Fragment  key={`pieChart-titleContainer_${
            niveis.nivelEnsino
          }_${index}`}>
          <S.nivelTituloContainer
          responsive={responsive}
            key={`pieChart-titleContainer_${
              niveis.nivelEnsino
            }_${index}`}
          >
            <Divider
              orientation="left"
              style={{
                fontSize: "1.2em",
                marginLeft: "-20px",
                width: "120%",
                color: "#009240",
              }}
            >
              {renderNivelEnsino(niveis.nivelEnsino)}
            </Divider>
          </S.nivelTituloContainer>
          {niveis.series.filter(x => x.codHabilitacao == (serieSelecionada ? serieSelecionada : x.codHabilitacao)).map((item, indice) => (
            item.quantidade > 0 &&
            <S.PieChartItemWrapper
              key={`pieChartWrapper-Grafico1_${item.codHabilitacao}_${item.codFilial}_${item.quantidade}-${index}_${indice}_${count()}`}
            >
              <S.pCharTitle>{renderSerie(item.serie)}</S.pCharTitle>
              <PieChart width={200} height={200}>
                <Pie
                  isAnimationActive={false}
                  width={100}
                  height={100}
                  data={formataItem(
                    item,
                    niveis.nivelEnsino,
                    tipop,
                    bim,
                    tipom
                  )}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  startAngle={0}
                  outerRadius={100}
                  label={renderCustomizedLabel}
                  labelLine={false}
                  onClick={(e) => handleClick(e.payload)}
                >
                  {formataCell(item)}
                </Pie>
                <Tooltip content={<CustomTooltip />} />
              </PieChart>
            </S.PieChartItemWrapper>
          ))}
        </React.Fragment>
      );
    })
  ) : (
    <p>Nenhum resultado encontrado.</p>
  );
};
export default PieChartComp;
