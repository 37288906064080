import React, { memo, useRef, useEffect, useCallback } from 'react'
import { MDBDataTable as Table } from 'mdbreact';
import { getMediaTurmaFilial, getMediaSerieFilial, getMediaSerieOrg } from '../../../../../Services/Notas/Service'


const Tabela = ({ dadosTurma, handleClick, status, setMediaTurma, setMediaSerieFilial, setMediaSerieOrg, perfil}) => {
  const refDataTable = useRef(null);

  const colunas = React.useMemo(() => { return [
    {
      label: `${perfil ? "RA" : ""}`,
      field: `${perfil ? "ra" : ""}`,
    },
    {
      label: 'Aluno',
      field: 'nome',
      sort: 'asc'
    },
    {
      label: 'Tipo',
      field: 'tipoTurma',
    },
    {
      label: 'Disciplina',
      field: 'disciplina',
    },
    {
      label: 'Nota',
      field: 'nota',
    },
  ];
},[]);

  const organizarRegistro = useCallback(
    (data) => {
    var result = data.filter(x => x.codFilial === status.codFilial)[0]
      .bimestres.filter(y => y.bimestre === status.bimestre)[0]
      .avaliacoes.filter(p => p.tipoAvaliacao === status.tipoProva)[0]
      .niveisEnsino.filter(i => i.nivelEnsino === status.nivelEnsino)[0]
      .series.filter(l => l.codHabilitacao === status.codHabilitacao)[0]
      .turmas.filter(q => q.turma === status.turma)[0]

    const semDuplicidade = removerDuplicidade(result.alunos);

    return semDuplicidade;
  },[status])

  useEffect(() => {

    if (status?.turma && status?.codFilial && status?.title && status?.codHabilitacao) {
      getMediaTurmaFilial(status?.turma, status?.codFilial, status?.title, status?.codHabilitacao).then((resultado) => {
        setMediaTurma(resultado[0]);
      });
    }

    if (status?.codFilial && status?.title && status?.codHabilitacao) {
      getMediaSerieFilial(status?.codFilial, status?.title, status?.codHabilitacao).then((resultado) => {
        setMediaSerieFilial(resultado[0]);
      });
    }

    if (status?.title && status?.codHabilitacao) {
      getMediaSerieOrg(status?.title, status?.codHabilitacao).then((resultado) => {
        setMediaSerieOrg(resultado[0]);
      });
    }

  }, [status, organizarRegistro, setMediaSerieFilial, setMediaSerieOrg, setMediaTurma])

  const removerDuplicidade = (alunos) => {
    const alunosUnicos = [];
    const alunosDuplicados = {};

    alunos.forEach(aluno => {
      const {
        bimestre,
        codFilial,
        ra,
        nome,
        serie,
        turma,
        tipoTurma,
        tipoAvaliacao,
        disciplina,
        nota
      } = aluno;

      const chave = `${bimestre}-${codFilial}-${ra}-${nome}-${serie}-${turma}-${tipoTurma}-${tipoAvaliacao}-${disciplina}-${nota}`;

      if (!alunosDuplicados[chave]) {
        alunosUnicos.push(aluno);
        alunosDuplicados[chave] = true;
      }
    });

    return alunosUnicos;
  }


  const dadosDaTabela = React.useMemo(() => {
    return {
      columns: colunas,
      rows: organizarRegistro(dadosTurma).map((registro, index) => ({
        ...registro, nota: (Math.floor(registro.nota) / 10).toFixed(1),
        clickEvent: () => { handleClick({ ...registro }, true) },
        key: index.toString()
      }))
    };
  }, [colunas, organizarRegistro, dadosTurma, handleClick]);


  return (
    <Table
      striped={dadosDaTabela.rows.length > 0 ? true : false}
      hover={dadosDaTabela.rows.length > 0 ? true : false}
      autoWidth={true}
      small
      noBottomColumns
      displayEntries={false}
      entries={20}
      infoLabel={['Mostrando', 'a', 'de', 'registros']}
      paginationLabel={["<", ">"]}
      paging={true}
      pagesAmount={5}
      searchLabel=" "
      responsive
      disableRetreatAfterSorting={true}
      data={dadosDaTabela}
      ref={refDataTable}
    />
  )
}

export default memo(Tabela)