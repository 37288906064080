import React from 'react'
import * as S from "../../../styles/styled";
import { RightOutlined } from '@ant-design/icons';


const TitleWrapper = ({bim, tipom, tipop}) => {
  return (
    <S.TitleWrapper>
          <S.h2>
            {`${bim}º BIMESTRE`}
            {tipop ? (
              <>
                <span>
                  <RightOutlined
                    style={{
                      fontSize: 13,
                      stroke: "#009240",
                      strokeWidth: "80px",
                      margin: "0px 5px",
                    }}
                  />
                </span>
                <span>{tipop}</span>
              </>
            ) : (
              ""
            )}
            {tipop === "MEDIA" ? (
              <>
                <span>
                  <RightOutlined
                    style={{
                      fontSize: 13,
                      stroke: "#009240",
                      strokeWidth: "80px",
                      margin: "0px 5px",
                    }}
                  />
                </span>
                <span>{tipom === "S" ? "SEM NTD" : "COM NTD"}</span>
              </>
            ) : (
              ""
            )}
          </S.h2>
        </S.TitleWrapper>
  )
}

export default TitleWrapper