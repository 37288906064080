import React, { useEffect } from "react";
import Bars from "./Bars";
import { Header } from ".";
import Body from "./Body";
import Footer from "./Footer";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "./Header/Navbar";
import Startup from "startup";
import { UsuarioActions } from "../redux/ducks/usuario";
import { setSGE } from "../redux/ducks/commons";


const PrimaryLayout = ({ children }) => {
  const { isEmail, sge } = useSelector((state) => state.commons);
  const autenticado = useSelector((state) => state.usuario.autenticado);
  const dispatch = useDispatch();

  useEffect(() =>{
    dispatch(setSGE(window.location.pathname.includes("/sge/")));
  },[])

  if (!autenticado) {
    return (
      <Startup>
        {children}
      </Startup>
    );
  }

  if (isEmail) {
    return null;
  }

  return (
    <>
      {!sge ? (
        <>
          <Bars />
          <Header />
          <Body>
            <Startup>
              {children}
            </Startup>
          </Body>
          <Footer />
        </>
      ) : (
        <>
          <Navbar />
          <Body>
            {children}
          </Body>
        </>
      )}
    </>
  );
};

export default PrimaryLayout;
