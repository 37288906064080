import ApiGlobais from './ApiGlobais'

const { get, post, update } = ApiGlobais('usuarios')

const ApiUsuarios = () => {
    return {
        autenticarIp: () => post('autenticar/ip'),
        autenticar: (nome, senha) => post('/autenticar', { nome, senha }),
        checarAcesso: (funcao) => get('/verifica-permissao', { funcao }),
        getTiposCursos: () => get(`/tipos-cursos`),
        atualizarTipoCursoAtual: (tipo) => update(`/tipo-curso/${tipo}`),
    }
}

export default ApiUsuarios
