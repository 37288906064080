import React, { useRef } from 'react';
import { Modal, Button, Divider } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import * as S from '../styles/styled'
import { ReactComponent as TipoTurmaIcon } from './icons/tipoturma.svg';
import { ReactComponent as TurmaIcon } from './icons/turma.svg';
import { ReactComponent as DisciplinaIcon } from './icons/disciplina.svg';
import { ReactComponent as AlunoIcon } from './icons/aluno.svg';
import { setPage } from "../../../redux/ducks/commons";
import { useDispatch } from 'react-redux';

const MenuModal = ({
    visible,
    setVisible
}) => {

    const dispatch = useDispatch();
    const iconTipoTurma = useRef(null);
    const iconTurma = useRef(null);
    const iconDisciplina = useRef(null);
    const iconAluno = useRef(null);



    // function handleClick() {
    //     setVisible(false);
    // }

    const changeColorEnter = (icon) => {
        icon.current.style.fill = "#FFF";
    }

    const changeColorLeave = (icon) => {
        icon.current.style.fill = "#009240";
    }

    return (
        visible && (
            <>
                <Modal
                    open={visible}
                    centered={true}
                    destroyOnClose={true}
                    closable={true}
                    maskClosable={true}
                    keyboard={false}
                    width={350}
                    onCancel={() => setVisible(false)}
                    footer={[
                        <Button key="back" icon={<CloseOutlined />} onClick={() => setVisible(false)} style={{ marginTop: '50px' }}>
                            Cancelar
                        </Button>
                    ]}
                    styles={{ textAlign: 'center' }}
                >
                    <Divider style={{ color: "#999", textTransform: "uppercase", fontSize: "1em", fontWeight: "300", letterSpacing: ".03em", marginTop: "30px", marginBottom: "30px" }}>Detalhar visão por</Divider>

                    <S.ButtonContainer>
                        <S.ButtonVisao onClick={() => {
                            dispatch(setPage("TipoTurma"));
                            setVisible(false);
                        }}
                            onMouseEnter={() => changeColorEnter(iconTipoTurma)} onMouseLeave={() => changeColorLeave(iconTipoTurma)}>
                            <S.IconContainer><TipoTurmaIcon width={30} height={30} fill='#009240' ref={iconTipoTurma} /></S.IconContainer>
                            <S.TitleContainer>Tipo de Turma</S.TitleContainer>
                        </S.ButtonVisao>

                        <S.ButtonVisao onClick={() => { dispatch(setPage("Turma")); setVisible(false) }} onMouseEnter={() => changeColorEnter(iconTurma)} onMouseLeave={() => changeColorLeave(iconTurma)}>
                            <S.IconContainer><TurmaIcon width={30} height={30} fill='#009240' ref={iconTurma} /></S.IconContainer>
                            <S.TitleContainer>Turma</S.TitleContainer>
                        </S.ButtonVisao>

                        <S.ButtonVisao onClick={() => { dispatch(setPage("Disciplina")); setVisible(false) }} onMouseEnter={() => changeColorEnter(iconDisciplina)} onMouseLeave={() => changeColorLeave(iconDisciplina)}>
                            <S.IconContainer><DisciplinaIcon width={30} height={30} fill='#009240' ref={iconDisciplina} /></S.IconContainer>
                            <S.TitleContainer>Disciplina</S.TitleContainer>
                        </S.ButtonVisao>

                        <S.ButtonVisao onClick={() => { dispatch(setPage("Aluno")); setVisible(false) }} onMouseEnter={() => changeColorEnter(iconAluno)} onMouseLeave={() => changeColorLeave(iconAluno)}>
                            <S.IconContainer><AlunoIcon width={30} height={30} fill='#009240' ref={iconAluno} /></S.IconContainer>
                            <S.TitleContainer>Aluno</S.TitleContainer>
                        </S.ButtonVisao>
                    </S.ButtonContainer>

                </Modal>
            </>
        )
    );
};

export default MenuModal;
