import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import NovidadeGif from "../../assets/img/filro_disc.png";
import { Puff } from "react-loading-icons";

function ModalNovidades() {
  const [showModal, setShowModal] = useState(false);
  const [gifLoaded, setGifLoaded] = useState(false); // State para controlar o carregamento do gif

  useEffect(() => {
    const hasViewedDisc = localStorage.getItem("hasViewedDisc");
    if (!hasViewedDisc) {
      setShowModal(true);
    }
  }, []);

  const handleGifLoad = () => {
    setGifLoaded(true);
  };

  const closeModal = () => {
    setShowModal(false);
    localStorage.setItem("hasViewedDisc", "true");
  };

  return (
    <>
      <Modal
        open={showModal} 
        closable={false}
        maskClosable={false}
        keyboard={false}
        width={"70%"}
        onCancel={() => closeModal()}
        footer={null} 
        style={{ marginTop: "-70px" }}
      >
       
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          > 
          {gifLoaded && ( 
            <div style={{ width: "100%" }}>
              <h3>Novidade!</h3>
              <p>
                Agora é possível filtrar os gráficos por disciplina.
              </p>
            </div>)}

            <img
              style={{boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"}}
              src={NovidadeGif}
              width={gifLoaded ? "80%" : "0px"}
              alt="Animação com as últimas novidades"
              onLoad={handleGifLoad}
            />

            {gifLoaded && (
            <div style={{ marginTop: "15px" }}>
              <Button size="large" type="primary" onClick={closeModal}>
                Entendi!
              </Button>
            </div>)}

          </div>
          {!gifLoaded && (
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "200px" }}>
            <h3>Carregando novidades...</h3>
            <Puff fill="#025940" stroke="#025940" height={100} width={100} />
          </div>
        )}
      </Modal>
    </>
  );
}

export default ModalNovidades;
