import axios from 'axios'
import {basePath, getHeader} from '../../Const/webConst'

export const getReducoes = async (RA) => {
  const resposta = await axios.get(
    `${basePath}/Merito/Reducao?ra=${RA}`,
    {
      headers: getHeader()
    }
  ); if (!resposta.data)
    throw new Error(resposta.data.exception.errorMessage);
  return resposta.data;
}

export const getBeneficios = async (RA) => {
const resposta = await axios.get(
  `${basePath}/Merito/Beneficio?ra=${RA}`,
  {
    headers: getHeader()
  }
); if (!resposta.data)
  throw new Error(resposta.data.exception.errorMessage);
return resposta.data;
}