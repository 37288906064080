import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setChangePage, setPage } from '../../../redux/ducks/commons'; // ajuste o caminho para suas ações

const usePageChange = (page, changePage) => {
  const dispatch = useDispatch();

  const handlePageChange = useCallback(() => {
    if (page !== "Graficos") {
      dispatch(setChangePage("Graficos"));
    } else {
      if (changePage === "Graficos" && page === "Graficos") {
        dispatch(setChangePage("Inicial"));
      } else if (changePage === "Inicial" && page !== "Graficos") {
        dispatch(setChangePage("Graficos"));
      } else if (changePage === "Graficos" && page === "Inicial") {
        dispatch(setPage("Graficos"));
        dispatch(setChangePage("Inicial"));
      } else if (changePage === "Inicial" && page === "Graficos") {
      } else {
        dispatch(setChangePage("Inicial"));
      }
    }
  }, [page,dispatch]);

  return handlePageChange;
};

export default usePageChange;
