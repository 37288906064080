import { SolutionOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import {
  setMainPage,
  setChangePage,
  setIsEmail,
  setSGE,
  setPage,
  setDataAll,
} from "../../redux/ducks/commons";
import { useDispatch } from "react-redux";

export const Body = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const renderSerie = (serie) => {
    switch (serie) {
      case "1o ANO EF1":
        return "1º ANO EF";
      case "2o ANO EF1":
        return "2º ANO EF";
      case "3o ANO EF1":
        return "3º ANO EF";
      case "4o ANO EF1":
        return "4º ANO EF";
      case "5o ANO EF1":
        return "5º ANO EF";
      case "6o ANO EF2":
        return "6º ANO EF";
      case "7o ANO EF2":
        return "7º ANO EF";
      case "8o ANO EF2":
        return "8º ANO EF";
      case "9o ANO EF2":
        return "9º ANO EF";
      case "1o ANO E.M":
        return "1º ANO EM";
      case "2o ANO E.M":
        return "2º ANO EM";
      case "3o ANO E.M":
        return "3º ANO EM";
      default:
        return serie.toString();
    }
  };
  let contador = 0;

  const count = (index) => {
    contador++
    return contador + index;
  };

  const onclick = () =>{
    navigate('/');
    dispatch(setIsEmail(false));

  }

  return (
    //<div className='main'>
    data &&
    data.map((itens, index) => {
      return (
        <React.Fragment key={itens.filial + count(index) + index.toString()}>
          <span>
            <div className="titulo-filial" id={`${itens.filial}`}>
              {itens.filial}
            </div>
            <div
              className="main"
              style={
                data.length > 1
                  ? {
                      borderBottom: "1px dashed #d9d9d9",
                      paddingBottom: "60px",
                    }
                  : {}
              }
            >
              {itens.dados.map((item, indice) => (
                <div
                  className="container"
                  key={itens.filial + indice.toString() + count(indice)}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div className="flex-column">
                      <span className="title">{renderSerie(item.serie)}</span>
                      <span className="sub-title">{item.tipoTurma}</span>
                    </div>
                    <div>
                        <Button
                          type="primary"
                          className="go-to-sistema"
                          onClick={onclick}
                          style={{
                            height: "50px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            paddingLeft: "30px",
                          }}
                        >
                          Ver turmas e alunos{" "}
                          <SolutionOutlined style={{ fontSize: "1.5em" }} />{" "}
                        </Button>
                    </div>
                  </div>
                  <div className="flex-column" style={{ marginTop: "2em" }}>
                    <span
                      className="sub-title-detail"
                      style={{
                        color: "#BBBBBB",
                        fontSize: ".8em",
                        textAlign: "left",
                      }}
                    >
                      quantidade de alunos com baixo desempenho por disciplina
                    </span>
                    <div>
                      <div>
                        <table>
                          <thead className="table-header">
                            <tr>
                              <th
                                rowSpan="2"
                                style={{
                                  textAlign: "left",
                                  paddingLeft: "1em",
                                }}
                              >
                                DISCIPLINA
                              </th>
                              <th
                                colSpan="3"
                                style={{ borderLeft: "1px solid #FFF" }}
                              >
                                1BIM
                              </th>
                              <th
                                colSpan="3"
                                style={{ borderLeft: "1px solid #FFF" }}
                              >
                                2BIM
                              </th>
                              <th
                                colSpan="3"
                                style={{ borderLeft: "1px solid #FFF" }}
                              >
                                3BIM
                              </th>
                              <th
                                colSpan="3"
                                style={{ borderLeft: "1px solid #FFF" }}
                              >
                                4BIM
                              </th>
                            </tr>
                            <tr>
                              <th style={{ borderLeft: "1px solid #FFF" }}>
                                VP
                              </th>
                              <th>VG</th>
                              <th>MD</th>

                              <th style={{ borderLeft: "1px solid #FFF" }}>
                                VP
                              </th>
                              <th>VG</th>
                              <th>MD</th>

                              <th style={{ borderLeft: "1px solid #FFF" }}>
                                VP
                              </th>
                              <th>VG</th>
                              <th>MD</th>

                              <th style={{ borderLeft: "1px solid #FFF" }}>
                                VP
                              </th>
                              <th>VG</th>
                              <th>MD</th>
                            </tr>
                          </thead>
                          {item.disciplinas.map((disciplina, index) => (
                            <tbody
                              key={
                                itens.filial +
                                item.serie +
                                item.tipoTurma +
                                disciplina.disciplina +
                                disciplina.vP1 +
                                count(index) +
                                index.toString()
                              }
                            >
                              <tr>
                                <td className="disciplina">
                                  {disciplina.disciplina}
                                </td>
                                <td>
                                  {disciplina.vP1 === 0 ? "" : disciplina.vP1}
                                </td>
                                <td>
                                  {disciplina.vG1 === 0 ? "" : disciplina.vG1}
                                </td>
                                <td>
                                  {disciplina.media1 === 0
                                    ? ""
                                    : disciplina.media1}
                                </td>

                                <td>
                                  {disciplina.vP2 === 0 ? "" : disciplina.vP2}
                                </td>
                                <td>
                                  {disciplina.vG2 === 0 ? "" : disciplina.vG2}
                                </td>
                                <td>
                                  {disciplina.media2 === 0
                                    ? ""
                                    : disciplina.media2}
                                </td>

                                <td>
                                  {disciplina.vP3 === 0 ? "" : disciplina.vP3}
                                </td>
                                <td>
                                  {disciplina.vG3 === 0 ? "" : disciplina.vG3}
                                </td>
                                <td>
                                  {disciplina.media3 === 0
                                    ? ""
                                    : disciplina.media3}
                                </td>

                                <td>
                                  {disciplina.vP4 === 0 ? "" : disciplina.vP4}
                                </td>
                                <td>
                                  {disciplina.vG4 === 0 ? "" : disciplina.vG4}
                                </td>
                                <td>
                                  {disciplina.media4 === 0
                                    ? ""
                                    : disciplina.media4}
                                </td>
                              </tr>
                            </tbody>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </span>
        </React.Fragment>
      );
    })
    // </div>
  );
};

export default Body;
