import { createSlice } from '@reduxjs/toolkit';

const requestSlice = createSlice({
  name: 'request',
  initialState: {
    success: false,
    successMessage: '',
    error: false,
    errorMessage: '',
    loading: false,
  },
  reducers: {
    showMessageSuccess: (state, action) => {
      state.success = true;
      state.successMessage = action.payload;
      state.loading = false;
    },
    showMessageFailure: (state, action) => {
      state.error = true;
      state.errorMessage = action.payload;
      state.loading = false;
    },
    resetRequest: (state) => {
      state.success = false;
      state.successMessage = '';
      state.error = false;
      state.errorMessage = '';
      state.loading = false;
    },
    beginRequest: (state) => {
      state.loading = true;
    },
  },
});

export const { actions: RequestActions, reducer: requestReducer } = requestSlice;
export const { Types } = requestSlice;

export default requestSlice.reducer;
